import React from 'react';
import PropTypes from 'prop-types';
import './DesignerCities.scss';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { translate } from 'react-i18next';
import CityImages from './components/CityImages/CityImages'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DesignerCities = (props) => {

const [value, setValue] = React.useState(0);

const handleChange = (event, newValue) => {
  setValue(newValue);
};

return(
  <div className="DesignerCities">
  	<h4 className="section-title">{props.t("My Cities")}</h4>
    <div className="">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          {props.cities.map((item, index) => <Tab key={index} label={props.t(item.cityText)} {...a11yProps(0)} />)}
        </Tabs>
      {
        props.cities.map((item, index) => 
          <TabPanel value={value} index={index} key={"tab"+index}>

             <div className="cityDesc">
               <p>{item.description}</p>
             </div>

            <CityImages images={item.images}/>
          </TabPanel>
      )}  
    </div>
  </div>
)};

DesignerCities.propTypes = {};

DesignerCities.defaultProps = {
  cities:[]
};

export default translate("translations")(DesignerCities);