import React from 'react';
import PropTypes from 'prop-types';
import './BreadCrumb.scss';

import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { translate, Trans } from "react-i18next";

const BreadCrumb = (props) => (
  <div className="BreadCrumb">
    <div className="container">
      {
        props.t("VisibleAreaTripDesignerBreadCrump") !== "VisibleAreaTripDesignerBreadCrump" && props.t("VisibleAreaTripDesignerBreadCrump") === "True" &&
        <Breadcrumbs >
          {props.items.map((item, i) => <p key={i}>{item}</p>)}
        </Breadcrumbs>
      }
		
    </div>
  </div>
);

BreadCrumb.propTypes = {};

BreadCrumb.defaultProps = {
	items:[]
};

export default translate("translations")(BreadCrumb);
