import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './FaqContainer.scss';
import FaqItem from './../FaqItem/FaqItem';


function getHalf(ar,first=false){

	var mid = Math.ceil(ar.length/2);

	if(first)
		return ar.slice(0,mid);
	else
		return ar.slice(mid);
}


function FaqContainer(props){

	const [expanded, setExpanded] = useState('panel') // change 0 to the number u want to be open by default
	  
	return(

	  	<div className="d-flex justify-content-between faq-grid">
			<div className="faq-container">
		    	{ getHalf(props.faqs,true).map((faq, i) => FaqItem(props.t, faq, i, expanded, setExpanded)) }
			</div>
			<div className="faq-container">
		    	{ getHalf(props.faqs).map((faq, i) => FaqItem(props.t, faq, i+getHalf(props.faqs,true).length, expanded, setExpanded)) }
			</div>
		</div>

	);
}

export default FaqContainer;

