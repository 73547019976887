import React from 'react';
import PropTypes from 'prop-types';
import './PlanRating.scss';
import { translate } from 'react-i18next';

const PlanRating = (props) => (
  <div className="PlanRating d-flex justify-content-start align-items-center">
    <div className="rating">{props.rating.toFixed(1)}</div>
    <div className="stars-container">
	    <div className="d-flex flex-nowrap">
			<div className="star">
				<div className="star-fill" style={{width: (props.rating >= 1) ? "100%": (props.rating % 1) * 100 +"%" }}></div>
			</div>
			<div className="star">
				<div className="star-fill" style={{width: (props.rating >= 2) ? "100%": (props.rating > 1) ? (props.rating % 1) * 100+"%" : 0+"%" }}>
				</div>
			</div>
			<div className="star">
				<div className="star-fill" style={{width: (props.rating >= 3) ? "100%": (props.rating > 2) ? (props.rating % 1) * 100+"%" : 0+"%" }}>
				</div>
			</div>
			<div className="star">
				<div className="star-fill" style={{width: (props.rating >= 4) ? "100%": (props.rating > 3) ? (props.rating % 1) * 100+"%" : 0+"%" }}>
				</div>
			</div>
			<div className="star">
				<div className="star-fill" style={{width: (props.rating >= 5) ? "100%": (props.rating > 4) ? (props.rating % 1) * 100+"%" : 0+"%" }}>
				</div>
			</div>
		</div>
<div className="desc">{props.t("Travellers give an average rating", {name:props.name, rating :props.rating.toFixed(1)})}</div>
    </div>
  </div>
);

PlanRating.propTypes = {};

PlanRating.defaultProps = {};

export default translate("translations")(PlanRating);
