import React from 'react';
import './PaymentSuccess.scss';
import tree from './../../assets/images/tree.png';
import success from './../../assets/icons/success.svg';
import unlock from './../../assets/icons/unlock.svg';
import ShareFriendItem from './components/ShareFriendItem/ShareFriendItem'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import logo from './../../assets/images/logo-d@3x.png';
import { translate } from 'react-i18next';


class PaymentSuccess extends React.Component {

  constructor(props) {
    super(props);
  }
  state={
    friends:[{
            id:uuidv4(),name:"",surName:"",email:""
          }],
    shared:false
  }

  addFriendField(){
    this.setState({
    friends:this.state.friends.concat({
            id:uuidv4(),name:"",surName:"",email:""
          })
    });
  }

  removeFriend(frId){
   
    this.setState({friends: this.state.friends.filter(fr => fr.id !== frId)},()=>console.log(this.state.friends));

  }

  updateFriend(itemId,data){
     var frs = [...this.state.friends];
     var foundIndex = frs.findIndex(x => x.id == itemId);
     frs[foundIndex] = data; 
     this.setState({friends: frs});
     console.log(frs);

  }

  submitFriends(){

var validAll = true;
const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

this.state.friends.forEach((fr, index) => { //reduce?
      
      if(!fr.name || !fr.surName || !fr.email || !re.test(fr.email) ){
        validAll = false;
      }

});

  if(validAll){


   var payload =  this.state.friends.map(fr => ({ //reduce?
            
                name:fr.name,
                surName:fr.surName,
                email:fr.email
      }));

    
  console.log("payload");
var that = this;
axios.post('/UserBasket/planshare/'+this.props.basketId, payload)
                  .then(function (response) {
                    console.log(response);
  
                      that.setState({shared:true});
                      
                  });


    }else{
      console.log("not valid");
    }


  }

render(){
  const { t } = this.props;
  return(
  <div className="PaymentSuccess">
   <div className="mobile-logo"> 
<img className="logo"  src={t("MainLogo1")}/>
    </div>
		<div className="container content-wrap">

             <div className="d-flex justify-content-center">
                    <img className="sc-logo" src={success}/>
             </div>
             <div className="success-title d-flex justify-content-center">
                   <h3>{t("Payment Success")}</h3>
             </div>

             <div className="unlock-box d-flex justify-content-between align-items-center flex-nowrap">
                    <img  className="flex-grow-0 unlock" src={unlock}/>
                    <div className="flex-grow-1">
                       <p className="title">
                        {t("You just unlocked your trip plan!")}
                      </p>
                      <p className="txt">
                        {t("You will receive your trip plan in the next 24 hours.")}
                      </p>
                    </div>
                </div>

         	 	<div className="planting d-flex justify-content-start align-items-center flex-nowrap">
                    <img src={tree}/>
                    <div className="plant-content">
                     	<p className="title">
	                    	{t("Did you know we plant 1 tree for every plan you get!")}
	                    </p>
	                    <p className="tree-txt">
	                    	{t("10 Million trees on the way!")}
	                    </p>
                    </div>
                </div>

                   { !this.state.shared ? <h4 className="shareTitle">{t("Who else is travelling with you?")} <br/>{t("Share your plans with them!")}</h4>:null}

                  { this.state.shared ?
                     <div>
                     <h5 className="thanks">{t("Thank You!")}</h5>
                     <p className="look">{t("We look forward to seeing you and your friends.")}</p>
                     </div>
                     :null
                 }
                   { !this.state.shared ?
                     <div>
                   <div className="share-items">
                      { this.state.friends.map((fr, index) => <ShareFriendItem  update={(id,data)=>this.updateFriend(id,data)} remove={(id)=>this.removeFriend(id)} uid={fr.id} key={fr.id} />) }
                    </div>

                    <div className="share-actions">
                    <button className="btn-o-d add-btn" onClick={()=>this.addFriendField()}>{t("+ Add", {other : this.state.friends.length > 0 ? "Another":"Friend"})}</button>
                     <button className="btn-d share-btn" onClick={()=>this.submitFriends()}>{t("Share my Plans")}</button>
                     </div>
                     </div>
                     :null}

        </div>
    { !this.state.shared ? <button className="btn-d mobile-share-btn" onClick={()=>this.submitFriends()}>{t("Share my Plans")}</button> :null}

    

  </div>
)};

}
PaymentSuccess.defaultProps = {};

export default translate("translations")(PaymentSuccess);