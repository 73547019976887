import React from 'react';
import PropTypes from 'prop-types';
import './PlanTags.scss';
import { translate } from 'react-i18next';
import { diffTime } from './../../../../assets/Helpers.js'

const PlanTags = (props) => (
  <div className="PlanTags d-flex justify-content-start flex-wrap">
    	<div className="tag">{props.t("TOTAL PLACES", {total: props.cards.reduce((total,item) => total + item.spots.length,props.cards.length)})}</div>
    	<div className="tag">{props.t(" MAIN EVENTS", {main: props.cards.filter(item => !item.cardId).length})}</div> 
    	<div className="tag">{props.t("WALKING",{walking:props.walking })}</div> 
    	{ props.availabilities.reduce((coupleEv,item) =>  coupleEv ? coupleEv : item.includes("Çiftler")  ,false)  ? <div className="tag">{props.t("GREAT FOR COUPLES")}</div>:null}   	
		{props.cards.reduce((total,item) => item.cardId ? total + 1 : total ,0) > 0 ? <div className="tag"> {props.t(" ALTERNATIVE EVENTS",{total: props.cards.reduce((total,item) => item.cardId ? total + 1 : total ,0)})}</div>  :null}
		{props.cards.reduce((total,item) => total + item.spots.length ,0) > 0 ? <div className="tag"> {props.t(" BONUS EVENTS", { total : props.cards.reduce((total,item) => total + item.spots.length ,0)})}</div>  :null}
    	{props.cards.length > 1 ? <div className="tag">{props.t("BETWEEN - ",{startTime: props.cards[0].startTime, endTime: props.cards[props.cards.length - 1].endTime})}</div>:null}
    	<div className="tag"> {parseFloat(props.planAvaregePrice) > 0  ? props.t("PLANAVARAGE", {planAvaregePrice:props.planAvaregePrice, planCurrency:props.t(props.planCurrency)}): props.t("FREE PLAN")}</div>
  </div>
);

PlanTags.propTypes = {};

PlanTags.defaultProps = {};

export default translate("translations")(PlanTags);
