import React from 'react';
import PropTypes from 'prop-types';
import './FooterLight.scss';
import logo from './../../assets/images/logo-d@3x.png';
import shield from './../../assets/icons/shield.svg';
import { translate, Trans } from "react-i18next";


const FooterLight = (props) => (
  <div className="FooterLight">
    <div className="container d-flex justify-content-between align-items-center main">
    	<img className="logo" src={props.t("MainLogo1")}/>
    	<div className="side d-flex align-items-center">
            <div className="d-flex ssl">
        		<img className="shield" src={shield}/>
        		<p className="secure">Secured with SSL</p>
            </div>
    		<p className="copy">{props.t("copyright")}</p>
    	</div>
    </div>
  </div>
);

FooterLight.propTypes = {};

FooterLight.defaultProps = {};

export default translate("translations")(FooterLight);
