import React from 'react';
import PropTypes from 'prop-types';
import './EventDesigner.scss';
import { translate } from 'react-i18next';

import location from './../../../../assets/icons/map-pin-outline.svg';
import instagram from './../../../../assets/icons/instagram.svg';
import comment from './../../../../assets/icons/comment.svg';
import {firstName,lastName} from './../../../../assets/Helpers.js'

const EventDesigner = (props) => (
  <div className="EventDesigner">
	  <div className="d-flex align-items-center">
	  	<div className="avatar flex-shrink-0" style={{ backgroundImage:  "url(" + props.image + ")" }}></div>
	  	<div className="info">
	  		<div className="name truncate">{firstName(props.fullName)} {lastName(props.fullName)}</div>
	  		<div className="occupation">{props.professions}</div>
	  		<div className="trips"><p>{props.t("trip design", {count:props.tdPlanCount})}</p></div>
	  		{props.livePlaces ? <div className="local d-flex align-items-center"><img src={location}/><p>{props.livePlaces}</p></div>:null}
	  		{ props.instagram ? 
		  		<div className="insta d-flex align-items-center ">
			  		<a href={"https://www.instagram.com/"+props.instagram.substring(1,props.instagram.length)} target="_blank" rel="nofollow noreferrer noopener"><img src={instagram}/></a>
			  		<a href={"https://www.instagram.com/"+props.instagram.substring(1,props.instagram.length)} target="_blank" rel="nofollow noreferrer noopener">{props.instagram.substring(1,props.instagram.length)}</a>
		  		</div>
		  		:null
	  		}
	  	</div>
	  </div>
	  	{props.tdLanguages && props.tdLanguages.length > 0 ?
	  <div className="langs-wrap d-flex align-items-center">
	  	<img src={comment}/>
		  <p className="langs">{props.t("Speaks")} {props.tdLanguages.map(t => {return(props.t("Languages" + t))}).join(", ")}</p>
	  </div>:null}
 </div>
);

EventDesigner.propTypes = {};

EventDesigner.defaultProps = {};

export default translate("translations")(EventDesigner);
