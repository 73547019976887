import React from 'react';
import './PlanActions.scss';
import { translate } from 'react-i18next';



const PlanActions = (props) => {
	const { t } = props;
	return(
	  <div className="PlanActions">
	    <div className="full-access d-flex flex-wrap align-items-center justify-content-between">
	    	<div>
	    		<p>{t("Fully access to this plan only for")}</p>
	    		<div className="d-flex align-items-center">
	    		{ parseFloat(props.price) > parseFloat(props.discountedPrice) ?
	    			<p className="price-full">{t(props.currency)}{props.price}</p>
	    			:null
	    		}
	    		<p className="price">{t(props.currency)}{props.discountedPrice}</p>
	    		</div>
	    	</div>
	    	<div><button className={`btn-w ${props.status !== 10 ? "dis":""}`} onClick={props.fullAccess} disabled={props.status !== 10} >{t(`Get Full Access_${props.status}`)}</button></div>
	    </div>
	  </div>
	)};


PlanActions.defaultProps = {};

export default translate("translations")(PlanActions);
