import React from 'react';
import PropTypes from 'prop-types';
import './EventPlan.scss';
import { translate } from 'react-i18next';
import clock from './../../../../assets/icons/clock-dark.svg';
import moment from 'moment';

const EventPlan = (props) => (
  <div className="EventPlan">
       <div onClick={props.clicked} className={props.selected ? "event-item selected" : "event-item"}>

       {props.indexs+1}. {props.event.nameOfTheEvent}

       	{/*props.event.adress ? <div className="location">{props.event.adress.split(",")[0]}, {props.event.adress.split(",")[2].split("/")[0].replace(/\d+/g,'').replace(' ','')}</div> :null*/}

	     <div className="d-flex align-items-between align-items-start ec-content">

		     <div className="ec-txt-content flex-grow-1">
		      	{props.event.endTime && props.event.startTime ?

		         <div className="d-flex align-items-center duration">
		           <img src={clock} className="img-clock"/>
		           <p>{moment.duration(moment(props.event.endTime,"HH:mm").diff(moment(props.event.startTime,"HH:mm"))).asMinutes()+ props.t(" min")}</p>
		         </div>         

		         :null}

		         <div> <p className="summary"> {props.event.shortDescription}</p></div>
		     </div>
			 <p className={ props.isFullAccess ? "selected-event-box-hidden" : "selected-event-box" } dangerouslySetInnerHTML={{__html: props.t("SAMPLE EVENT")}} />
			 {
			 	props.isFullAccess || props.solted ? 
		    <div className="paid-warning">
          		<p>
          		{props.t("View this event detail")}
          		</p>
	       	</div>
	       	:
		    <div className="paid-warning">
          		<p>
          		{props.t("This is a paid content.")}
          		<br/>
          		{props.t("To view please complete payment.")}
          		</p>
	       	</div>
	       }
         </div>

       </div>
  </div>
);

EventPlan.propTypes = {};

EventPlan.defaultProps = {
};

export default translate("translations")(EventPlan);
