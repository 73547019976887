import i18n from 'i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from "./utils/i18n/translation/en";
import tr from "./utils/i18n/translation/tr";
import ru from "./utils/i18n/translation/ru";
import axios from 'axios';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const loadResources=async(locale)=> {
  return await axios.get('/Organization/translate', { params: { lang: locale === "tr" ? "0" : locale === "ru" ? "2" : "1" } })
    .then((response) => { 
      console.log(response.data);
      return response.data })
    .catch((error) => { console.log(error); });
}

const backendOptions = {
  loadPath: '{{lng}}|{{ns}}', 
  request: (options, url, payload, callback) => {
    try {
      console.log("backendOptions");
      console.log(LanguageDetector);
      console.log(url);
      console.log(payload);
      console.log(callback);
      const [lng] = url.split('|');
      loadResources(lng).then((response) => {
        callback(null, {
          data: response,
          status: 200, 
        });
      });
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500,
      });
    }
  },
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: true,
    backend: backendOptions,
    languages: ['en','tr','ru'],
    // resources: {
    //   en: en,
    //   tr: tr,
    //   ru: ru
    // },
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ","
    },
    react: {
            useSuspense: false
        },
  });


export default i18n;