import React from 'react';
import PropTypes from 'prop-types';
import './PlanSummary.scss';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clock from './../../../../assets/icons/clock-w.svg';
import viewBtn from './../../../../assets/icons/eye.svg';
import moment from 'moment';
import { translate } from 'react-i18next';

function PlanSummary (props)  {
	const { t } = props;
  const [closedAr, setClosedArray] = React.useState([]);

  const handleChange = (panel) => (event, newExpanded) => {
        props.setExpanded(newExpanded ? panel : false)

        //for FullAccess -> not used anymore
        /*var ar = closedAr.slice(0);

        if(ar.includes(panel)){
          ar.splice(ar.indexOf(panel), 1); 
        }else{
          ar.push(panel);
        }

        setClosedArray(ar);*/

        /*On Accordion -> && !closedAr.includes(`panel${props.id}`)*/

    }


  return(

    	<Accordion key={props.id} expanded={props.expanded === `panel${props.id}`} onChange={handleChange(`panel${props.id}`)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel_${props.id}a-content`}
          id={`panel_${props.id}a-header`}
        >
          <div className="cnm">{ (props.id+1) +". "+props.card.nameOfTheEvent}</div>

         {!props.isFullAccess && props.card.starEvent && props.expanded != `panel${props.id}` ?
                <div><button className="btn view-btn" onClick={() => props.onScrollToEvent()} ><img src={viewBtn}/> {props.t("View this event for free")}</button></div>
         :null}

          {props.isFullAccess && props.expanded != `panel${props.id}`? 
                <div><button className="btn view-btn" onClick={() => props.onSelectEvent([props.card.id,props.id])} ><img src={viewBtn}/> {props.t("Go to this event")}</button></div>
                :null}  

        </AccordionSummary> 
        <AccordionDetails>
          <div>
            {props.card.endTime && props.card.startTime ?

            <div className="d-flex align-items-center duration">
              <img src={clock} className="img-clock"/>
              <p>{moment.duration(moment(props.card.endTime,"HH:mm").diff(moment(props.card.startTime,"HH:mm"))).asMinutes()+ props.t(" min")}</p>
            </div>         

            :null}

                  <div> {props.card.shortDescription}</div>
                  {!props.isFullAccess && props.card.starEvent ?
                    <button className="btn view-btn" onClick={() => props.onScrollToEvent()} ><img src={viewBtn}/> {props.t("View this event for free")}</button>
                  :null}

                { props.isFullAccess ? 
                <div><button className="btn view-btn" onClick={() => props.onSelectEvent([props.card.id,props.id])} ><img src={viewBtn}/> {props.t("Go to this event")}</button></div>
                :null}

          </div>
        </AccordionDetails>
      </Accordion>
)};


export default translate("translations")(PlanSummary);