import React from 'react';
import PropTypes from 'prop-types';
import './FooterDark.scss';
import logo from './../../assets/images/logo@3x.png';
import shield from './../../assets/icons/shield-w.svg';
import { translate, Trans } from "react-i18next";

const FooterDark = (props) => (
  <div className="FooterDark">

   <div className="container d-flex justify-content-between align-items-center main">
    	<img className="logo" src={props.t("MainLogo2")}/>
    	<div className="side d-flex align-items-center">
    		    <div className="d-flex ssl">
              <img className="shield" src={shield}/>
              <p className="secure">Secured with SSL</p>
            </div>
    		<p className="copy">{props.t("copyright")}</p>
    	</div>
    </div>
  </div>
);

FooterDark.propTypes = {};

FooterDark.defaultProps = {};


export default translate("translations")(FooterDark);
