import React from 'react';
import './EveryPlan.scss';
import { translate } from 'react-i18next';

import appointment from './../../assets/icons/plan-feature/001-appointment.svg';
import routes from './../../assets/icons/plan-feature/002-placeholder.svg';
import clock from './../../assets/icons/plan-feature/01-fragments-icons-32-px-clock-dark.svg';
import pin from './../../assets/icons/plan-feature/003-pin.svg';
import clipboard from './../../assets/icons/plan-feature/004-clipboard.svg';
import pdf from './../../assets/icons/plan-feature/005-pdf-file.svg';
import flags from './../../assets/icons/plan-feature/006-flags.svg';
import tree from './../../assets/icons/tree.svg';
import exclamation from './../../assets/icons/exclamation-point.svg';
import picture from './../../assets/icons/picture.svg';


const EveryPlan = (props) => (
  <div className="EveryPlan">
    <div className="container d-flex justify-content-between flex-wrap">
    	<div className="to-action">
            <h4>{props.t("What’s in every HeyTripster plan?")}</h4>
    		<button className="btn-w" onClick={props.onFullAccess}>{props.t("Get Started")}</button>
    	</div>
    	<div className="features">
            <div className="plan-feature d-flex align-items-center">
                <img className="clock" src={clock}/>
                <p>{props.t("Information to prevent you from roaming aimlessly.")}</p>
            </div>
            <div className="plan-feature d-flex align-items-center">
                <img src={clipboard}/>
                <p>{props.t("What to eat, buy, take pictures & more.")}</p>
            </div>
    		<div className="plan-feature d-flex align-items-center">
    			<img src={appointment}/>
    			<p>{props.t("Relevant and reliable information.")}</p>
    		</div>
            <div className="plan-feature d-flex align-items-start">
                <img className="flags" src={flags}/>
                <p>{props.t("Insider tips like transportation, dress code & more.")}</p>
            </div>
            <div className="plan-feature d-flex align-items-start">
                <img  src={tree}/>
                <p>{props.t("A tree planted on your behalf.")}</p>
            </div>
    	</div>
    	<div className="features">
    		<div className="plan-feature d-flex align-items-center">
    			<img src={exclamation}/>
    			<p>{props.t("Things to watch out for like common scams.")}</p>
    		</div>
    		 <div className="plan-feature d-flex align-items-center">
    			<img className="pin" src={pin}/>
    			<p>{props.t("Min. 5 main events with hidden spots & alternatives.")}</p>
    		</div>
    		<div className="plan-feature d-flex align-items-start">
    			<img  src={picture}/>
    			<p>{props.t("Themes from candlelit dinner to adrenaline-filled activities")}</p>
    		</div>
             <div className="plan-feature d-flex align-items-center">
                <img src={routes}/>
                <p>{props.t("PDF export or interactive app (iOS & Android)")}</p>
            </div>
    	</div>
    </div>
    <div className="container get-started-mobile">
            <button className="btn-w" onClick={props.onFullAccess}>{props.t("Get Started")}</button>
    </div>
  </div>
);

export default translate("translations")(EveryPlan);
