import React from 'react';
import { translate } from 'react-i18next';
import {Helmet} from "react-helmet";


const Metas = (props) => {
	const { t } = props;

return(
    <Helmet>
        <title>{`${props.title}`}</title>
        <meta name="description" content={`${props.description}`} />
        <meta itemprop="image" content={`${props.image}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@heytripster" />
        <meta name="twitter:site" content="@heytripster" />
        <meta name="twitter:title" content={`${props.title}`} />
        <meta name="twitter:description" content={`${props.description}`} />
        <meta name="twitter:image" content={`${props.image}`} />
        <meta property="og:title" content={`${props.title}`} />
        <meta property="og:description" content={`${props.description}`} />
        <meta property="og:url" content={`${props.url}`} />
        <meta property="og:site_name" content="HeyTripster" />
        <meta property="og:image" content={`${props.image}`} />
        {props.concierge && <meta name="robots" content="noindex" />}
    </Helmet>
);

}

Metas.defaultProps = {};

export default translate("translations")(Metas);
