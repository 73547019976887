import React, { Component } from 'react';
import './BrowseOtherPlans.scss';
import axios from 'axios';
import * as acTypes from '../../store/actions';
import { connect } from 'react-redux';
import BrowsePlans from './../../components/BrowsePlans/BrowsePlans';
import FooterLight from './../../components/FooterLight/FooterLight';
import HeaderLight from './../../components/HeaderLight/HeaderLight';
import moment from 'moment'
import compose from 'compose-function';
import { translate } from 'react-i18next';
import { firstName } from './../../assets/Helpers.js'
import { lastNameFull } from './../../assets/Helpers.js'
import DateSelectPopup from './../../components/DateSelectPopup/DateSelectPopup';
import Connector from 'activecampaign_v3';

const actId = "224635093";
const eventKey = "df158451e05eb31744a99d59251513283a91b9cc";
const apiKey = "";
const apiUrl = "";
const ActiveCamp = new Connector(actId, eventKey, apiKey, apiUrl);

var langStr = { "id": "Languages", "value": "EN" };
var langId = 1;

class BrowseOtherPlans extends Component {

  state = {
    plans: [],
    startDate: null,
    endDate: null,
    city: null,
    email: null,
    fullName: null,
    datePopupOpen: false,
    couponCode: ""
  }
  changeLanguage = (lng) => {
    this.props.i18n.changeLanguage(lng);
    if (lng != "en") {
      require(`moment/locale/${lng}`);
    }
  };
  componentDidMount() {
    var url = this.props.match.params.url;
    const query = new URLSearchParams(this.props.location.search);
    const email = query.get("email");
    const tags = query.get("tags");
    const couponCode = query.get("couponCode");
    const apr = query.get("apr");
    langId = this.props.t(this.props.match.params.lang);
    
    
    if (this.props.match.params.lang == "tr") {
      langStr = { "id": "Languages", "value": "TR" };
      this.changeLanguage("tr");
    }
    else if(this.props.match.params.lang == "ru"){
      langStr = { "id": "Languages", "value": "RU" };
      this.changeLanguage("ru");
    }
    else {
      langStr = { "id": "Languages", "value": "EN" };
      this.changeLanguage("en");
    }

    var that = this;
    if(couponCode != null && couponCode != undefined)
    {
        that.props.onCouponCode(couponCode);
    }
    if(apr === "1")
    {
      that.props.onPrivacy(true);
    }

    if (url != undefined) {
      var payload = JSON.parse(decodeURIComponent(this.props.match.params.url));
      payload.startDate = payload.startDate.replace("+", " ").replace("+", " ");
      payload.endDate = payload.endDate.replace("+", " ").replace("+", " ");
      payload.fullName = payload.fullName.replace("+", " ").replace("+", " ").replace("+", " ");
      console.log(payload);
      this.setState({ startDate: moment(payload.startDate), endDate: moment(payload.endDate), email: payload.email, fullName: payload.fullName });

      setTimeout(() => {
        if (payload.email != undefined && payload.email != null && payload.email != "") {
          new Connector(this.props.t("ActiveCamiaignActid"), this.props.t("ActiveCamiaignActKey"), apiKey, apiUrl).trackSite("", { url: encodeURIComponent(window.location.href), referrer: encodeURIComponent(window.location.href) }, payload.email);
        }
        }, 5000);

      axios.post('/Order/planFinder', payload)
        .then(function (response) {
          that.setState({ loading: false });
          const data = response.data.data;
          that.props.onUserToken(data.user.token);
          that.props.onEmail(payload.email);
          that.props.onFullName(payload.fullName);
          that.props.onNameSurname({ firstName: firstName(payload.fullName), surName: lastNameFull(payload.fullName) });
          that.props.onDates({ startDate: payload.startDate, endDate: payload.endDate });

          setTimeout(() => {
            if (payload.email != undefined && payload.email != null && payload.email != "") {
              new Connector(this.props.t("ActiveCamiaignActid"), this.props.t("ActiveCamiaignActKey"), apiKey, apiUrl).trackSite("", { url: encodeURIComponent(window.location.href), referrer: encodeURIComponent(window.location.href) }, payload.email);
            }
          }, 5000);
          if (data.isApplicable) {
            const headers = {
              'Authorization': 'Bearer ' + data.user.token
            }
            payload["startDate"] = moment(data.applicableDate).format('YYYY-MM-DD');
            var apDays = data.aplicableDays.map((ad, i) => moment(ad).startOf('day'))
            that.setState({ payload: payload, avDays: apDays }, that.toAvailableDays());
          } else {

            that.setState({ plans: data.planList });
            that.props.onOtherPlans(data.planList);
            that.setState({ otherPlansLength: data.planList.length });
            that.setState({ status: "browse" });
            
          }
          const first = query.get("first");
          console.log("firstUndefined");
          if(first !== null)
          {
            setTimeout(
              () => this.props.history.push(`/${this.props.match.params.lang}/plan/${response.data.data.planList[0].uniqId}/${response.data.data.planList[0].planUrl}`),
              200
            );
          }
        }).catch(error => {
          console.log(error.response);
        });;
    }
    else if (this.props.plans && this.props.plans.length > 0) {
      this.setState({ "plans": this.props.plans });

    }
    else {
      console.log("chakPlansToShow");
      if (this.props.match.params.startDate && this.props.match.params.endDate) {
        that.props.onDates({ startDate: this.props.match.params.startDate, endDate: this.props.match.params.endDate });
        this.setState({
          startDate: moment(this.props.match.params.startDate, 'YYYY-MM-DD'),
          endDate: moment(this.props.match.params.endDate, 'YYYY-MM-DD'),
          city: (this.props.match.params.city) != undefined ? this.props.match.params.city : null
        });
      }

      if (query.get("startDate") != null && query.get("endDate") != null) {
        var start = decodeURIComponent(query.get("startDate")).replace("+", "-").replace("+", "-").replace(" ", "-").replace(" ", "-");
        var end = decodeURIComponent(query.get("endDate")).replace("+", "-").replace("+", "-").replace(" ", "-").replace(" ", "-");
        that.props.onDates({ startDate: start, endDate: end });
        this.setState({
          startDate: moment(start, 'YYYY-MM-DD'),
          endDate: moment(end, 'YYYY-MM-DD')
        });
      }

      if (query.get("city") != null) {

        that.props.onCity(parseInt(query.get("city"), 10));
        this.setState({
          city: parseInt(query.get("city"), 10)
        });
      }
      
      if (query.get("couponCode") != null) {

        that.props.onCouponCode(query.get("couponCode"));
        this.setState({
          couponCode: query.get("couponCode")
        });
      }

      if (query.get("email") != null) {
        that.props.onEmail(decodeURIComponent(query.get("email")));
        this.setState({
          email: decodeURIComponent(query.get("email"))
        });
      }

      if (query.get("fullName") != null) {
        that.props.onFullName(decodeURIComponent(query.get("fullName")));
        that.props.onNameSurname({ firstName: firstName(decodeURIComponent(query.get("fullName"))), surName: lastNameFull(decodeURIComponent(query.get("fullName"))) });
        this.setState({
          fullName: decodeURIComponent(query.get("fullName"))
        });
      }

      if (that.props.startDate && that.props.endDate) {
        that.props.onDates({ startDate: that.props.startDate, endDate: that.props.endDate });
        console.log("nn", moment(that.props.startDate, 'YYYY-MM-DD'));
        this.setState({
          startDate: moment(that.props.startDate, 'YYYY-MM-DD'),
          endDate: moment(that.props.endDate, 'YYYY-MM-DD'),
          city: (that.props.city) != undefined ? that.props.city : null
        });
      }

      setTimeout(
        () => {
          if (that.props.endDate != null && that.props.startDate != null && that.props.fullName != null && that.props.email != null) {
            this.getPlansToShow();
          }
          else {
            this.setState({ datePopupOpen: true });
          }
        },
        200
      );
    }
  }

  getPlansToShow() {
    console.log("getPlansToShow");
    const query = new URLSearchParams(this.props.location.search);

    console.log("this.state");

    var payload = {
      "email": "",
      "fullName": "",
      "startDate": this.state.startDate.format('YYYY-MM-DD'),
      "endDate": this.state.endDate.format('YYYY-MM-DD'),
      "requirements": [],
      "filtered": [langStr
      ],
      "price": 25,
      "value": "AJyFWJ8XfuZt8SMIp+87DA==",
      "currency": "Euro"
    }

    if (this.state.email !== null) {
      payload.email = this.state.email
    }

    if (this.state.fullName !== null) {
      payload.fullName = this.state.fullName
    }

    if (this.state.city !== null) {

      payload["requirements"].push(
        {
          "id": "city",
          "value": this.state.city
        }
      )
    }

    if (query.get("tag") != null) {
      query.get("tag").split(";").map(t => {
        payload["requirements"].push(
          {
            "id": "taginclude",
            "value": t
          }
        )
      })
    }

    if (query.get("concierge") != null) {
      payload["requirements"].push(
        {
          "id": "concierge",
          "value": query.get("concierge")
        }
      )
    }

    if (query.get("tagex") != null) {
      query.get("tagex").split(";").map(t => {
        payload["requirements"].push(
          {
            "id": "tagexclude",
            "value": t
          }
        )
      })
    }

    if (query.get("count") != null) {
      payload["requirements"].push(
        {
          "id": "count",
          "value": query.get("count")
        }
      )
    }
    setTimeout(() => {
      if (payload.email != undefined && payload.email != null && payload.email != "") {
        new Connector(this.props.t("ActiveCamiaignActid"), this.props.t("ActiveCamiaignActKey"), apiKey, apiUrl).trackSite("", { url: encodeURIComponent(window.location.href), referrer: encodeURIComponent(window.location.href) }, payload.email);
      }
      }, 5000);
    

    axios.post('/Order/planFinder', payload)
      .then(response => {
        this.setState({ plans: response.data.data.planList });
        
        const first = query.get("first");
          console.log("firstUndefined");
          console.log(first);
          if(first !== null)
          {
            setTimeout(
              () => this.props.history.push(`/${this.props.match.params.lang}/plan/${response.data.data.planList[0].uniqId}/${response.data.data.planList[0].planUrl}`),
              200
            );
          }

      });

  }

  onCityChange = (city) => {
    this.props.onCity(parseInt(city, 10));
    this.props.history.push(`/${this.props.lang === 2 ? "ru" : this.props.lang === 0 ? "tr":"en" }/browse?city=${city}`);
    setTimeout(() => window.location.reload(false), 500);
  }

  render() {
    return (
      <div className="BrowseOtherPlans" >
        <div className="d-flex flex-column" style={{ minHeight: "100%" }}>
          <div className="flex-shrink-0 flex-grow-0">
            <HeaderLight />
          </div>
          <div className="flex-shrink-0 flex-grow-1">
            {this.state.plans ? <BrowsePlans oncityChange={(e) => this.onCityChange(e)} plans={this.state.plans} city={this.props.city} lang={this.props.match.params.lang === "en" ? 1 : this.props.match.params.lang === "tr" ? 0 : this.props.match.params.lang === "ru" ? 2 : 1} /> : null}
          </div>
          <div className="flex-shrink-0 flex-grow-0" >
            <FooterLight />
          </div>
        </div>
        {this.state.datePopupOpen && <DateSelectPopup
          onClose={() => this.handleCloseDatePopup()}
          redirect={(to) => this.redirecToPath(to)}
          showDesigner={false}
          showFirst={true}
          eventCity={this.state.city != null ? { city: this.state.city, cityText: this.props.t(`City_${this.state.city}`) } : false}
        />}
      </div>
    )
  };

  handleOpenDatePopup = (showDesigner = true) => {
    this.setState({ showDesignerPopup: showDesigner, datePopupOpen: true });
  }

  handleCloseDatePopup = () => {
    axios.get(`Order/getRandomPlans/8/${this.props.match.params.lang === "tr" ? "0" :this.props.match.params.lang === "ru" ? "2":"1"}/${this.props.city}/0`)
      .then(response => {
        this.setState({ plans: response.data, datePopupOpen: false });
      });
  };

  redirecToPath = (path) => {

    const query = new URLSearchParams(this.props.location.search);
    query.get("tag")
    setTimeout(
      () => {
        var url = "/" + this.props.i18n.language + "/browse?";

        if (this.props.email != null) {
          url += `email=${this.props.email}&`;
        }
        if (this.props.fullName != null) {
          url += `fullName=${this.props.fullName}&`;
        }
        if (this.props.startDate != null) {
          url += `startDate=${this.props.startDate.format('YYYY-MM-DD')}&`;
        }
        if (this.props.endDate != null) {
          url += `endDate=${this.props.endDate.format('YYYY-MM-DD')}&`;
        }
        if (this.props.city != null) {
          url += `city=${this.props.city}&`;
        }
        if (query.get("tag") != null) {
          url += `tag=${query.get("tag")}&`;
        }
        if (query.get("concierge") != null) {
          url += `concierge=${query.get("concierge")}&`;
        }
        if (query.get("count") != null) {
          url += `count=${query.get("count")}&`;
        }
        if (query.get("tagex") != null) {
          url += `tag=${query.get("tagex")}&`;
        }
        window.location.href = url;
      },
      100
    );
  };

}





BrowseOtherPlans.defaultProps = {};

const mapStateToProps = state => {
  return {
    token: state.usr.token,
    lang: state.usr.lang,
    basketId: state.usr.basketId,
    plans: state.usr.otherPlans,
    fullName: state.usr.fullName,
    email: state.usr.email,
    startDate: state.usr.startDate,
    endDate: state.usr.endDate,
    firstName: state.usr.firstName,
    surName: state.usr.surName,
    city: state.usr.city,
    couponCode: state.usr.couponCode
  }
};


const mapDispatchToProps = dispatch => {
  return {
    onUserToken: (token) => dispatch({ type: acTypes.SET_TOKEN, token: token }),
    onBasketId: (basketId) => dispatch({ type: acTypes.SET_BASKET_ID, basketId: basketId }),
    onCouponCode: (couponCode) => dispatch({ type: acTypes.SET_COUPON_CODE, couponCode: couponCode }),
    onOtherPlans: (plans) => dispatch({ type: acTypes.SET_OTHER_PLANS, plans: plans }),
    onEmail: (email) => dispatch({ type: acTypes.SET_EMAIL, email: email }),
    onFullName: (fullName) => dispatch({ type: acTypes.SET_FULLNAME, fullName: fullName }),
    onNameSurname: (nameObj) => dispatch({ type: acTypes.SET_NAME_SURNAME, nameObj: nameObj }),
    onDates: (datesObj) => dispatch({ type: acTypes.SET_DATES, datesObj: datesObj }),
    onCity: (city) => dispatch({ type: acTypes.SET_CITY, city: city }),
		onPrivacy: (privacy) => dispatch({ type: acTypes.SET_PRIVACY, privacy: privacy }),
  }
};

export default compose(translate("translations"), connect(mapStateToProps, mapDispatchToProps))(BrowseOtherPlans);