export function firstName(fullName) {
  const names = fullName.split(" ");
  names.pop();
  return names.join(" ");
}

export function lastName(fullName) {
  const names = fullName.split(" ");
  return names[names.length - 1]; // same as lastNameFull

}

export function lastNameFull(fullName) {
  	const names = fullName.split(" ");
  	return names[names.length - 1];
}


export function lastNameFirstChar(fullName) {
  const names = fullName.split(" ");
  return names[names.length - 1].charAt(0) + ".";
}

export function calcPrice(full,discount) {

    const price = parseFloat(full) - parseFloat(discount)
  
    return price.toFixed(2);
}

export function diffTime(start, end) {
    start = start.split(":");
    end = end.split(":");
    var startDate = new Date(0, 0, 0, start[0], start[1], 0);
    var endDate = new Date(0, 0, 0, end[0], end[1], 0);
    var diff = endDate.getTime() - startDate.getTime();
    var hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    var minutes = Math.floor(diff / 1000 / 60);

    // If using time pickers with 24 hours format, add the below line get exact hours
    // if (hours < 0)
    //    hours = hours + 24;

    /*return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;*/

    return hours;
}