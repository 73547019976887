import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './EventPreview.scss';
import Slider from "react-slick";
import { translate } from 'react-i18next';

import down from './../../../../assets/images/down.png';
import external from './../../../../assets/icons/external-link.svg';
import instagram from './../../../../assets/icons/instagram.svg';
import Dialog from '@material-ui/core/Dialog';


const settings = {
  dots: false,
  arrows:true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1
};
class EventPreview extends Component {
	constructor(props) {
		super(props);
	}

	state = {
		
		openCopyDialog: false
		
	}

	handleCloseCopyDialog = () => {
		this.setState({  openCopyDialog: false });
	}

	handleOpenCopyDialog = () => {
		this.setState({ openCopyDialog: true });
	}

render () {
	const { t, plan } = this.props;

return(
  <div className="EventPreview">
    <h2>{(this.props.cardId === null ? t("Main Event") : this.props.spot ? t(" Bonus Events") : t("Alternative Event"))  +" #"+(this.props.eventIndex+1)+" - "+t("Highlights")}</h2>
    {this.props.t("VisibleAreaFreeSampleText") !== "VisibleAreaFreeSampleText" && this.props.t("VisibleAreaFreeSampleText") === "True" && (!this.props.etype || (this.props.etype != "bonus" && this.props.etype != "alternative")) && !this.props.solted ?
	  <div className="demo-notice d-flex justify-content-between align-items-center">
	  	<img src={down}/>
{/*		  <p dangerouslySetInnerHTML={{__html: t("Following event is brought to you by HeyTripster for demo purposes.", {designerName : props.designerName, city : t("City_" + plan.eventCity)})}} />
*/}	  	<p dangerouslySetInnerHTML={{__html: t("This is a free sample. You’ll see for ALL events like this", {eventCount : (this.props.events.length - 1)})}} />

	</div>
	:null}

	  {this.props.images.split(";").filter(x => x).length>0?
	  <div className="event-images">
	        <Slider {...settings}>
			
	          {
	          	this.props.images.split(";").filter(x => x).map((image, index) => {
	            return <div key={"ei-"+index} ><div className="event-image"  style={{ backgroundImage:  "url(" + image + ")" }} alt={this.props.locationName}></div></div>
	          })}
	          
	        </Slider>
			{
				this.props.imageCopyright != null && this.props.imageCopyright != "" &&
				<div className="sticky">
					<a  onClick={this.handleOpenCopyDialog} >{t("Image sources")} <img src={external} /></a>
				</div>
			}
	  </div>
		:null}
	  <div>
	  	<p className="hashtag">{this.props.tags != null && this.props.tags.split(";").map((tag, i) => <span key={"t"+i}>{"#"+ t(tag) }</span> )}</p>
	  </div>
	  <div className="event-name-is"> {this.props.etype && this.props.etype == "bonus" ? t("Bonus Event Name") : this.props.etype && this.props.etype == "alternative" ? t("Alternative Event Name")  : t("Event name")}</div>
      <h4 className="event-name">{this.props.nameOfTheEvent}</h4>
	  <Dialog
					open={this.state.openCopyDialog}
					onClose={this.handleCloseCopyDialog}
					className="coupon-dialog"
				>
					<div className="coupon-dialog-content">
						<p className="coupon-title">  {t("CopyRight Links")} </p>
						{this.props.imageCopyright !=null && 
							this.props.imageCopyright.split(";").filter(x => x).map((image, index) => {
							return <p key={"ei-"+index}><a href={image} target="_blank" rel="nofollow noreferrer noopener">Link {index +1}</a>							</p>
						})}
					</div>
				</Dialog>
  </div>
)};
}

EventPreview.propTypes = {};

EventPreview.defaultProps = {
	images:"",
	tags:""
};


export default translate("translations")(EventPreview);
