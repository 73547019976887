import React, { Component } from 'react';
import './PlanHeatMap.scss';
import ReactMapGL, {Source, Layer} from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {heatmapLayer} from './map-style';

const layerStyle = {
  id: 'point',
  type: 'circle',
  paint: {
    'circle-radius': 10,
    'circle-color': '#ffb303',
    'circle-opacity':0.8
  }
};
const layerStyle2 = {
  id: 'layer-with-pulsing-dot',
  type: 'symbol',
  source: 'dot-point',
  layout: {
  'icon-image': 'pulsing-dot'
  }
  
};
const layerStyle3 = {
  'id': 'points',
  'type': 'symbol',
  'source': 'point', // reference the data source
  'layout': {
  'icon-image': 'cat', // reference the image
  'icon-size': 0.25
  }
  
};
class PlanHeatMap extends Component {

  state = {
    geoData :{
	  type: 'FeatureCollection',
	  features: [
	  ]
	},viewport: {
        latitude: 0,
        longitude:0,
        zoom: 13,
        bearing: 0,
        pitch: 0
      }
  };

   constructor(props) {
     super(props);

      this.state.viewport= {
        latitude: 0,
        longitude:0,
        zoom: 13,
        bearing: 0,
        pitch: 0
       };
   }

  componentDidMount(){
console.log(this.props.locations);
  	var locations = this.props.locations ? this.props.locations.reduce((locs, item) => locs.concat([{ "type": "Feature", "properties": { "id": "ak16994521", "mag": 4.2, "time": 1507425650893, "felt": null, "tsunami": 0 }, "geometry": { "type": "Point", "coordinates": [ item.lng, item.lat ] } }]),[]) : [];
  	var geoData = this.state.geoData;
  	geoData.features = locations;
    
    console.log("locations");
    console.log(locations);

  	this.setState({geoData: geoData});

      this.setState({viewport: {
        latitude: parseFloat(this.props.locations[0].lat),
        longitude:parseFloat(this.props.locations[0].lng),
        zoom: 13,
        bearing: 0,
        pitch: 0
       }});
  	console.log(this.state.geoData);
  }

    _onViewportChange = viewport => this.setState({viewport});


  render() {
    const {viewport, data, allDay, selectedTime, startTime, endTime} = this.state;


    return (
    	<div className="PlanHeatMap">
      <div className='map-wrap' >
    
     { 

this.state.viewport.latitude && this.state.viewport.longitude ? 
       
       <ReactMapGL
       {...this.state.viewport}
        onViewportChange={this._onViewportChange}
              width="100%"
               height="100%"
               mapStyle="mapbox://styles/mapbox/light-v10"
               maxZoom={13}
                mapboxApiAccessToken={'pk.eyJ1IjoiaGV5dHJpcHN0ZXIiLCJhIjoiY2toaTByNmowMTBsYjJ5bndidzA0ZnZiaCJ9.4FU_uRLuYB8ikupTjuatag'}
           >
      <Source id="my-data" type="geojson" data={this.state.geoData}>
          {this.props.solted ? 
            <Layer {...layerStyle} />
            : 
            <Layer {...heatmapLayer} />
          }
        </Source>
      </ReactMapGL> :null}
      </div>
      </div>
    );
  }
}
PlanHeatMap.defaultProps = {
  locations:[]
};

export default PlanHeatMap;




