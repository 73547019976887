let en = {
  translations: {
    "City_0": "Istanbul",
    "City_1": "Nevsehir (Cappadocia)",
    "City_2": "Denizli (Pamukkale)",
    "City_3": "Antalya",
    "City_4": "Canakkale",
    "City_5": "Izmir",
    "City_6": "Mugla",
    "City_7": "Paris",
    "City_8": "Nice",
    "City_9": "Marseille",
    "City_10": "Rome",
    "City_11": "Florence",
    "City_12": "Venice",
    "City_13": "Milano",
    "City_14": "Napoli",
    "City_15": "Amsterdam",
    "City_16": "Rotterdam",
    "City_17": "Berlin",
    "City_18": "Munich",
    "City_19": "Frankfurt",
    "City_20": "Hamburg",
    "City_21": "Barcelona",
    "City_22": "Madrid",
    "City_23": "Sevilla",
    "City_24": "Granada",
    "City_25": "London",
    "City_26": "Manchester",
    "tr" : "0",
    "tr" : "0",
    "en" : "1",
    "ru" : "2",
    "TRY" : "TRY",
    "TL" : "₺",
    "Euro" : "€",
    "USD" : "$",
    "Dolar" : "$",
    "years" : "year",
    "Living" : "Living",
    "livetext" : "{{inhabitText}} in {{cityText}} for {{inhabitCount}} {{inhabitCircleText}}(s)",
    "Müze" : "Museum",
    "Tarih" : "History",
    "Gezi" : "Sightseeing",
    "Alışveriş" : "Shopping",
    "Spor ve Etkinlik" : "Sports and Activity",
    "Eğlence" : "Entertainment",
    "Etkinlik" : "Activity",
    "Yiyecek ve içecek" : "Food and Drink",
    "Açık Alan" : "Outdoors",
    "Açık Arttırma" : "Auction",
    "Ada" : "Island",
    "Bar" : "Bar",
    "Bilet Gerekli" : "Ticket Required",
    "Bisiklet" : "Bicycle",
    "Cafe" : "Cafe",
    "Cam Sanatı" : "Glass Art",
    "Cami" : "Mosque",
    "Deniz Kenarı" : "Seaside",
    "Deniz Ürünleri" : "Sea food",
    "Doğa" : "Nature",
    "Döner" : "Doner",
    "Esnaf Lokantası" : "Diner",
    "Ev" : "House",
    "Fuar / Expo" : "Fair / Expo",
    "Galeri" : "Gallery",
    "Gece" : "Night",
    "Gece Hayatı" : "Night Life",
    "Gizli Nokta" : "Hidden Gem",
    "Gurme" : "Gourmet",
    "Havuz" : "Pool",
    "Hint Mutfağı" : "Indian Cuisine",
    "İltalyan Mutfağı" : "Italian Cuisine",
    "Kahvaltı" : "Breakfast",
    "Kapalı Alan" : "Indoors",
    "Karaoke" : "Karaoke",
    "Kebap" : "Kebab",
    "Kiralık Araba" : "Rental Car",
    "Kokoreç" : "Kokorec",
    "Koşu" : "Running",
    "Köfte" : "Meatball",
    "Köprü" : "Bridge",
    "Kütüphane" : "Library",
    "Liman / Marina" : "Harbor / Marina",
    "Lounge" : "Lounge",
    "Manevi / Dini" : "Spiritual / Religious",
    "Mangal" : "Barbecue",
    "Manzara" : "Scenery",
    "Meksika Mutfağı" : "Mexican Cuisine",
    "Meydan" : "Square",
    "Müzik" : "Music",
    "Pahalı" : "Expensive",
    "Park" : "Park",
    "Pasaj" : "Passage",
    "Pide" : "Pita",
    "Piknik" : "Picnic",
    "Pizza" : "Pizza",
    "Plaj" : "Beach",
    "Rakı-Balık" : "Raki-Fish",
    "Restoran" : "Restaurant",
    "Rus Mutfağı" : "Russian Cuisine",
    "Sağlık & Spa" : "Health & Spa",
    "Sahaf" : "Bibliopole",
    "Sergi" : "Exhibition",
    "Sokak Yemekleri" : "Street Food",
    "Spor" : "Sport",
    "Tekne Turu" : "Boat Tour",
    "Türk Mutfağı" : "Turkish Cuisine",
    "Ucuz" : "Cheap",
    "Vegan" : "Vegan",
    "Workshop" : "Workshop",
    "Yerel Tatlar" : "Local Tastes",
    "Engelliler Topluluğu" : "Disabled community",
    "Vejetaryen" : "Vegetarian",
    "İtalyan Mutfağı" : "Italian cuisine",
    "Hamam" : "Hammam",
    "Müzayede" : "Auction",
    "Kilise" : "Church",
    "Sinagog" : "Synagogue",
    "Sightseeing" : "Sightseeing",
    "Shopping" : "Shopping",
    "SportAndActivity" : "Sport and activity",
    "Entertainment" : "Entertainment",
    "Events" : "Events",
    "FoodAndDrink" : "Food and drink",
    "VisitArtOfGallery" : "Visit - Art Gallery / Exhibition",
    "VisitMuseum" : "Visit - Museum",
    "VisitPlaceOfWorship" : "Visit - Place of Worship",
    "FoodAndDrinkStreetFlavor" : "Food & Drink- Street Food",
    "FoodAndDrinkRestourant" : "Food & Drink- Restaurant",
    "FoodAndDrinkBreakFast" : "Food & Drink- Breakfast",
    "FoodAndDrinkCafe" : "Food & Drink- Cafe",
    "FoodAndDrinkFineDining" : "Food & Drink- Fine Dining",
    "SportAndActivityYogaAngPlates" : "Sports & Health & Activity - Yoga & Pilates",
    "SportAndActivitySpa" : "Sports & Health & Activity - Spa",
    "SportAndActivityRunning" : "Sports & Health & Activity - Running",
    "SportAndActivityFitness" : "Sports & Health & Activity - Fitness",
    "SportAndActivityTreeking" : "Sports & Health & Activity - Trekking",
    "SportAndActivitySycling" : "Sports & Health & Activity - Cycling",
    "SportAndActivityTurkishBath" : "Sports & Health & Activity - Hammam",
    "TravelBoatTour" : "Travel - Boat tour / Bosphorus tour",
    "TravelWalking" : "Travel - City Walk",
    "TravelParks" : "Travel - Parks & Gardens",
    "TravelSquares" : "Travel - Squares",
    "TravelStreets" : "Travel - Streets",
    "EventTournament" : "Event - Tournament & Competition : Audience / Participant",
    "EventConcert" : "Event - Concert",
    "EventFair" : "Event - Fair (Trade Book Food Technology fairs)",
    "EventFestival" : "Event - Festival (Film Music Coffee Puppet Theater etc.)",
    "EventBiennial" : "Event - Biennial",
    "EntertainmentMeyhane" : "Entertainment - Tavern",
    "EntertainmentNightClub" : "Entertainment - Night Clubs",
    "EntertainmentCasino" : "Entertainment - Casino",
    "EntertainmentBar" : "Entertainment - Bar",
    "Live Support" : "Live Support",
    "Trip Designer" : "Trip Designer",
    "trip design" : "{{count}} trip design",
    "Fully access to this plan only for" : "Access the full plan for just",
    "Get Full Access" : "Get Full Access",
    "Fully access to this plan for (one time).  %100 satisfaction guaranteed!" : "Fully access to this plan for {{priceCurrency}}{{discountedPrice}}(one time).  %100 satisfaction guaranteed!",
    "tripDesigner-join-event" : "{{firstname}}, designer of this plan, <span>will not</span> join you while you are using this plan.",
    "Fully access to this plan for (one time)" : "Fully access to this plan for {{currency}}{{price}} (one time)",
    "%100 satisfaction guaranteed." : "%100 satisfaction guaranteed.",
    "Browse other plans matching my trip dates" : "Browse other plans matching your trip dates",
    "Browse Plans" : "Browse Plans",
    "or" : "or",
    "This is a paid content. To view please click on “Get Full Access” button." : "This is a paid content. To view please click on “Get Full Access” button.",
    "Following event is brought to you by HeyTripster for demo purposes." : "Following event is brought to you by HeyTripster for demo purposes.<br/> To access the full day plan, click “<span>Get Full Access</span>” button and experience {{city}} like {{designerName}}.",
    "Play Trailer" : "Play Trailer",
    "Show me 1 Sample Event" : "Show me a Sample Event",
    "Plan Summary" : "Plan Summary",
    "View this event for free" : "View this event for free",
    "Travellers give an average rating" : "Travellers give {{name}} an average rating of {{rating}} out of 5 stars",
    "This Plan Includes" : "This Plan Includes",
    "Total places" : "Total {{total}} places",
    " Main Events" : "{{main}} Main Events",
    "walking" : "{{walking}} walking",
    "Çiftler" : "Great for couples",
    " Alternative Events" : "{{total}} Alternative Events",
    " Bonus Events" : "{{total}} Nearby Attractions",
    "Between - " : "Between {{startTime}}-{{endTime}}",
    "PlanAvarage" : "Avg.{{planAvaregePrice}}{{planCurrency}}",
    "PlanFree" : "Free Plan",
    "300+ different plans, 100+ trip designers at HeyTripster." : "117+ different plans, 67+ trip designers at HeyTripster.",
    "Arrival Date" : "Arrival Date",
    "Return Date" : "Return Date",
    "Browse All Plans" : "Browse All Plans",
    "Languages" : "Languages",
    "LanguagesArabic" : "Arabic",
    "LanguagesBengali" : "Bengali",
    "LanguagesChinese" : "Chinese",
    "LanguagesDutch" : "Dutch",
    "LanguagesEnglish" : "English",
    "LanguagesFrench" : "French",
    "LanguagesGerman" : "German",
    "LanguagesGreek" : "Greek",
    "LanguagesHebrew" : "Hebrew",
    "LanguagesHindi" : "Hindi",
    "LanguagesIndonesian" : "Indonesian",
    "LanguagesJavanese" : "Javanese",
    "LanguagesJapanese" : "Japanese",
    "LanguagesItalian" : "Italian",
    "LanguagesKorean" : "Korean",
    "LanguagesPersian" : "Persian",
    "LanguagesPolish" : "Polish",
    "LanguagesPortuguese" : "Portuguese",
    "LanguagesRussian" : "Russian",
    "LanguagesSpanish" : "Spanish",
    "LanguagesTurkish" : "Turkish",
    "LanguagesUrdu" : "Urdu",
    "LanguagesVietnamese" : "Vietnamese",
    "Planted Trees" : "Planted Trees",
    "Number of Plans Sold" : "Number of Plans Sold",
    "Themes of ’s trip plans" : "Themes of {{fullName}}’s trip plans",
    "Travel" : "Travel",
    "Nature" : "Nature",
    "Art" : "Art",
    "Shopping" : "Shopping",
    "Sports & Activity" : "Sports & Activity",
    "Entertainment" : "Entertainment",
    "Event" : "Event",
    "Eating & Drinking" : "Eating & Drinking",
    "Culture & History" : "Culture & History",
    "Favorite Song" : "Favorite Song",
    "What’s in every HeyTripster plan?" : "Included with every HeyTripster plan",
    "Get Started" : "Get Started",
    "Information to prevent you from roaming aimlessly." : "Information to prevent you from roaming aimlessly.",
    "What to eat, buy, take pictures & more." : "What to eat, buy, take pictures & more.",
    "Relevant and reliable information." : "Relevant and reliable information.",
    "Insider tips like transportation, dress code & more." : "Insider tips like transportation, dress code & more.",
    "A tree planted on your behalf." : "A tree planted on your behalf.",
    "Things to watch out for like common scams." : "Things to watch out for like common scams.",
    "Min. 5 main events with hidden spots & alternatives." : "Min. 5 main events with hidden spots & alternatives.",
    "Themes from candlelit dinner to adrenaline-filled activities" : "Themes from candlelit dinner to adrenaline-filled activities.",
    "PDF export or interactive app (iOS & Android)" : "PDF export or interactive app (iOS & Android).",
    "TOTAL PLACES" : "TOTAL {{total}} PLACES",
    " MAIN EVENTS" : "{{main}} MAIN EVENTS",
    "WALKING" : "{{walking}} WALKING",
    "GREAT FOR COUPLES" : "GREAT FOR COUPLES",
    " ALTERNATIVE EVENTS" : "{{total}} ALTERNATIVE EVENTS",
    " BONUS EVENTS" : "{{total}} BONUS EVENTS",
    "BETWEEN - " : "BETWEEN {{startTime}}-{{endTime}}",
    "PLANAVARAGE" : "Avg.{{planAvaregePrice}}{{planCurrency}}",
    "FREE PLAN" : "FREE PLAN",
    "Speaks" : "Speaks",
    "name's Plan" : "{{name}}'s Plan",
    "Browse other plans" : "Browse other plans",
    "You can choose a suitable one for yourself and see its details." : "Click on one and you'll see what's inside!",
    "LOCAL" : "LOCAL",
    "TOURIST" : "TRAVELLER",
    "CONCIERGE" : "CONCIERGE",
    "Browse all plans" : "We know you, below you see the most suitable trip plans for you in order.",
    "When do you plan to arrive" : "When do you plan to arrive {{city}}?",
    "We’ll check ’s plan is suitable for your travel window or not." : "We’ll check if this plan is suitable for your travel window or not.",
    "When is your trip" : "When is your trip?",
    "Show me available trip plans" : "Show me available trip plans",
    "Can I use this plan" : "Can I use this plan?",
    "We will list the trip plans suitable for your dates." : "We will list the trip plans suitable for your dates.",
    "Which email address should we send your trip plan?" : "Which email address should we send your trip plan?",
    "Which email address should we send your confirmation?" : "Which email address should we send your confirmation?",
    "Show me available trip plans" : "Show me available trip plans",
    "Travellers Reviews" : "Travellers Reviews",
    "Sorted by Relevancy" : "Sorted by Relevancy",
    "Explore other trip plans" : "Explore other trip plans",
    "s Other Designs" : "{{designerName}}'s other trip plans",
    "My Instagram" : "My Instagram",
    "Browse related articles" : "Browse related articles",
    "Golden Checklist" : "Golden Checklist",
    "See" : "See",
    "Taste" : "Taste",
    "Buy" : "Buy",
    "Photo-Point" : "Photo-Point",
    "Do" : "Do",
    "Read" : "Read",
    "Listen" : "Listen",
    "Don't" : "Don't",
    "Watch a video related to this event" : "Watch a video related to this event",
    "My Cities" : "My Cities",
    "MyCitiesCity0" : "Istanbul",
    "MyCitiesCity1" : "Nevsehir (Cappadocia)",
    "MyCitiesCity2" : "Denizli (Pamukkale)",
    "MyCitiesCity3" : "Antalya",
    "MyCitiesCity4" : "Canakkale",
    "MyCitiesCity5" : "Izmir",
    "MyCitiesCity6" : "Mugla",
    "MyCitiesCity7" : "Paris",
    "MyCitiesCity8" : "Nice",
    "MyCitiesCity9" : "Marseille",
    "MyCitiesCity10" : "Rome",
    "MyCitiesCity11" : "Florence",
    "MyCitiesCity12" : "Venice",
    "MyCitiesCity13" : "Milano",
    "MyCitiesCity14" : "Napoli",
    "MyCitiesCity15" : "Amsterdam",
    "MyCitiesCity16" : "Rotterdam",
    "MyCitiesCity17" : "Berlin",
    "MyCitiesCity18" : "Munich",
    "MyCitiesCity19" : "Frankfurt",
    "MyCitiesCity20" : "Hamburg",
    "MyCitiesCity21" : "Barcelona",
    "MyCitiesCity22" : "Madrid",
    "MyCitiesCity23" : "Sevilla",
    "MyCitiesCity24" : "Granada",
    "MyCitiesCity25" : "London",
    "MyCitiesCity26" : "Manchester",
    "Visited Countries" : "Visited Countries",
    "About Event" : "About Event",
    "Event Type" : "Event Type",
    "Suggested Duration" : "Suggested start time & duration",
    "Reservation" : "Booking",
    "Not Required" : "Not Required",
    "Required" : "Required",
    "Avg. Price" : "Avg. spending",
    "Suggested Start Time" : "Suggested start time & duration",
    "Location" : "Location",
    "Key Information About this Event" : "Key Information About this Event",
    "The importance of the event in terms of tourism" : "The importance of the event in terms of tourism",
    "What is the story / history of the event?" : "What is the story / history of the event?",
    "Things to know" : "Things to know",
    "Alternative Event & Bonus Events" : "Alternative Event & Bonus Events",
    "When is your trip?" : "When is your trip?",
    "We will list the trip plans suitable for your dates." : "We will list the trip plans suitable for your dates.",
    "January" : "January",
    "February" : "February",
    "March" : "March",
    "April" : "April",
    "May" : "May",
    "June" : "June",
    "July" : "July",
    "August" : "August",
    "September" : "September",
    "October" : "October",
    "November" : "November",
    "December" : "December",
    "Yes, you can use this plan" : "Yes, you can use this plan",
    "Your trip designer suggests this plan for the following dates." : "Your trip designer suggests this plan for the following dates.",
    "What is your name?" : "What is your name?",
    "Name" : "Name",
    "Surname" : "Surname",
    "To Get Full Access" : "To Get Full Access",
    "Continue to checkout" : "Continue to checkout",
    "Show me available trip plans" : "Show me available trip plans",
    "Can I use this plan" : "Can I use this plan?",
    "We will list the trip plans suitable for your dates." : "We will list the trip plans suitable for your dates.",
    "Which email address should we send your trip plan?" : "Which email address should we send your trip plan?",
    "Which email address should we send your confirmation?" : "Which email address should we send your confirmation?",
    "We’ll check ’s plan is suitable for your travel window or not." : "We’ll check if this plan is suitable for your travel window or not.",
    "Main Event" : "Main Event",
    "Highlights" : "Featured Event",
    "This is a paid content." : "This is a paid content.",
    "To view please complete payment." : "To view please complete payment.",
    "Show me available trip plans" : "Show me available trip plans",
    "Show plan on a map" : "Show plan on the map",
    "Live Support" : "Live Support",
    "Travellers Reviews" : "Travellers Reviews",
    "Sorted by Relevancy" : "Sorted by Relevancy",
    "-Less" : "-Less ",
    "+More" : " + More ",
    "Explore other trip plans" : "Explore other trip plans",
    "Don't" : "Don't",
    "Listen" : "Listen",
    "Read" : "Read",
    "Do" : "Do",
    "Photo Point" : "Photo Point",
    "Buy" : "Buy",
    "Taste" : "Taste",
    "See" : "See",
    "Golden CheckList" : "Golden Checklist",
    "Click here for more information" : "Click here for more information",
    "Your trip designer is" : "Your trip designer is <span className='truncate'>{{designerName}}</span>",
    "SAMPLE EVENT" : "FEATURED <br/> EVENT",
    "About Event" : "About Event",
    "Location" : "Location",
    "Event Type" : "Event Type",
    "Suggested Duration" : "Suggested start time & duration",
    " min" : " min",
    "Reservation" : "Booking",
    "Avg. Price" : "Avg. spending",
    "Suggested Start Time" : "Suggested start time & duration",
    "Free Event" : "Free Event",
    "Watch a video related to this event." : "Watch a video related to this event",
    "Open Hours" : "Open Hours",
    "Key Information About this Event" : "Key Information About this Event",
    "The importance of the event in terms of tourism" : "The importance of the event in terms of tourism",
    "What is the story / history of the event?" : "What is the story / history of the event?",
    "Things to know" : "Things to know",
    "Alternative Event & Bonus Events" : "Alternative Event & Bonus Events",
    "Bonus Event Icon" : "Bonus Event",
    "About your trip designer" : "About your trip designer",
    "Bio" : "Bio",
    "Visited Countries" : "Visited Countries",
    "Show More" : "Show More",
    "My Cities" : "My Cities",
    "PlanTreeInfoText" : "As HeyTripster, we plant a tree every time you buy a travel plan. It’s our way of helping you reduce your carbon footprint caused by travelling. We’re hoping to plant 100,000 trees by 2022.",
    "Frequently asked questions" : "Frequently asked questions",
    "What is your name?" : "What is your name?",
    "Loading..." : "Loading...",
    "Yes, you can use this plan" : "Yes, you can use this plan",
    "Please pick one." : "Please pick one.",
    "Your trip designer suggested the best possible dates for this plan." : "Your trip designer suggested the best possible dates for this plan.",
    "To Get Full Access" : "To Get Full Access",
    "Continue to checkout" : "Continue to checkout",
    "Yes, take me to available plans." : "Yes, take me to available plans.",
    "PlanNotApplicableText" : "{{designerName}}’s plan is <b>not applicable</b> to use between <b>{{startDate}} - {{endDate}}</b>. But we found <span>{{otherPlansLength}} other awesome plans</span> available to use.",
    "Would you like to review these plans?" : "Would you like to review <br/>these plans?",
    "Pay with" : "Pay with",
    "Name on Card" : "Name on Card",
    "Card Number" : "Card Number",
    "TermsText" : "By completing this payment, you agree to the <a href='https://heytripster.com/distance-sales-agreement/?utm={{name}}&email={{email}}' target='_blank'>Guest Release and Waiver</a>, the <a href='https://heytripster.com/distance-sales-agreement/?utm={{name}}&email={{email}}' target='_blank'>Cancellation Policy</a>, and the <a href='https://heytripster.com/distance-sales-agreement/?utm={{name}}&email={{email}}' target='_blank'>Guest Refund Policy</a>.",
    "Review and Pay" : "Review and Pay",
    "After payment, you can leave the e-mail addresses of the people you want your trip plan to be shared with, or you can share it later if you wish." : "After payment, you can leave the e-mail addresses of the people you want your trip plan to be shared with, or you can share it later if you wish.",
    "Designed by" : "Designed by {{tridDesigner}}",
    "See More" : "See More",
    "By purchasing single" : "By purchasing this plan you are planting {{count}} tree in {{city}}",
    "By purchasing multiple" : "By purchasing these plans you are planting {{count}} tree in {{city}}",
    "Enter a coupon code" : "Enter a coupon code",
    "Get discount!" : "Get {{discount}} discount!",
    "Apply" : "Apply",
    "Discount applied" : "Discount applied",
    "discount applied" : "discount applied",
    "PlanPickedForDaySingle" : "{{basketSource}} plan picked for {{planDays}} days",
    "PlanPickedForDayMultiple" : "{{basketSource}} plan picked for {{planDays}} days",
    "x trip plan" : "{{currency}}{{price}} x {{count}} trip plan",
    "CurencyPrice" : "{{currency}}{{price}}",
    "Service fee" : "Service fee",
    "TotalCurency" : "Total ({{currency}})",
    "Confirm and Pay" : "Confirm and Pay",
    "100% satisfaction guaranteed." : "100% satisfaction guaranteed.",
    "If you're not satisfied, get a full refund." : "If you're not satisfied, get a full refund.",
    "More Details" : "More Details",
    "Total" : "Total",
    "Payment Success" : "Payment Success",
    "You just unlocked your trip plan!" : "Thanks for your payment! We will send the trip plans you have purchased to your email address with a special link. This process is usually completed within 2 hours.",
    "You will receive your trip plan in the next 24 hours." : "Don't worry if this period takes longer due to reasons beyond our control, you will be able to access your trip plans within 24 hours at most.",
    "Did you know we plant 1 tree for every plan you get!" : "Did you know we plant 1 tree for every plan you get?",
    "10 Million trees on the way!" : "10 Million trees on the way!",
    "Who else is travelling with you?" : "Who else is travelling with you?",
    "Share your plans with them!" : "Share your plans with them!",
    "Thank You!" : "Thank You!",
    "We look forward to seeing you and your friends." : "We look forward to seeing you and your friends.",
    "+ Add" : "+ Add",
    "Share my Plans" : "Share my trip plan",
    "Andorra" : "Andorra",
    "United Arab Emirates" : "United Arab Emirates",
    "Afghanistan" : "Afghanistan",
    "Antigua and Barbuda" : "Antigua and Barbuda",
    "Anguilla" : "Anguilla",
    "Albania" : "Albania",
    "Armenia" : "Armenia",
    "Netherlands Antilles" : "Netherlands Antilles",
    "Angola" : "Angola",
    "Antarctica" : "Antarctica",
    "Argentina" : "Argentina",
    "American Samoa" : "American Samoa",
    "Austria" : "Austria",
    "Australia" : "Australia",
    "Aruba" : "Aruba",
    "Aland Islands" : "Aland Islands",
    "Azerbaijan" : "Azerbaijan",
    "Bosnia and Herzegovina" : "Bosnia and Herzegovina",
    "Barbados" : "Barbados",
    "Bangladesh" : "Bangladesh",
    "Belgium" : "Belgium",
    "Burkina Faso" : "Burkina Faso",
    "Bulgaria" : "Bulgaria",
    "Bahrain" : "Bahrain",
    "Burundi" : "Burundi",
    "Benin" : "Benin",
    "Saint Barthélemy" : "Saint Barthélemy",
    "Bermuda" : "Bermuda",
    "Brunei Darussalam" : "Brunei Darussalam",
    "Bolivia Plurinational State of" : "Plurinational State of Bolivia",
    "Caribbean Netherlands" : "Caribbean Netherlands",
    "Brazil" : "Brazil",
    "Bahamas" : "Bahamas",
    "Bhutan" : "Bhutan",
    "Bouvet Island" : "Bouvet Island",
    "Botswana" : "Botswana",
    "Belarus" : "Belarus",
    "Belize" : "Belize",
    "Canada" : "Canada",
    "Cocos (Keeling) Islands" : "Cocos (Keeling) Islands",
    "Congo the Democratic Republic of the" : "The Democratic Republic of the Congo",
    "Central African Republic" : "Central African Republic",
    "Congo" : "Congo",
    "Switzerland" : "Switzerland",
    "Cote d'Ivoire" : "Cote d'Ivoire",
    "Cook Islands" : "Cook Islands",
    "Chile" : "Chile",
    "Cameroon" : "Cameroon",
    "China" : "China",
    "Colombia" : "Colombia",
    "Costa Rica" : "Costa Rica",
    "Cuba" : "Cuba",
    "Cape Verde" : "Cape Verde",
    "Curacao" : "Curacao",
    "Christmas Island" : "Christmas Island",
    "Cyprus" : "Cyprus",
    "Czech Republic" : "Czech Republic",
    "Germany" : "Germany",
    "Djibouti" : "Djibouti",
    "Denmark" : "Denmark",
    "Dominica" : "Dominica",
    "Dominican Republic" : "Dominican Republic",
    "Algeria" : "Algeria",
    "Ecuador" : "Ecuador",
    "Estonia" : "Estonia",
    "Egypt" : "Egypt",
    "Western Sahara" : "Western Sahara",
    "Eritrea" : "Eritrea",
    "Spain" : "Spain",
    "Ethiopia" : "Ethiopia",
    "Europe" : "Europe",
    "Finland" : "Finland",
    "Fiji" : "Fiji",
    "Falkland Islands (Malvinas)" : "Falkland Islands (Malvinas)",
    "Micronesia Federated States of" : "Federated States of Micronesia ",
    "Faroe Islands" : "Faroe Islands",
    "France" : "France",
    "Gabon" : "Gabon",
    "England" : "England",
    "Northern Ireland" : "Northern Ireland",
    "Scotland" : "Scotland",
    "Wales" : "Wales",
    "United Kingdom" : "United Kingdom",
    "Grenada" : "Grenada",
    "Georgia" : "Georgia",
    "French Guiana" : "French Guiana",
    "Guernsey" : "Guernsey",
    "Ghana" : "Ghana",
    "Gibraltar" : "Gibraltar",
    "Greenland" : "Greenland",
    "Gambia" : "Gambia",
    "Guinea" : "Guinea",
    "Guadeloupe" : "Guadeloupe",
    "Equatorial Guinea" : "Equatorial Guinea",
    "Greece" : "Greece",
    "South Georgia and the South Sandwich Islands" : "South Georgia and the South Sandwich Islands",
    "Guatemala" : "Guatemala",
    "Guam" : "Guam",
    "Guinea-Bissau" : "Guinea-Bissau",
    "Guyana" : "Guyana",
    "Hong Kong" : "Hong Kong",
    "Heard Island and McDonald Islands" : "Heard Island and McDonald Islands",
    "Honduras" : "Honduras",
    "Croatia" : "Croatia",
    "Haiti" : "Haiti",
    "Hungary" : "Hungary",
    "Indonesia" : "Indonesia",
    "Ireland" : "Ireland",
    "Israel" : "Israel",
    "Isle of Man" : "Isle of Man",
    "India" : "India",
    "British Indian Ocean Territory" : "British Indian Ocean Territory",
    "Iraq" : "Iraq",
    "Iran Islamic Republic of" : "Islamic Republic of Iran",
    "Iceland" : "Iceland",
    "Italy" : "Italy",
    "Jersey" : "Jersey",
    "Jamaica" : "Jamaica",
    "Jordan" : "Jordan",
    "Japan" : "Japan",
    "Kenya" : "Kenya",
    "Kyrgyzstan" : "Kyrgyzstan",
    "Cambodia" : "Cambodia",
    "Kiribati" : "Kiribati",
    "Comoros" : "Comoros",
    "Saint Kitts and Nevis" : "Saint Kitts and Nevis",
    "Korea Democratic People's Republic of" : "Democratic People's Republic of Korea",
    "Korea Republic of" : "Republic of Korea",
    "Kuwait" : "Kuwait",
    "Cayman Islands" : "Cayman Islands",
    "Kazakhstan" : "Kazakhstan",
    "Lao People's Democratic Republic" : "Lao People's Democratic Republic",
    "Lebanon" : "Lebanon",
    "Saint Lucia" : "Saint Lucia",
    "Liechtenstein" : "Liechtenstein",
    "Sri Lanka" : "Sri Lanka",
    "Liberia" : "Liberia",
    "Lesotho" : "Lesotho",
    "Lithuania" : "Lithuania",
    "Luxembourg" : "Luxembourg",
    "Latvia" : "Latvia",
    "Libya" : "Libya",
    "Morocco" : "Morocco",
    "Monaco" : "Monaco",
    "Moldova Republic of" : "Republic of Moldova",
    "Montenegro" : "Montenegro",
    "Saint Martin" : "Saint Martin",
    "Madagascar" : "Madagascar",
    "Marshall Islands" : "Marshall Islands",
    "Macedonia the former Yugoslav Republic of" : "North Macedonia",
    "Mali" : "Mali",
    "Myanmar" : "Myanmar",
    "Mongolia" : "Mongolia",
    "Macao" : "Macao",
    "Northern Mariana Islands" : "Northern Mariana Islands",
    "Martinique" : "Martinique",
    "Mauritania" : "Mauritania",
    "Montserrat" : "Montserrat",
    "Malta" : "Malta",
    "Mauritius" : "Mauritius",
    "Maldives" : "Maldives",
    "Malawi" : "Malawi",
    "Mexico" : "Mexico",
    "Malaysia" : "Malaysia",
    "Mozambique" : "Mozambique",
    "Namibia" : "Namibia",
    "New Caledonia" : "New Caledonia",
    "Niger" : "Niger",
    "Norfolk Island" : "Norfolk Island",
    "Nigeria" : "Nigeria",
    "Nicaragua" : "Nicaragua",
    "Netherlands" : "Netherlands",
    "Norway" : "Norway",
    "Nepal" : "Nepal",
    "Nauru" : "Nauru",
    "Niue" : "Niue",
    "New Zealand" : "New Zealand",
    "Oman" : "Oman",
    "Panama" : "Panama",
    "Peru" : "Peru",
    "French Polynesia" : "French Polynesia",
    "Papua New Guinea" : "Papua New Guinea",
    "Philippines" : "Philippines",
    "Pakistan" : "Pakistan",
    "Poland" : "Poland",
    "Saint Pierre and Miquelon" : "Saint Pierre and Miquelon",
    "Pitcairn" : "Pitcairn",
    "Puerto Rico" : "Puerto Rico",
    "Palestine" : "Palestine",
    "Portugal" : "Portugal",
    "Palau" : "Palau",
    "Paraguay" : "Paraguay",
    "Qatar" : "Qatar",
    "Réunion" : "Réunion",
    "Romania" : "Romania",
    "Serbia" : "Serbia",
    "Russian Federation" : "Russian Federation",
    "Rwanda" : "Rwanda",
    "Saudi Arabia" : "Saudi Arabia",
    "Solomon Islands" : "Solomon Islands",
    "Seychelles" : "Seychelles",
    "Sudan" : "Sudan",
    "Sweden" : "Sweden",
    "Singapore" : "Singapore",
    "Saint Helena Ascension and Tristan da Cunha" : "Saint Helena Ascension and Tristan da Cunha",
    "Slovenia" : "Slovenia",
    "Svalbard and Jan Mayen Islands" : "Svalbard and Jan Mayen Islands",
    "Slovakia" : "Slovakia",
    "Sierra Leone" : "Sierra Leone",
    "San Marino" : "San Marino",
    "Senegal" : "Senegal",
    "Somalia" : "Somalia",
    "Suriname" : "Suriname",
    "South Sudan" : "South Sudan",
    "Sao Tome and Principe" : "Sao Tome and Principe",
    "El Salvador" : "El Salvador",
    "Sint Maarten (Dutch part)" : "Sint Maarten (Dutch part)",
    "Syrian Arab Republic" : "Syrian Arab Republic",
    "Swaziland" : "Swaziland",
    "Turks and Caicos Islands" : "Turks and Caicos Islands",
    "Chad" : "Chad",
    "French Southern Territories" : "French Southern Territories",
    "Togo" : "Togo",
    "Thailand" : "Thailand",
    "Tajikistan" : "Tajikistan",
    "Tokelau" : "Tokelau",
    "Timor-Leste" : "Timor-Leste",
    "Turkmenistan" : "Turkmenistan",
    "Tunisia" : "Tunisia",
    "Tonga" : "Tonga",
    "Turkey" : "Turkey",
    "Trinidad and Tobago" : "Trinidad and Tobago",
    "Tuvalu" : "Tuvalu",
    "Taiwan" : "Taiwan",
    "Tanzania United Republic of" : "Tanzania United Republic of",
    "Ukraine" : "Ukraine",
    "Uganda" : "Uganda",
    "US Minor Outlying Islands" : "US Minor Outlying Islands",
    "United States" : "United States",
    "Uruguay" : "Uruguay",
    "Uzbekistan" : "Uzbekistan",
    "Holy See (Vatican City State)" : "Holy See (Vatican City State)",
    "Saint Vincent and the Grenadines" : "Saint Vincent and the Grenadines",
    "Venezuela Bolivarian Republic of" : "Venezuela Bolivarian Republic of",
    "Virgin Islands British" : "Virgin Islands British",
    "Virgin Islands U.S." : "Virgin Islands U.S.",
    "Viet Nam" : "Viet Nam",
    "Vanuatu" : "Vanuatu",
    "Wallis and Futuna Islands" : "Wallis and Futuna Islands",
    "Kosovo" : "Kosovo",
    "Samoa" : "Samoa",
    "Yemen" : "Yemen",
    "Mayotte" : "Mayotte",
    "South Africa" : "South Africa",
    "Zambia" : "Zambia",
    "Zimbabwe" : "Zimbabwe",
    "DiscountPercent" : "%{{couponDiscountPercent}} discount applied",
    "features headline" : "Included with every HeyTripster plan",
    "features exclamationPoint" : "Local routes from different professions with different perspectives",
    "features placeholder" : "At least 5 different main events, 1 alternative event and 5 bonus points",
    "features appointment" : "Day plans lasting at least 8 hours and evening plans lasting at least 6 hours",
    "features picture" : "Planning prepared for different traveller groups",
    "features tree" : "Distinguished events that appeal to different interests",
    "features flags" : "Regularly updated route information on a monthly basis",
    "features pin" : "Cultural tips to make travel easier",
    "features clock" : "Detailed location and time information spread throughout the day",
    "features homeMarker" : "Narratives of local life, experience and memory",
    "features clipboard" : "Cultural experience recommendation list for the event",
    "features Button" : "Show me available trip plans",
    "data.themeOfTheDay" : "The theme of the day appears here",
    "data.shortDescription" : "Short description of the day appears here.",
    "data.difficulty" : "Sample",
    "data.effort" : "Sample",
    "data.walking" : "Sample",
    "data.planAvaregePrice" : "Sample",
    "data.planCurrency" : "$",
    "data.themeImage" : "https://api.heytripster.com/resources/images/122/b5ef289e-074a-4a42-a536-d20f97ee813e.jpg",
    "data.themeImage" : "https://api.heytripster.com/resources/images/122/b5ef289e-074a-4a42-a536-d20f97ee813e.jpg",
    "data.themeThumbnail" : "https://api.heytripster.com/resources/images/122/2afcd13c-bf1c-4fee-b518-af5514d49119.jpg",
    "data.themeThumbnails" : "https://api.heytripster.com/resources/images/122/2afcd13c-bf1c-4fee-b518-af5514d49119.jpg",
    "q.nameOfTheEvent" : "The name of the event appears here",
    "q.reason" : "Importance of the route in terms of history and tourism appears here.",
    "q.importance" : "The importance of the event in terms of tourism appears here.",
    "q.historyOfTheEvent" : "The story / history of the event appears here.",
    "q.availability" : "Available days appear here.",
    "q.shortDescription" : "The short description of the event appears here.",
    "q.images" : "https://api.heytripster.com/resources/images/122/b5ef289e-074a-4a42-a536-d20f97ee813e.jpg",
    "q.locationName" : "The location name appears here.",
    "q.openHours" : "Open hours appear here.",
    "q.tipGuide" : "The tip guide appears here.",
    "q.webSite" : "The website appears here.",
    "q.instagram" : "Instagram address appears here.",
    "q.hygieneRules" : "Hygiene rules appear here.",
    "q.doSee" : "The text you wrote for 'See' in the to-do list appears here.",
    "q.doTaste" : "The text you wrote for 'Taste' in the to-do list appears here.",
    "q.doBuy" : "The text you wrote for 'Buy' in the to-do list appears here.",
    "q.doPhotographing" : "The text you wrote for 'Photo-point' in the to-do list appears here.",
    "q.doDo" : "The text you wrote for 'Do' in the to-do list appears here.",
    "q.doRead" : "The text you wrote for 'Read' in the to-do list appears here.",
    "q.tags" : "Tags appear here.",
    "q.doListen" : "The text you wrote for 'Listen' in the to-do list appears here.",
    "q.doWarning" : "The text you wrote for 'Don't' in the to-do list appears here.",
    "q.doSeeLink" : "The link you added for 'See' in the to-do list will be here.",
    "q.doTasteLink" : "The link you added for 'Taste' in the to-do list will be here.",
    "q.doBuyLink" : "The link you added for 'Buy' in the to-do list will be here.",
    "q.doPhotographingLink" : "The link you added for 'Photo-point' in the to-do list will be here.",
    "q.doDoLink" : "The link you added for 'Do' in the to-do list will be here.",
    "q.doReadLink" : "The link you added for 'Read' in the to-do list will be here.",
    "q.doListenLink" : "The link you added for 'Listen' in the to-do list will be here.",
    "q.doWarningLink" : "The link you added for 'Don't' in the to-do list will be here.",
    "q.media" : "https://api.heytripster.com/resources/images/122/b5ef289e-074a-4a42-a536-d20f97ee813e.jpg",
    "q.media" : "https://api.heytripster.com/resources/images/122/b5ef289e-074a-4a42-a536-d20f97ee813e.jpg",
    "q.thingsToKnow" : "What you write for 'need to know' appears here.",
    "s.nameOfTheEvent" : "Sample bonus event title",
    "s.shortDescription" : "Sample bonus event description.",
    "s.locationName" : "Sample bonus event location name",
    "s.map" : "Sample bonus event map link",
    "s.tags" : "Sample bonus event tags",
    "s.webSite" : "Sample bonus event website",
    "This is a free sample. You’ll see for ALL events like this" : "This is a free sample. You’ll see for ALL events like this when you click on “GET FULL ACCESS. Until then, there are {{eventCount}} locked events.”",
    "Min Max People" : "Min Max People",
    "Start / End" : "Start / End",
    "Available Days" : "Available Days",
    "Dress code" : "Dress code",
    "Before Starting" : "Before Starting",
    "Hashtags suitable to the theme" : "Hashtags suitable to the theme",
    "Rotanın tarihi ve turistik açıdan önemi" : "Importance of the route in terms of history and tourism",
    "View this event detail" : "View this event detail",
    "CopyRight Links" : "Image sources",
    "Image sources" : "Image sources",
    "PlanNameTitle" : "Plan Name",
    "HygieneRulesTitle" : "Hygiene Rules",
    "TransportationTitle" : "Transportation",
    "Istanbul" : "Istanbul",
    "Nevsehir" : "Nevsehir",
    "Denizli" : "Denizli",
    "Antalya" : "Antalya",
    "Canakkale" : "Canakkale",
    "Izmir" : "Izmir",
    "Mugla" : "Mugla",
    "Paris" : "Paris",
    "Nice" : "Nice",
    "Marsilya" : "Marseille",
    "Roma" : "Rome",
    "Floransa" : "Floransa",
    "Venedik" : "Venice",
    "Milano" : "Milano",
    "Napoli" : "Napoli",
    "Amsterdam" : "Amsterdam",
    "Rotterdam" : "Rotterdam",
    "Berlin" : "Berlin",
    "Munih" : "Munich",
    "Frankfurt" : "Frankfurt",
    "Hamburg" : "Hamburg",
    "Barselona" : "Barcelona",
    "Madrid" : "Madrid",
    "Sevilla" : "Sevilla",
    "Granada" : "Granada",
    "Londra" : "London",
    "Manchester" : "Manchester",
    "Bursa" : "Bursa",
    "monday" : "Monday",
    "tuesday" : "Tuesday",
    "thursday" : "Thursday",
    "saturday" : "Saturday",
    "Tip Guide" : "Tip Guide",
    "Lütfen yeniden 'Preview' butonuna basın" : "Please click the 'Preview' button again",
    "bonus_event_default_image" : "https://api.heytripster.com/resources/images/376/0576dadc-6315-4f91-bbac-0808ceaceab2.jpg",
    "visitedtext" : "{{inhabitText}} {{cityText}} {{inhabitCount}} {{inhabitCircleText}}(s).",
    "Another" : "Another",
    "Friend" : "Friend",
    "Get Full Access_10" : "Get Full Access",
    "Get Full Access_1" : "This trip plan is not up to date",
    "Get Full Access_3" : "Not yet available for purchase",
    "Get Full Access_4" : "Not yet available for purchase",
    "Get Full Access_5" : "Not yet available for purchase",
    "Price" : "Price",
    "Go to this event" : "Go to this event",
    "Bonus Location" : "Nearby Attractions - #{{count}}",
    "Show Details" : "Show Details",
    "Alternative Event" : "Alternative Event",
    "PEOPLE" : "{{min}} - {{max}} People",
    "A day you will walk is awaiting you. You'd better wear sneakers." : "A day you will walk is awaiting you. You'd better wear sneakers.",
    "The day will be long. If you take a snack with you?" : "The day will be long. You may want to take a snack with you.",
    "Would you take a shawl with you for a visit to the mosque? (For women)" : "You should take a shawl with you for a visit to the mosque (For women)",
    "Don't forget to make a reservation in advance for the restaurant you plan to visit." : "Don't forget to make a reservation in advance for the restaurant you plan to visit.",
    "Don't forget to take coins with you." : "Don't forget to take coins with you.",
    "In some places on the travel plan credit card does not pass." : "In some places on the travel plan, credit card does not pass.",
    "Since there are museums on the route it will be more economical for you to buy tickets for a pass or multiple pass." : "Since there are museums on the route, it will be more economical for you to buy tickets for a pass or multiple pass.",
    "* Come together with local producers and learn about the products from firsthand." : "Come together with local producers and learn about the products from firsthand.",
    "* Your friends back at home will envy your photographs taken in great points." : "Your friends back at home will envy your photographs taken in great points.",
    "* Explore the rural area and get lost in mother nature" : "Explore the rural area and get lost in mother nature",
    "* See various art pieces closely" : "See various art pieces closely",
    "* A great plan for couples live unforgettable moments together" : "A great plan for couples live unforgettable moments together",
    "* Once in a lifetime experience - you should definitely tell your friends." : "Once in a lifetime experience - you should definitely tell your friends.",
    "* A great day for children they will love every minute of it" : "A great day for children they will love every minute of it",
    "* Take a stroll through the streets and learn better of the city" : "Take a stroll through the streets and learn better of the city",
    "* This plan is suitable for every individual in your family spread the love!" : "This plan is suitable for every individual in your family spread the love!",
    "* While riding your bicycle in the area experience the atmosphere." : "While riding your bicycle in the area experience the atmosphere.",
    "* A venue recommendation for a tasty dinner at the end" : "A venue recommendation for a tasty dinner at the end",
    "* Escape the noise and traffic of the city for a day" : "Escape the noise and traffic of the city for a day",
    "* Includes a rich breakfast place to motivate you for the day" : "Includes a rich breakfast place to motivate you for the day",
    "* A restaurant with a wide dinner menu to keep you well-fed and happy" : "A restaurant with a wide dinner menu to keep you well-fed and happy",
    "* Lunch menu to boost your energy" : "Lunch menu to boost your energy",
    "* Do not forget to bring your towel and swimsuit. You will enjoy the beach." : "Do not forget to bring your towel and swimsuit. You will enjoy the beach.",
    "* It will be hot! You will need your sunglasses and sunscreen." : "It will be hot! You will need your sunglasses and sunscreen.",
    "* Not everyone is your friend! There might be some uncanny people you will encounter on your route." : "Not everyone is your friend! There might be some uncanny people you will encounter on your route.",
    "* Make sure your phone's battery is full. It can be hard to find a place to help you." : "Make sure your phone's battery is full. It can be hard to find a place to help you.",
    "* This plan is designed for driving route. Do not forget to rent one if you do not have your own." : "This plan is designed for driving route. Do not forget to rent one if you do not have your own.",
    "* This is a bicycle route. Do not forget to rent a bicycle if you do not have your own." : "This is a bicycle route. Do not forget to rent a bicycle if you do not have your own.",
    "* Fill your backpack with snacks and beverages. You will need lots of energy throughout the day." : "Fill your backpack with snacks and beverages. You will need lots of energy throughout the day.",
    "Media Link related to the Event" : "Media Link Related to the Event",
    "Event name" : "Event name",
    "Hediyelik Eşya" : "Souvenir",
    "Yürüyüş" : "Walking",
    "Şarap" : "Wine",
    "Tadım" : "Tasting",
    "Çarşı / Pazar" : "Bazaar",
    "Tarihi Yapı" : "Historical Building",
    "Saray" : "Palace",
    "Vejetaryen" : "Vegetarian",
    "Kule" : "Tower",
    "Antik Kent" : "Ancient City",
    "Kermes" : "Kermis",
    "Yüzme" : "Swimming",
    "404 NotFound" : "The plan you are looking for was not found",
    "Plan NotFound" : "The page you are looking for was not found",
}
};

export default en;
