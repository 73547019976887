import React from 'react';
import PropTypes from 'prop-types';
import './EventAbout.scss';
import {diffTime} from'./../../../../assets/Helpers.js'

import location from './../../../../assets/icons/map-pin-outline.svg';
import etype from './../../../../assets/icons/type.svg';
import clock from './../../../../assets/icons/clock.svg';
import reception from './../../../../assets/icons/reception.svg';
import price from './../../../../assets/icons/price-tag.svg';
import instagram from './../../../../assets/icons/instagram.svg';
import externalLink from './../../../../assets/icons/external-link.svg';
import duration from './../../../../assets/icons/duration.svg';
import moment from 'moment';
import { translate } from 'react-i18next';

const EventAbout = (props) => {
	const { t } = props;


return(
  <div className="EventAbout">
        <div className="">
	        {props.adress && props.adress.split(",").length > 2 && !props.solted?
	        <div className="about-item">
		        <div className="abimg"><img src={location} className="img-location"/></div>
		        <div className="abtxt"><p className="desc">{props.t("Location")}</p>
		        <p className="val">{props.adress.split(",")[0]}, {props.adress.split(",")[2].split("/")[0].replace(/\d+/g,'').replace(' ','')}</p></div>
	        </div>: props.solted &&
				<div className="about-item">
				<div className="abimg"><img src={location} className="img-location"/></div>
				<div className="abtxt"><p className="desc">{props.t("Location")}</p>
				<p className="val">{props.mapsLink != null && props.mapsLink !== '' ? <a href={props.mapsLink} target="_blank" rel="nofollow noreferrer noopener">{props.locationName}</a> : props.locationName} - {props.adress && props.adress.split(",").length > 2 && props.adress.split(",")[0] + ","} {props.adress && props.adress.split(",").length > 2 && props.adress.split(",")[2].split("/")[0].replace(/\d+/g,'').replace(' ','')}</p></div>
			</div>
			}
			
	        <div className="about-item">
		        <div className="abimg"><img src={etype} className="img-etype"/></div>
		        <div className="abtxt"><p className="desc">{props.t("Event Type")}</p>
		        <p className="val truncate" style={{paddingRight:"50px"}}>{ t(props.eventTypeText) }</p></div>
	        </div>

	        <div className="about-item">
		        <div className="abimg"><img src={clock} className="img-clock"/></div>
		        <div className="abtxt"><p className="desc">{props.t("Suggested Duration")}</p>
		        {/*<p className="val">{diffTime(props.startTime,props.endTime) < 1 ? "<1": diffTime(props.startTime,props.endTime)}</p>*/}
		        { props.startTime && props.endTime  ? 
		        	<p className="val">{moment(props.startTime, "HH:mm").format("HH:mm")} - {moment.duration(moment(props.endTime,"HH:mm").diff(moment(props.startTime,"HH:mm"))).asMinutes()+ props.t(" min")}</p>
		        	:null}
		        { (!props.startTime || !props.endTime ) && props.spendTime >= 0  ? 
		        	<p className="val">{props.spendTime} {props.t(" min")}</p>
		        	:null
		        }	
		        	</div> 
		        
	        </div>

			{!props.etype || props.etype != "bonus"?
	        <div className="about-item">
		        <div className="abimg"><img src={price} className="img-price"/></div>
		        <div className="abtxt"><p className="desc">{props.t("Avg. Price")}</p>
		        <p className="val">{parseFloat(props.price) <= 0 ? props.t("Free Event")
				:
				props.price > 3 ? <><span>$$$$</span><span className='solid'></span></> :
				props.price > 2 ? <><span>$$$</span><span className='solid'>$</span></> :
				props.price > 1 ? <><span>$$</span><span className='solid'>$$</span></> :
				 <><span>$</span><span className='solid'>$$$</span></>  
			}</p></div>
	        </div>
	        :null}

	        {!props.etype || (props.etype != "bonus" && props.etype != "alternative") ?
	        <div className="about-item">
		        <div className="abimg"><img src={reception} className="img-reception"/></div>
		        <div className="abtxt"><p className="desc">{props.t("Reservation")}</p>
		        <p className="val">{props.howtoArrangeorBookAsk ? props.t("Required"):props.t("Not Required")}</p></div>
	        </div>
	        :null}

			{props.instagram != null && props.instagram.length > 3 &&
				<div className="about-item">
					<div className="abimg"><img src={instagram} className="img-reception"/></div>
					<div className="abtxt"><a href={"https://www.instagram.com/" + props.instagram.replace("@","") + "/"} target="_blank" rel="nofollow noreferrer noopener"><p className="desc">{props.t("Instagram")}</p></a>
					</div>
				</div>
			}

			{props.webSite != null && props.webSite.length > 3 &&
				<div className="about-item">
					<div className="abimg"><img src={externalLink} className="img-reception"/></div>
					<div className="abtxt"><a href={props.webSite} target="_blank" rel="nofollow noreferrer noopener"><p className="desc">{props.t("WebSite")}</p></a>
					</div>
				</div>
			}

	        {/*<div className="about-item">
		        <div className="abimg"><img src={duration} className="img-coin"/></div>
		       <div className="abtxt" ><p className="desc">{props.t("Suggested Start Time")}</p>
		        <p className="val">{moment(props.startTime, "HH:mm").format("hh:mm A")}</p></div>
	        </div>*/}
        </div>
  </div>
)};

EventAbout.propTypes = {};

EventAbout.defaultProps = {};

export default translate("translations")(EventAbout);
