import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Plan from './Plan/Plan';
import Payment from './Payment/Payment';
import BrowseOtherPlans from './BrowseOtherPlans/BrowseOtherPlans';

import NotFound from './Notfound/NotFound';
import Host from './Notfound/Host';
import NotFoundPlan from './NotFoundPlan/NotFoundPlan';


export const Routes = withRouter(({ history }) => {

  return (
      /* hepsi :lang ile başlamalı*/
      <Switch>
        <Route path="/:lang/plan/:id" exact component={Plan} /> {/*657708275*/}
        <Route path="/:lang/plan/:id/:url" exact component={Plan} /> {/*657708275*/}
        <Route path="/:lang/my-plan/:id/:key/:userId" exact component={Plan} /> {/*657708275*/}
        <Route path="/:lang/soldplan/:id/:key" exact component={Plan} /> {/*657708275*/}
        <Route path="/:lang/soldplan/:id/:key/:prew" exact component={Plan} /> {/*657708275*/}
        <Route path="/:lang/checkout/:basketId" exact component={Payment} />
        <Route path="/host/:test" exact component={Host} />
        <Route path="/browse">
          <Redirect push to="/en/browse" />
        </Route>
        <Route path="/:lang/browse/:startDate/:endDate/:city" exact component={BrowseOtherPlans} />
        <Route path="/:lang/browse/:startDate/:endDate/:city" exact component={BrowseOtherPlans} />
        <Route path="/:lang/browse/:startDate/:endDate" exact component={BrowseOtherPlans} />
        <Route path="/:lang/browse" exact component={BrowseOtherPlans} />
        <Route path="/:lang/browse/:url" exact component={BrowseOtherPlans} />
        <Route path="/planNotFound" exact component={NotFoundPlan} />
        <Route component={NotFound} />
      </Switch>
  );
});
