import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './PlanStory.scss';
import Slider from "react-slick";
import PlanStoryFeature from './components/PlanStoryFeature/PlanStoryFeature'
import { translate } from 'react-i18next';

const slSettingsDesktop = {
  dots: false,
  arrows:false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade:true,
  speed: 900,
  cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
  autoplay:false,
  draggable:false,
  swipe:false,
  swipeToSlide:false
};

const slSettingsMobile = {
  dots: true,
  arrows:false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable:false
};

class PlanStory extends Component {
  constructor(props) {
    super(props);
  }
  

  render(){
    return(
    <div className="PlanStory">
    {this.props.plan ?
      <div>
    	<div className="image-slider">
    		 <div className="slider-container desktop-slider" onMouseEnter={() => this.sliderDesktop.slickNext()} >
  	        <Slider ref={slider => (this.sliderDesktop = slider)} {...slSettingsDesktop}>
              {this.props.plan.themeThumbnail != null ?  
              this.props.plan.themeThumbnails.map((im, i) => <div key={i}><div className="plan-image" style={{ backgroundImage:  "url(" + im + ")" }} alt={this.props.plan.themeOfTheDay} /></div>)
              :this.props.plan.themeImages.map((im, i) => <div key={i}><div className="plan-image" style={{ backgroundImage:  "url(" + im + ")" }} alt={this.props.plan.themeOfTheDay}/></div>)
              }
  	        </Slider>
      	</div>
        <div className="slider-container mobile-slider">
              {this.props.plan.themeThumbnail != null ?  
              this.props.plan.themeThumbnails.map((im, i) => i == 0 ? 
              <div key={i}><div className="plan-image" style={{ backgroundImage:  "url(" + im + ")" }} /></div>
              :null)
              :this.props.plan.themeImages.map((im, i) => i == 0 ? 
              <div key={i}><div className="plan-image" style={{ backgroundImage:  "url(" + im + ")" }} /></div>
              :null)
              }
        </div>
       
          {
            this.props.plan.planType == 0 ?
              <div className="plan-tag">{this.props.t("LOCAL")}</div>
              :<div className="plan-tag">{this.props.t("CONCIERGE")}</div>
          }

          {this.props.t("PlanPrices") !== "PlanPrices" && this.props.t("PlanPrices") !== "" && this.props.t("PlanPrices") === "price" ?
              <div className="plan-tagr">{this.props.t("CurencyPrice", { currency: this.props.t(this.props.plan.priceCurrency), price: this.props.plan.discountedPrice })}</div>
              :
              <>
              {this.props.t("PlanPrices") !== "PlanPrices" && this.props.t("PlanPrices") !== "" &&
                <div className="plan-tagr">{this.props.t("PlanPrices")}</div>
              }
              </>
          }

    	</div>  
    	<div className="author-wrap d-flex align-items-center justify-content-between">
    		<div className="author-info d-flex align-items-center">
    			<div className="avatar bgImg" style={{ backgroundImage:  "url(" + this.props.plan.tridDesigner.image + ")" }} ></div>
    			<div className="name">{this.props.plan.tridDesigner.fullName}</div>
    		</div>
    		{this.props.plan.tridDesigner.tdRatingCount > 0 ? 
          <div className="plan-ratings d-flex align-items-center">
      			<div className="star"></div>
      			<div className="rating">{this.props.plan.tridDesigner.tdRating.toFixed(1)} </div>
      			<div className="rate-count">({this.props.plan.tridDesigner.tdRatingCount})</div>
    		</div>
        :null}
    	</div>
    	<div className="plan-title">{this.props.plan.themeOfTheDay}</div>
    	<div className="">
    		{ this.props.plan ? <PlanStoryFeature {...this.props.plan}/> : null }
    	</div>
      </div>:null}

    </div>
  );}
}


export default translate("translations")(PlanStory)