import React, { useState, useRef } from 'react';
import './PlanDetail.scss';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { calcPrice } from "./../../assets/Helpers.js";
import { translate } from 'react-i18next';


import PlanPreview from './components/PlanPreview/PlanPreview'
import PlanActions from './components/PlanActions/PlanActions'
import PlanSummary from './components/PlanSummary/PlanSummary'
import PlanMap from './components/PlanMap/PlanMap'
import PlanTags from './components/PlanTags/PlanTags'
import playBtn from './../../assets/icons/play-wbg.svg';
import viewBtn from './../../assets/icons/eye-wbg.svg';
import PlanMapBox from './components/PlanMapBox/PlanMapBox'

function PlanDetail(props) {
	const [expanded, setExpanded] = useState('panel') // change 0 to the number u want to be open by default
	const previewRef=useRef();

	const handlePlayVideo = () => {

		console.log(previewRef.current);
		console.log(previewRef.current.getWrappedInstance());
		//getWrappedInstance returns undefined
		//previewRef.current.getWrappedInstance().playVideo(); //doesn't work
		
		//normal component code below
           if(previewRef.current) {
              previewRef.current.playVideo();

           }
        }

	return (
		<div className="PlanDetail">
			<div className="container d-flex justify-content-center flex-wrap">
				{
					props.plan ?
						<PlanPreview
							profile={props.plan.tridDesigner}
							title={props.plan.themeOfTheDay}
							desc={props.plan.shortDescription}
							plan={props.plan}
							isFullAccess={props.isFullAccess}
							ref={previewRef} 
						/> : null

   				}
				<div className={props.isFullAccess || props.solted ? "PlanSummary isFullAccess":"PlanSummary"}> 
			      {!props.solted &&
				  <>
				  <PlanActions fullAccess={() => props.onFullAccess()} 
			      	currency={props.plan.priceCurrency}
			      	status={props.plan.status}
					cprice={props.amountDiscount > 0 ? (props.plan.price - props.amountDiscount).toFixed(2) : props.percentDiscount > 0 ? ((props.plan.price / 100) * (100 - props.percentDiscount)).toFixed(2): props.plan.price.toFixed(2)}
                    cdiscountedPrice={props.amountDiscount > 0 ? (props.plan.discountedPrice - props.amountDiscount).toFixed(2) : props.percentDiscount > 0 ? ((props.plan.discountedPrice / 100) * (100 - props.percentDiscount)).toFixed(2): props.plan.discountedPrice.toFixed(2)}
                    price={props.plan.price.toFixed(2)}
                    discountedPrice={props.plan.discountedPrice.toFixed(2)} />
			      <div className="planBtns">
			{/* {props.plan.themeVideo ? <button className="planBtn" onClick={handlePlayVideo} ><img src={playBtn}/>{props.t("Play Trailer")}</button>:null} */}
			      	
			      </div>
				  </>
 					}
					 <>
					 {
					   props.t("VisibleAreaPlanSummary") !== "VisibleAreaPlanSummary" && props.t("VisibleAreaPlanSummary") === "True" &&
					   <h3 className="summary-title">{props.t("Plan Summary")}</h3>
					}
					 </>
			       {props.plan && props.plan.cards ?
			       	<div>
			       
					 <>
				   		{
							props.t("VisibleAreaPlanSummary") !== "VisibleAreaPlanSummary" && props.t("VisibleAreaPlanSummary") === "True" &&

							<div className="summary-box" style={{
								height:
								props.isFullAccess ? "554px" : 
								props.plan.cards.filter(item => !item.cardId).length >= 5 ? "342px" :
								(props.plan.cards.filter(item => !item.cardId).length * 57) < 250 ? "250px":
								(props.plan.cards.filter(item => !item.cardId).length * 57).toString() + "px" 
							}}>


							<PerfectScrollbar>
										{ props.plan.cards.filter(item => !item.cardId).sort(((a, b) => a.startTime < b.startTime ? -1 : 1)).map((card, i) => 
										<PlanSummary isFullAccess={props.isFullAccess } card={card} id={i}  expanded={expanded}  setExpanded={setExpanded} onScrollToEvent={props.onScrollToEvent} onSelectEvent={(id) => props.onSelectEvent(id) } /> )}
								</PerfectScrollbar>
							</div>
						}
						</>
 					</div>
 					:null}
			      { props.t("VisibleAreaShowPlanMap") !== "VisibleAreaShowPlanMap" && props.t("VisibleAreaShowPlanMap") === "True" && props.plan && props.plan.cards && props.plan.cards.filter(item=> item.lat && item.lng ).length > 0 ?  
			      	<PlanMapBox  solted={props.solted} mapOpen={() => props.onMapOpen()} mapOpen2={() => props.onMapOpen2()} locations={props.plan.cards.filter(item=> item.lat && item.lng ).reduce((locs, item) =>locs.concat([{lat:item.lat,lng:item.lng}]),[])} /> 
			      	: null
			      }
			    </div>
			</div>
		</div>
	)
}

export default translate("translations")(PlanDetail);
