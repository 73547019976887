import React, { Component,useState } from 'react';
import axios from 'axios';
import './Faq.scss';
import FaqContainer from './components/FaqContainer/FaqContainer';
import { translate } from 'react-i18next';

class Faq extends Component {

  constructor(props) {
    super(props);
  }

	state = {
        faqs:[],
    }
    componentDidMount () {
        axios.get( `getlist/1` )
            .then( response => {
                this.setState({faqs: response.data});
            } );
    }


render(){
return(
  <div className="Faq">
    <div className="container">
    	<h3 className="sub-title">{this.props.t("Frequently asked questions")}</h3>
    	<FaqContainer faqs={this.state.faqs} t={this.props.t}/>
    </div>
  </div>
)};
}

export default translate("translations")(Faq);