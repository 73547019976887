import React from 'react';
import PropTypes from 'prop-types';
import './DesignerDetail.scss';

import mapicon from './../../assets/icons/map-pin.svg';
import qstart from './../../assets/icons/quote-start.svg';
import qend from './../../assets/icons/quote-end.svg';
import { translate } from 'react-i18next';

import DesignerProfile from './components/DesignerProfile/DesignerProfile';
import DesignerCities from './components/DesignerCities/DesignerCities';
import DesignerCountries from './components/DesignerCountries/DesignerCountries';
import DesignerOther from './components/DesignerOther/DesignerOther';
import {firstName,lastName} from './../../assets/Helpers.js'

const DesignerDetail = (props) => (
  <div className="DesignerDetail">
    <div className="container">

    <h5 className="about-td">{props.t("About your trip designer")}</h5>

	  	<div className="head d-flex align-items-baseline flex-wrap mobile-head">
	  		<h2 class="truncate">{firstName(props.designer.fullName)} {lastName(props.designer.fullName)}</h2>
	  		<div className="sum d-flex align-items-center">
	  			<p>{props.designer.profession},</p>
	  			<div className="d-flex align-items-center inhabit">
	  				{props.cities.filter(ct=> ct.city == props.eventCityId).length > 0 ? <img src={mapicon}/> : null}
	  				{
						  props.cities.filter(ct=> ct.city == props.eventCityId).map((c,i)=> 
	  					<p key={i}>{props.t("livetext",{"inhabitText":props.t(c.inhabitText),"cityText": props.t(c.cityText),"inhabitCount":props.t(c.inhabitCount),"inhabitCircleText":props.t(c.inhabitCircleText.toLowerCase())})}</p>)
	  				}
	  			</div>
	  		</div>
	  	</div>

  		<div className="head d-flex align-items-end flex-wrap desktop-head">
	  		<h2 class="truncate" >{firstName(props.designer.fullName)} {lastName(props.designer.fullName)}</h2>
	  		<div className="sum flex-grow-1">
	  		<div className="d-flex align-items-center inhabit">
	  				{props.cities.filter(ct=> ct.city == props.eventCityId).length > 0 ? <img src={mapicon}/> : null}
	  				{
	  					props.cities.filter(ct=> ct.city == props.eventCityId).map((c,i)=> 
	  					<p key={i}>{props.t("livetext",{"inhabitText":props.t(c.inhabitText),"cityText": props.t(c.cityText),"inhabitCount":props.t(c.inhabitCount),"inhabitCircleText":props.t(c.inhabitCircleText.toLowerCase())})}</p>)
	  				}
	  			</div>
	  			<p className="new-bio" ><b>{props.t("Bio")}: </b> {props.designer.profession}</p>
	  			
	  		</div>
	  	</div>

	  	<div className="des-container d-flex justify-content-between flex-wrap">
	  		<div className="des-profile"><DesignerProfile {...props.designer}/></div>
	  		<div className="des-content flex-grow-1">
	  			<DesignerCountries countries={props.designer.visitedCountries}/>
	  			<DesignerCities cities={props.cities}/>
	  			<DesignerOther designerName={props.designer.fullName} designerId={props.designer.id} planId={props.planId}/>
	  		</div>
	  	</div>
	</div>
  </div>
);

DesignerDetail.propTypes = {};

DesignerDetail.defaultProps = {
	cities:[]
};

export default translate("translations")(DesignerDetail);