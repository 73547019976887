import React, { Component } from 'react';
import axios from 'axios';
import './NotFound.scss';
//import Modal from "@material-ui/core/Modal";

import { connect } from 'react-redux';
import compose from 'compose-function';
import { translate, Trans } from "react-i18next";
import { Controller, Scene } from 'react-scrollmagic';
import HeaderLight from '../../components/HeaderLight/HeaderLight';
import FooterLight from '../../components/FooterLight/FooterLight';
import notfound from './../../assets/images/404.png';
import {Link } from "react-router-dom";


class Notfound extends Component {
    state = {

    }


    constructor(props) {
        super(props)
        this.eventDetailRef = React.createRef()
    }



    componentDidMount() {
        
    }

    redirecToPath = (path) => {
        this.props.history.push(path);
    };

    render() {
        const { t } = this.props;
        return (
            <div className="NotFound" >
                <div className="d-flex flex-column" style={{ minHeight: "100%" }}>
                  <div className="flex-shrink-0 flex-grow-0">
                    <HeaderLight />
                  </div>
                  <div className="flex-shrink-0 flex-grow-1">
                    <div className="container">
                      <div className="d-flex flex-wrap pay-content">
                        <div className="left">
                          <div className="sum-container text-center shadow p-3 mb-5 bg-white rounded" style={{
        position: 'absolute', left: '50%', top: '45%',
        transform: 'translate(-50%, -50%)'
      }}>
        <div className="avatar bgImg" style={{ backgroundImage: "url(" + notfound + ")" }} ></div>
                            <h4>{t("404 NotFound")}</h4>
                            <Link to="/en/browse"><button className="btn-d mt-5 mb-5" >{t("Browse Plans")}</button></Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0 flex-grow-0" >
                    <FooterLight />
                  </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
 
};


const mapDispatchToProps = dispatch => {

};

export default compose(translate("translations"), connect(mapStateToProps, mapDispatchToProps))(Notfound);