import React from 'react';
import PropTypes from 'prop-types';
import './HeaderLight.scss';
import logo from './../../assets/images/logo-d@3x.png';
import comment from './../../assets/icons/comment.svg';
import { translate } from 'react-i18next';

const HeaderLight = (props) => (
  <div className="HeaderLight">
       <div className="container d-flex justify-content-between align-items-center">
	    	<img className="logo" src={props.t("MainLogo1")}/>
	    	<div className="side">
				{
					props.t("VisibleAreaLiveSupport") !== "VisibleAreaLiveSupport" && props.t("VisibleAreaLiveSupport") === "True"  &&
					<a href={props.t("MainLiveSupport")} target="_blank" rel="nofollow noreferrer noopener"><button className="btn-o-d"><img className="comment" src={comment}/> {props.t("Live Support")} </button></a>
				}
    		</div>
    	</div>
  </div>
);

HeaderLight.propTypes = {};

HeaderLight.defaultProps = {};



export default translate("translations")(HeaderLight);
