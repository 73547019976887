import React, { Component } from 'react';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import './DateSelectPopup.scss';
import TextField from '@material-ui/core/TextField';
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from '@material-ui/core/InputAdornment';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import logo from './../../assets/images/logo@3x.png';
import exclamationPoint from './../../assets/icons/exclamation-point.svg';
import placeholder from './../../assets/icons/plan-feature/002-placeholder.svg';
import popupimage from './../../assets/images/popupimage.png';
import appointment from './../../assets/icons/plan-feature/001-appointment.svg';
import clock from './../../assets/icons/plan-feature/01-fragments-icons-32-px-clock-dark.svg';
import pin from './../../assets/icons/plan-feature/003-pin.svg';
import clipboard from './../../assets/icons/plan-feature/004-clipboard.svg';
import homeMarker from './../../assets/icons/plan-feature/home-location-marker.svg';
import pdf from './../../assets/icons/plan-feature/005-pdf-file.svg';
import flags from './../../assets/icons/plan-feature/006-flags.svg';
import tree from './../../assets/icons/tree.svg';
import picture from './../../assets/icons/picture.svg';

import { withStyles, createStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as acTypes from '../../store/actions';
import { connect } from 'react-redux';
import moment from 'moment'
import loadingImg from './../../assets/icons/loading.svg';
import success from './../../assets/icons/success.svg';
import PerfectScrollbar from 'react-perfect-scrollbar'
import compose from 'compose-function';
import Connector from 'activecampaign_v3';
import { translate } from 'react-i18next';
const actId = "224635093";
const eventKey = "df158451e05eb31744a99d59251513283a91b9cc";
const apiKey = "";
const apiUrl = "";
const ActiveCamp = new Connector(actId, eventKey, apiKey, apiUrl);

class DateSelectPopup extends Component {

	constructor(props) {
		super(props);
		this.emailInput = React.createRef();
		this.nameInput = React.createRef();
		this.surnameInput = React.createRef();

		this.state = {
			startDate: null,
			endDate: null,
			email: null,
			city: null,
			focusedInput: null,
			isMobile: false,
			shrinkEmailField: false,
			loading: false,
			status: "form",
			showValidation: null,
			otherPlansLength: 0,
			avDays: [],
			name: null,
			surname: null,
			selectedAvDate: null,
			payload: {}
		};
	}

	emailChangedHandler = (event) => {
		this.setState({ email: event.target.value });
	}

	nameChangedHandler = (event) => {
		this.setState({ name: event.target.value });
	}

	surnameChangedHandler = (event) => {
		this.setState({ surname: event.target.value });
	}

	handleKeyPress = (event) => {
        if(event.key == 'Enter'){
        	this.props.showDesigner ? this.findPlan():this.toCheckBrowse();
        }else if(event.keyCode == 13){
        	this.props.showDesigner ? this.findPlan():this.toCheckBrowse();
        }
    };

    handleSurnameKeyPress = (event) => {

        if(event.key == 'Enter'){
        	this.createOrder();
        }else if(event.keyCode == 13){
        	this.createOrder();
        }

    };


	singleDateAvailable = () => {

		//single date selected and is available

		if (!this.state.startDate || !this.state.endDate)
			return false;

		if (
			this.state.startDate.startOf('day').isSame(this.state.endDate.startOf('day'))
			&& this.state.avDays.filter(adt => adt.isSame(this.state.startDate.startOf('day'))).length > 0
		) {
			return false;
		}
		return false;
	};

	betweenDates = () => {


		if (!this.state.startDate || !this.state.endDate)
			return [];

		var dates = [];

		var currDate = this.state.startDate.clone().add(-1, 'days').startOf('day');
		var lastDate = this.state.endDate.clone().startOf('day');

		while (currDate.add(1, 'days').diff(lastDate) <= 0) {
			//console.log(currDate.toDate());
			dates.push(currDate.clone());
		}

		//console.log("between",dates);
		//console.log("adays",this.state.avDays);
		return dates;
	};

	handlePrivacyChange = () => {
		var that = this;
		that.props.onPrivacy(!this.props.privacy);
	}

	handleTermsChange = () => {
		var that = this;
		that.props.onTerms(!this.props.terms);
	}


	shrinkLabel = (event) => {
		const { onFocus } = this.props;
		this.setState({ shrinkEmailField: true });
		onFocus && onFocus(event); // let the child do it's thing
	};

	unShrinkLabel = (event) => {
		const { onBlur } = this.props;
		if (event.target.value.length === 0) {
			this.setState({ shrinkEmailField: false }) //gotta make sure the input is empty before shrinking the label
		}
		onBlur && onBlur(event); // let the child do it's thing
	};

	render() {
		return (
			<div className="DateSelectPopup">
				{!this.props.showFirst && this.props.t("handleOpenDatePopupCloser") !== "0" ? 
						<>
							<div className="ov" ></div>
						</>:
							<div className="ov" onClick={this.props.onClose}></div>
						}
				
				<div className={this.props.showDesigner ? "popup-content" : this.props.showFirst ? "popup-content showfirst" : "popup-content nodesigner"}>

					<div className="content-wrap d-flex">
						{!this.props.showFirst && this.props.t("handleOpenDatePopupCloser") !== "0" ? 
						""	
						:
						<>
							<div className="close-btn" onClick={this.props.onClose} />
							<div className="close-btn close2" onClick={this.props.onClose} />
						</>
						}
						
						{this.state.status == "form" ?
							<div className="main-content">
								<h2>{!this.props.showDesigner ? this.props.t("When is your trip") : this.props.t("When do you plan to arrive", { city: this.props.eventCity && this.props.eventCity.cityText ? this.props.eventCity.cityText : "" })}</h2>
								<p className="form-txt">{!this.props.showDesigner ? this.props.t("We will list the trip plans suitable for your dates.") : this.props.t("We’ll check ’s plan is suitable for your travel window or not.")}</p>
								<div className="date-wrap fc-wrap wm">
									<div className="date-picker-wrap">
										<DateRangePicker
											minimumNights={0}
											hideKeyboardShortcutsPanel
											readOnly
											startDatePlaceholderText={this.props.t("Arrival Date")}
											endDatePlaceholderText={this.props.t("Return Date")}
											withFullScreenPortal={this.state.isMobile}
											orientation={this.state.isMobile ? "vertical" : "horizontal"}
											startDateId="startDate"
											endDateId="endDate"
											startDate={this.state.startDate}
											endDate={this.state.endDate}
											onKeyPress={this.handleKeyPress}
											onDatesChange={({ startDate, endDate }) => { this.setState({ startDate, endDate }) }}
											focusedInput={this.state.focusedInput}
											onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }}
										/>
									</div>
								</div>
								<p className="form-txt">{!this.props.showDesigner ? this.props.t("Which email address should we send your trip plan?") : this.props.t("Which email address should we send your confirmation?")}</p>
								<div className="email-wrap fc-wrap wm">
									<TextField margin="none"
										value={this.state.email}
										onChange={this.emailChangedHandler}
										label="Email"
										variant="outlined"
										size="small"
										fullWidth
										onFocus={this.shrinkLabel}
										onBlur={this.unShrinkLabel}
										InputLabelProps={{ shrink: this.state.email ? true : false, required: false }}
										onKeyPress={this.handleKeyPress}
										required
										type="email"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<MailOutlineIcon />
												</InputAdornment>
											),
										}}
										inputRef={this.emailInput}
									/>
								</div>
								<p className="form-txt" >{this.props.t("What is your name?")}</p>
								<div className="txt-wrap fc-wrap wm  d-flex justify-content-between align-items-center">
									<TextField margin="none"
										value={this.state.name}
										label={this.props.t("Name")}
										variant="outlined"
										size="small"
										fullWidth
										required
										onKeyPress={this.handleKeyPress}
										InputLabelProps={{ shrink: this.state.name ? true : false, required: false }}
										onChange={this.nameChangedHandler}
										inputRef={this.nameInput}

									/>
									<div style={{ width: "12px", flexShrink: 0 }} />
									<TextField margin="none"
										value={this.state.surname}
										label={this.props.t("Surname")}
										variant="outlined"
										size="small"
										fullWidth
										required
										InputLabelProps={{ shrink: this.state.surname ? true : false, required: false }}
										onChange={this.surnameChangedHandler}
										onKeyPress={this.handleKeyPress}
										inputRef={this.surnameInput}
									/>

								</div>
								<div className='txt-wrap fc-wrap ws  d-flex align-items-center'>
									<Checkbox color="primary" checked={this.props.privacy} onChange={this.handlePrivacyChange} />
									<p className="form-txt-policy" dangerouslySetInnerHTML={{ __html: this.props.t("PrivacyPolicyText") }} />
								</div>
								{this.props.showDesigner  ?
								<div  className='txt-wrap fc-wrap ws  d-flex align-items-center'>
									<Checkbox color="primary" checked={this.props.terms} onChange={this.handleTermsChange} />
									<p className="form-txt-policy" dangerouslySetInnerHTML={{ __html: this.props.t("TermsOfServicesText") }} />
								</div>
								:""
								}
								{this.state.showValidation && true ?
									<div className="validation name-val">
										<p>{this.state.showValidation}</p>

									</div> : ""}
								{this.props.showDesigner ? <button className="btn-d" disabled={!this.props.terms || !this.props.privacy} onClick={() => this.findPlan()} >{!this.props.showDesigner ? this.props.t("Show me available trip plans"):this.props.t("Can I use this plan")}</button> : null}
				    			{!this.props.showDesigner ?<button className="btn-d" disabled={!this.props.privacy} onClick={() => this.toCheckBrowse()} >{!this.props.showDesigner ? this.props.t("Show me available trip plans"):this.props.t("Can I use this plan")}</button>:null}

								
								<div className="loading-wrap" style={{ display: this.state.loading ? "block" : "none" }}>
									<div className="center-center">
										<div class="spinner-border" role="status">
											<span class="sr-only">{this.props.t("Loading...")}</span>
										</div>
									</div>
								</div>

							</div>
							: null}
						{this.state.status == "available" ?

							<div className="main-content av-container">
								<div className="d-flex justify-content-center">
									<img className="sc-logo" src={success} />
								</div>
								<h5 className="title">{this.props.t("Yes, you can use this plan")}</h5>
								{!this.singleDateAvailable() ?
									<div style={{ visibility: this.singleDateAvailable() ? "hidden" : "visible" }} >
										<p className="txt st">{this.props.t("Your trip designer suggested the best possible dates for this plan.")}</p>
										<p className="txt st pick-one">{this.props.t("Please pick one.")}</p>
									</div>
									: null}


								{!this.singleDateAvailable() ?

									<div className="avdays-overflow">
										<PerfectScrollbar>

											<div className="avdays-container">
												<div className={"avdays-wrap d-flex align-items-center flex-wrap avdays-wrap-d" + this.betweenDates().length}>

													{

														this.betweenDates().map((dt, i) =>

															this.state.avDays.filter(adt => adt.isSame(dt)).length > 0 ?

																<div key={dt} className={this.state.selectedAvDate && this.state.selectedAvDate.isSame(dt) ? "avDay selected avd" + this.betweenDates().length : "avDay avd" + this.betweenDates().length} style={{ cursor: "pointer" }}
																	onClick={() => this.selectAvDate(dt)}>
																	<p className="dnum truncate">{dt.format('DD')}</p>
																	<p className="truncate">{dt.format('MMM')}</p>
																</div>

																:

																<div key={dt} className={"not avDay avd" + this.betweenDates().length}>
																	<p className="dnum truncate">{dt.format('DD')}</p>
																	<p className="truncate">{dt.format('MMM')}</p>
																</div>

														)

													}
												</div>

											</div>
										</PerfectScrollbar>
									</div> : null}
								<div className="validation name-val">{this.state.showValidation || true ?
									<p>{this.state.showValidation}</p>
									: null
								}</div>

								<p className="full-txt">{this.props.t("To Get Full Access")}</p>
								<button className="btn-d" onClick={() => this.createOrder()} >{this.props.t("Continue to checkout")}</button>
								<div className="loading-wrap" style={{ display: this.state.loading ? "block" : "none" }}>
									<div className="center-center">
										<div class="spinner-border" role="status">
											<span class="sr-only">{this.props.t("Loading...")}</span>
										</div>
									</div>
								</div>
							</div>
							: null}
						{
							this.state.status == "browse" ?
								<div className="main-content">
									<p className="browse-txt" dangerouslySetInnerHTML={{ __html: this.props.t("PlanNotApplicableText", { designerName: this.props.designerName, startDate: this.state.startDate.format('DD MMM'), endDate: this.state.endDate.format('DD MMM'), otherPlansLength: this.state.otherPlansLength }) }} />
									<h2 className="browse-title " dangerouslySetInnerHTML={{ __html: this.props.t("Would you like to review these plans?") }} />
									<a ><button className="btn-d browse-btn" onClick={() => this.toBrowse()} >{this.props.t("Yes, take me to available plans.")}</button></a>
								</div>
								: null
						}

						{this.props.showFirst ?
							<div className="showfirst-info">
								<div className="avatar bgImg" style={{ backgroundImage: "url(" + popupimage + ")" }} ></div>
									<div className="features">
										<div className="plan-head d-flex align-items-center">
											<p>{this.props.t("features headline")}</p>
										</div>
										<div className="plan-feature d-flex align-items-center">
											<img  src={exclamationPoint} />
											<p>{this.props.t("features exclamationPoint")}</p>
										</div>
										<div className="plan-feature d-flex align-items-center">
											<img src={placeholder} />
											<p>{this.props.t("features placeholder")}</p>
										</div>
										<div className="plan-feature d-flex align-items-center">
											<img src={appointment} />
											<p>{this.props.t("features appointment")}</p>
										</div>
										<div className="plan-feature d-flex align-items-center">
											<img src={picture} />
											<p>{this.props.t("features picture")}</p>
										</div>
										<div className="plan-feature d-flex align-items-center">
											<img src={tree} />
											<p>{this.props.t("features tree")}</p>
										</div>
										<div className="plan-feature d-flex align-items-center">
											<img className="flags" src={flags} />
											<p>{this.props.t("features flags")}</p>
										</div>
										<div className="plan-feature d-flex align-items-center">
											<img className="pin" src={pin} />
											<p>{this.props.t("features pin")}</p>
										</div>
										<div className="plan-feature d-flex align-items-center">
											<img className="clock" src={clock} />
											<p>{this.props.t("features clock")}</p>
										</div>
										<div className="plan-feature d-flex align-items-center">
											<img className="homeMarker" src={homeMarker} />
											<p>{this.props.t("features homeMarker")}</p>
										</div>
										<div className="plan-feature d-flex align-items-center">
											<img src={clipboard} />
											<p>{this.props.t("features clipboard")}</p>
										</div>
									</div>
								</div>
							: null
						}


						{this.props.showDesigner ?
							<div className="designer-info">
								<div className="avatar bgImg" style={{ backgroundImage: "url(" + this.props.designerImage + ")" }} ></div>
								<div className="name" dangerouslySetInnerHTML={{ __html: this.props.t("Your trip designer is", { designerName: this.props.designerName }) }} />
								<div className="logo"><img className="logo" src={this.props.t("MainLogo2")} /></div>
							</div>
							: null
						}
					</div>
				</div>
			</div>
		);
	}

	componentDidMount() {

		if (this.props.startDate && this.props.endDate) {
			this.setState({ startDate: moment(this.props.startDate), endDate: moment(this.props.endDate) });
		}

		if (this.props.email) {

			this.setState({ email: this.props.email });
		}
		if (this.props.city) {

			this.setState({ city: this.props.city });
		}

		if (this.props.firstName) {

			this.setState({ name: this.props.firstName });
		}

		if (this.props.surName) {

			this.setState({ surname: this.props.surName });
		}


		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	/*focusNames(){
		this.nameInput.current.focus();
		this.surnameInput.current.focus();
	}*/

	resize() {

		this.setState({ isMobile: window.innerWidth <= 760 });
		//console.log("res",this.state.isMobile);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}


	createOrder() {
		if (!this.state.selectedAvDate) {
			this.setState({ showValidation: "*Please select an available day." });
			return false;
		} if (!this.state.name || !this.state.surname) {
			this.setState({ showValidation: "*Please fill in your full name." });
			return false;
		}

		var payload = this.state.payload;

		payload.fullName = this.state.name + " " + this.state.surname;
		payload["startDate"] = moment(this.state.selectedAvDate).format('YYYY-MM-DD');

		this.props.onFullName(this.state.name + " " + this.state.surname);
		this.props.onNameSurname({ firstName: this.state.name, surName: this.state.surname });
		this.setState({ loading: true });
		var that = this;
		axios.post('SourceTr/generateOrder?stripe=0', payload)
			.then(function (response) {

				const guid = response.data.url.split("/")[response.data.url.split("/").length - 1];
				that.props.onBasketId(guid);
				window.location.href = response.data.url;

				that.props.redirect(`/${that.props.i18n.language}/checkout/${guid}${that.props.couponCode != "" ? `?couponCode=${that.props.couponCode}` : ""}`);

			});


	}

	selectAvDate(date) {
		console.log("selectAvDate", date);
		this.setState({ selectedAvDate: date });
	}


	toCheckBrowse() {
		console.log("toCheckBrowse");

		if (this.state.startDate == null || this.state.endDate == null || this.state.email == null || this.state.name == null || this.state.surname == null) {
			this.setState({ showValidation: "*Please fill all the fields" });
			return false;
		} else if (this.state.email) {
			const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			const validEmail = re.test(String(this.state.email).toLowerCase());

			if (!validEmail) {
				this.setState({ showValidation: "*Please enter a valid email address" });
				return false;
			}

			var that = this;
			that.props.onEmail(this.state.email);
			that.props.onFullName(this.state.name + " " + this.state.surname);
			that.props.onNameSurname({ firstName: this.state.name, surName: this.state.surname });
			that.props.onDates({ startDate: this.state.startDate, endDate: this.state.endDate });

			this.setState({ showValidation: null, loading: true });
			setTimeout(
				() => this.toBrowse(),
				2000
			);
			//this.toBrowse();
		}

	}

	toCheckFirst() {
		if (this.state.startDate == null || this.state.endDate == null || this.state.email == null || this.state.name == null || this.state.surname == null) {
			this.setState({ showValidation: "*Please fill all the fields" });
			return false;
		} else if (this.state.email) {
			const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			const validEmail = re.test(String(this.state.email).toLowerCase());

			if (!validEmail) {
				this.setState({ showValidation: "*Please enter a valid email address" });
				return false;
			}

			var that = this;
			that.props.onEmail(this.state.email);
			that.props.onFullName(this.state.name + " " + this.state.surname);
			that.props.onNameSurname({ firstName: this.state.name, surName: this.state.surname });
			that.props.onDates({ startDate: this.state.startDate, endDate: this.state.endDate });

			this.setState({ showValidation: null, loading: true });
			this.props.onClose();
			// setTimeout(
			// 	() => this.toBrowse(),
			// 	2000
			// );
			// //this.toBrowse();
		}

	}

	toAvailableDays() {

		this.setState({ status: "available" }, this.checkAndSelectIfSingle);
		if (this.props.firstName && this.props.surName) {

			this.setState({ name: this.props.firstName, surname: this.props.surName });
		}

	}


	checkAndSelectIfSingle() {

		if (this.singleDateAvailable()) {

			this.selectAvDate(this.state.startDate.startOf('day'));


		}

	}

	toBrowse() {
		var url = "/" + this.props.i18n.language + "/browse/" + this.state.startDate.format('YYYY-MM-DD') + "/" + this.state.endDate.format('YYYY-MM-DD');
		if (this.props.eventCity && this.props.eventCity.city != null)
			url = url + "/" + this.props.eventCity.city;
		this.props.redirect(url);
	}

	findPlan() {

		if (!this.state.startDate || !this.state.endDate || !this.state.email || !this.state.name || !this.state.surname) {
			this.setState({ showValidation: "*Please fill all the fields." });
			return false;
		} else if (this.state.email) {
			const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			const validEmail = re.test(String(this.state.email).toLowerCase());

			if (!validEmail) {
				this.setState({ showValidation: "*Please enter a valid email address" });
				return false;
			}

			this.setState({ showValidation: null });
		}

		if (this.state.startDate && this.state.endDate && this.state.email) {

			this.setState({ showValidation: false });
			var langStr;
			if (this.props.i18n.language == "tr") {
				langStr = { "id": "Languages", "value": "TR" };
			}
			else {
				langStr = { "id": "Languages", "value": "EN" };
			}

			this.props.onEmail(this.state.email);
			this.props.onFullName(this.state.name + " " + this.state.surname);
			this.props.onNameSurname({ firstName: this.state.name, surName: this.state.surname });
			this.props.onDates({ startDate: this.state.startDate, endDate: this.state.endDate });

			var payload = {
				"email": this.state.email,
				"fullName": this.state.name != "" ? this.state.name + " " + this.state.surname : "",
				"startDate": this.state.startDate.format('YYYY-MM-DD'),
				"endDate": this.state.endDate.format('YYYY-MM-DD'),
				"requirements": [

				],
				"filtered": [
					langStr
				],
				"value": "xN9e/VFTTlgkaQMeqLrP+w==",
				"currency": "Euro"
			}

			if (this.props.planId) {
				payload["requirements"].push(
					{
						"id": "planid",
						"value": this.props.planId.toString()
					}
				);

				payload["requirements"].push(
					{
						"id": "count",
						"value": "1"
					}
				)
			}

			if( this.props.couponCode != undefined && this.props.couponCode != null && this.props.couponCode != "")
			{
				payload["requirements"].push(
					{
						"id": "couponcode",
						"value": this.props.couponCode
					}
				)
			}

			if (this.props.eventCity && this.props.eventCity.city != null) {

				payload["requirements"].push(
					{
						"id": "city",
						"value": this.props.eventCity.city.toString()
					}

				)

			}

			this.setState({ loading: true });
			var that = this;
			axios.post('/Order/planFinder', payload)
				.then(function (response) {
					that.setState({ loading: false });

					const data = response.data.data;
					that.props.onUserToken(data.user.token);

					if (data.isApplicable) {

						const headers = {
							'Authorization': 'Bearer ' + data.user.token
						}

						payload["startDate"] = moment(data.applicableDate).format('YYYY-MM-DD');


						var apDays = data.aplicableDays.map((ad, i) => moment(ad).startOf('day'))


						that.setState({ payload: payload, avDays: apDays }, that.toAvailableDays);

						setTimeout(() => {
							if (payload.email != undefined && payload.email != null && payload.email != "") {
							  new Connector(this.props.t("ActiveCamiaignActid"), this.props.t("ActiveCamiaignActKey"), apiKey, apiUrl).trackSite("", { url: encodeURIComponent(window.location.href), referrer: encodeURIComponent(window.location.href) }, payload.email);
							}
						  }, 5000);

					} else {
						console.log(data.planList);
						that.props.onOtherPlans(data.planList);
						that.setState({ otherPlansLength: data.planList.length });
						that.setState({ status: "browse" });
					}
				});

		}
	}
}
const mapStateToProps = state => {
	return {
		//token: state.usr.token,
		lang: state.usr.lang,
		//basketId:state.usr.basketId,
		fullName: state.usr.fullName,
		privacy:state.usr.privacy,
		terms:state.usr.terms,
		email: state.usr.email,
		startDate: state.usr.startDate,
		endDate: state.usr.endDate,
		firstName: state.usr.firstName,
		surName: state.usr.surName,
		couponCode: state.usr.couponCode
	}
};


const mapDispatchToProps = dispatch => {
	return {
		onUserToken: (token) => dispatch({ type: acTypes.SET_TOKEN, token: token }),
		onBasketId: (basketId) => dispatch({ type: acTypes.SET_BASKET_ID, basketId: basketId }),
		onOtherPlans: (plans) => dispatch({ type: acTypes.SET_OTHER_PLANS, plans: plans }),
		onEmail: (email) => dispatch({ type: acTypes.SET_EMAIL, email: email }),
		onFullName: (fullName) => dispatch({ type: acTypes.SET_FULLNAME, fullName: fullName }),
		onNameSurname: (nameObj) => dispatch({ type: acTypes.SET_NAME_SURNAME, nameObj: nameObj }),
		onDates: (datesObj) => dispatch({ type: acTypes.SET_DATES, datesObj: datesObj }),
		onCity: (city) => dispatch({ type: acTypes.SET_CITY, city: city }),
		onPrivacy: (privacy) => dispatch({ type: acTypes.SET_PRIVACY, privacy: privacy }),
		onTerms: (terms) => dispatch({ type: acTypes.SET_TERMS, terms: terms }),
		onCouponCode: (couponCode) => dispatch({ type: acTypes.SET_COUPON_CODE, couponCode: couponCode }),

	}
};

export default compose(translate("translations"), connect(mapStateToProps, mapDispatchToProps))(DateSelectPopup);
DateSelectPopup.defaultProps = {
	"showDesigner": true
};
