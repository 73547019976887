import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import './BrowsePlans.scss';
import PlanStory from './../PlanStory/PlanStory'
import close from './../../assets/icons/close.svg';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import compose from 'compose-function';
import { withRouter } from "react-router-dom";



class BrowsePlans extends Component {
  constructor() {
    super();
    this.state = {
      langs: [],
      cities: []
    };
  }
 
  componentDidMount(){
    axios.get(`/Organization/getCities/${this.props.lang}`)
            .then(response => {
              this.setState({ cities: response.data});
            });
    axios.get(`/Organization/getLangs/${this.props.city}`)
            .then(response => {
              this.setState({ langs: response.data});
            });

  }

  handleClick = (city) => {
    this.props.history.push(`/${this.props.lang === 2 ? "ru" : this.props.lang === 0 ? "tr":"en" }/browse?city=${city}`);
    setTimeout(() => window.location.reload(false), 500);
  }
  
  handleClick2 = (lang) => {
    this.props.history.push(`/${lang === 0 ? "tr" : lang === 1 ? "en":"ru" }/browse?city=${this.props.city}`);
    setTimeout(() => window.location.reload(false), 500);
  }
  
  render() {
    const { t } = this.props;
    return (
            <div className="BrowsePlans">
              <div className="container">
                <div className='navs'>
                  <nav>
                    <ul>
                      <li class="maindrop">{this.props.lang != null ? this.props.t(`Language${this.props.lang}`): this.props.t(`Language0`)}
                        <ul class="dropdown">
                          {
                            this.state.langs.map(s=> {
                              return <li onClick={() => this.handleClick2(s)} >{this.props.t(`Language${s}`)}</li>
                            })
                          }
                        </ul>
                      </li>
                    </ul>
                  </nav>
                  <nav>
                    <ul>
                      <li class="maindrop">{this.props.city != null ? this.props.t(`MyCitiesCity${this.props.city}`): this.props.t(`MyCitiesCity0`)}
                        <ul class="dropdown">
                          {
                            this.state.cities.map(s=> {
                              return <li class="subdrop" onClick={() => this.props.oncityChange(s)}>{this.props.t(`MyCitiesCity${s}`)}</li>
                            })
                          } 
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              <div className="change-notice" >
                      <p className="title">{this.props.t("Browse all plans", { city: this.props.city != null ? this.props.t(`City_${this.props.city}`):this.props.t(`City_${this.props.city}`)})}</p>
                      <p className="txt">{this.props.t("You can choose a suitable one for yourself and see its details.")}</p>
                  </div>

              <div className="stories-container">
                <div className="stories-wrap d-flex justify-content-start flex-wrap">
                        { this.props.plans.map((plan, i) => <a key={i}  href={`/${plan.languageString}/plan/${plan.uniqId}/${plan.planUrl}`} ><PlanStory plan={plan} /></a>) }
                </div>
              </div>
              </div>
            </div>
            );
    }
  }

BrowsePlans.propTypes = {};

BrowsePlans.defaultProps = {};
const mapStateToProps = state => {
  
};
const mapDispatchToProps = dispatch => {
 
};



export default withRouter(compose(translate("translations"), connect(mapStateToProps, mapDispatchToProps))(BrowsePlans));
