import React from 'react';
import './DesignerCountries.scss';
import { translate } from 'react-i18next';

class DesignerCountries extends React.Component{ 

  state={
    countriesSize:8,
    isMobile:false

  }

  showAllCountries(){
    this.setState({countriesSize:this.props.countries.length});
  }

  componentDidMount() {
      window.addEventListener("resize", this.resize.bind(this));
      this.resize();
  }

  resize() {
    
      this.setState({isMobile: window.innerWidth <= 1170});
  }

  componentWillUnmount() {
      window.removeEventListener("resize", this.resize.bind(this));
  }

   render(){
    const { t } = this.props;
 return(
  <div className="DesignerCountries">
  	{this.props.countries.length > 0 ? <div>
      	<h4 className="section-title">{t("Visited Countries")}</h4>
        <div className="countries-container">
        	<div className="countries-wrap d-flex justify-content-start flex-wrap">
          	{this.props.countries.slice(0, this.state.isMobile ? this.props.countries.length:this.state.countriesSize).map((country, index) =><div style={{ backgroundImage:  "url(" + "/images/countries/" + encodeURIComponent(country) + ".jpg)" }} className="country bgImg" key={"c"+index}><div className="name">{t(country)}</div></div>)}
          </div>
        </div>
        { 
        this.props.countries.length > this.state.countriesSize && !this.state.isMobile?
          <div className="show-more-wrap">
            <button className="btn-o-d sm-btn" onClick={() => this.showAllCountries()} >{t("Show More")}</button>
          </div>
        :null}

       </div>:null} 
  </div>

)};

}

DesignerCountries.defaultProps = {
  countries:[]
};

export default translate("translations")(DesignerCountries);
