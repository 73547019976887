import React from 'react';
import PropTypes from 'prop-types';
import './ShareFriendItem.scss';
import deleteBtn from './../../../../assets/icons/delete-button.svg';
import TextField from '@material-ui/core/TextField';
import { translate } from 'react-i18next';

class ShareFriendItem extends React.Component{

  state={

   email:{
       focus:false,
       value:"",
       valid:false,
       dirty:false
   },
   name:{
       focus:false,
       value:"",
       valid:false,
       dirty:false
   },
   lastName:{
       focus:false,
       value:"",
       valid:false,
       dirty:false
   },
   formValid:false

  }

  onChangeEmail(event) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const valid = re.test(event.target.value);
      this.setState({email: {...this.state.email, value: event.target.value,valid:valid}},() => this.updateForm());
  }

  onName(event) {
      const valid = event.target.value ? true:false;
      this.setState({name: {...this.state.name, value: event.target.value,valid:valid}},() => this.updateForm());
  }
  onLastName(event) {
      const valid = event.target.value ? true:false;
      this.setState({lastName: {...this.state.lastName, value: event.target.value,valid:valid}},() => this.updateForm());
  }


  updateForm(){
    console.log(this.state);
    /*var valid = false;
    if(this.state.email.valid &this.state.name.valid & this.state.lastName.valid){

      this.setState({formValid: valid});
    }*/

  this.props.update(this.props.uid,{
        name:this.state.name.value,
        surName:this.state.lastName.value,
        email:this.state.email.value,
        id:this.props.uid
      });


  }



render(){
  const { t } = this.props;
return(
  <div className="ShareFriendItem">
  			<button className="delBtn" onClick={()=>this.props.remove(this.props.uid)} ><img src={deleteBtn} alt="del"/></button>
  			<div className="form-wrap">
         			<form noValidate autoComplete="off">

               <div className="form-inline d-flex justify-content-between">
                              <TextField margin="normal"
                                label={t("Name")}
                                variant="outlined"
                                size="small"
                                onFocus={() => this.setState({name: {...this.state.name, focus: true,dirty:true}})} 
                                onBlur={() => this.setState({name: {...this.state.name, focus: false,dirty:true}})} 
                                error={
                                  this.state.name.dirty  && !this.state.name.focus ?
                                  this.state.name.valid ? false:true : false}
                                required
                                onChange={this.onName.bind(this)}
                              />

                              <TextField margin="normal" style={{ marginLeft: 24 }}
                                label={t("Surname")}
                                variant="outlined"
                                size="small"
                                onFocus={() => this.setState({lastName: {...this.state.lastName, focus: true,dirty:true}})} 
                                onBlur={() => this.setState({lastName: {...this.state.lastName, focus: false,dirty:true}})} 
                                error={
                                  this.state.lastName.dirty  && !this.state.lastName.focus ?
                                  this.state.lastName.valid ? false:true : false}
                                required
                                onChange={this.onLastName.bind(this)}
                              />
                              </div>
                              <TextField 
                                margin="normal"
                                label="Email"
                                variant="outlined"
                                size="small"
                                fullWidth
                                onFocus={() => this.setState({email: {...this.state.email, focus: true,dirty:true}})} 
                                onBlur={() => this.setState({email: {...this.state.email, focus: false,dirty:true}})}                               
                                error={
                                  this.state.email.dirty  && !this.state.email.focus ?
                                  this.state.email.valid ? false:true : false}
                                required
                                type="email"
                                onChange={this.onChangeEmail.bind(this)}
                              />
                              
                          </form>
            </div>
  </div>
)};

}


ShareFriendItem.defaultProps = {};

export default translate("translations")(ShareFriendItem);
