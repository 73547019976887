export const SET_TOKEN = 'SET_TOKEN';
export const SET_BASKET_ID = 'SET_BASKET_ID';
export const SET_COUPON_CODE = 'SET_COUPON_CODE';
export const SET_LANG = 'SET_LANG';
export const ADD_PLAN = 'ADD_PLAN';
export const REMOVE_PLAN = 'REMOVE_PLAN';
export const SET_OTHER_PLANS = 'SET_OTHER_PLANS';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_FULLNAME = 'SET_FULLNAME';
export const SET_NAME_SURNAME = 'SET_NAME_SURNAME';
export const SET_DATES = 'SET_DATES';
export const SET_CITY = 'SET_CITY';
export const SET_OPENNING = 'SET_OPENNING';
export const SET_PRIVACY = 'SET_PRIVACY';
export const SET_TERMS = 'SET_TERMS';
