import React from 'react';
import './EventActions.scss';
import { translate } from 'react-i18next';

const EventActions = (props) => {
	const { t } = props;

return(

  <div className="EventActions">
    <p className="action-txt">{t("Fully access to this plan for (one time)",{currency: t(props.currency), price: props.price })}</p>
    <button className={`btn-d ${props.status !== 10 ? "dis":""}`} onClick={props.fullAccess} disabled={props.status !== 10} >{t(`Get Full Access_${props.status}`)}</button>
    <p className="action-txt">{t("%100 satisfaction guaranteed.")}</p>
    <div className="or"></div>
    <p className="action-txt">{t("Browse other plans matching my trip dates")}</p>
    <button className="btn-w" onClick={props.browse}>{t("Browse Plans")}</button>
  </div>
);

}

EventActions.defaultProps = {};

export default translate("translations")(EventActions);
