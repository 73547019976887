import React from 'react';
import PropTypes from 'prop-types';
import './EventCheckList.scss';
import { translate } from 'react-i18next';
import more from './../../../../assets/icons/gc-arrow.svg';

const EventCheckList = (props) => (
  <div className="EventCheckList">
   {props.doSee ||
props.doTaste ||
props.doBuy ||
props.doPhotographing ||
props.doDo ||
props.doRead ||
props.doListen ||
props.doWarning  ?

    <h3 className="sub-title">{props.t("Golden CheckList")}</h3>
:null}

    {
    	props.doSee ?
		    <div className="checklist-item">
			    <h5>{props.t("See")}</h5>
			    <p>{props.doSee}</p>
				{props.doSeeLink != null && props.doSeeLink != "" &&   <a href={props.doSeeLink} target="_blank" rel="nofollow noreferrer noopener">{props.t("Click here for more information")} <img src={more} alt="more"/></a>}
			</div>
		:null
	}

	{
    	props.doTaste ?
		    <div className="checklist-item">
			    <h5>{props.t("Taste")}</h5>
			    <p>{props.doTaste}</p>
				{props.doTasteLink != null && props.doTasteLink != "" &&   <a href={props.doTasteLink} target="_blank" rel="nofollow noreferrer noopener">{props.t("Click here for more information")} <img src={more} alt="more"/></a>}
			</div>
		:null
	}
    {
    	props.doBuy ?
		    <div className="checklist-item">
			    <h5>{props.t("Buy")}</h5>
			    <p>{props.doBuy}</p>
				{props.doBuyLink != null && props.doBuyLink != "" &&   <a href={props.doBuyLink} target="_blank" rel="nofollow noreferrer noopener">{props.t("Click here for more information")} <img src={more} alt="more"/></a>}
			</div>
		:null
	}
    {
    	props.doPhotographing ?
		    <div className="checklist-item">
			    <h5>{props.t("Photo Point")}</h5>
			    <p>{props.doPhotographing}</p>
				{props.doPhotographingLink != null && props.doPhotographingLink != "" &&   <a href={props.doPhotographingLink} target="_blank" rel="nofollow noreferrer noopener">{props.t("Click here for more information")} <img src={more} alt="more"/></a>}
			</div>
		:null
	}
    {
    	props.doDo ?
		    <div className="checklist-item">
			    <h5>{props.t("Do")}</h5>
			    <p>{props.doDo}</p>
				{props.doDoLink != null && props.doDoLink != "" &&   <a href={props.doDoLink} target="_blank" rel="nofollow noreferrer noopener">{props.t("Click here for more information")} <img src={more} alt="more"/></a>}
			</div>
		:null
	}
    {
    	props.doRead ?
		    <div className="checklist-item">
			    <h5>{props.t("Read")}</h5>
			    <p>{props.doRead}</p>
				{props.doReadLink != null && props.doReadLink != "" &&   <a href={props.doReadLink} target="_blank" rel="nofollow noreferrer noopener">{props.t("Click here for more information")} <img src={more} alt="more"/></a>}
			</div>
		:null
	}
    {
    	props.doListen ?
		    <div className="checklist-item">
			    <h5>{props.t("Listen")}</h5>
			    <p>{props.doListen}</p>
				{props.doListenLink != null && props.doListenLink != "" &&   <a href={props.doListenLink} target="_blank" rel="nofollow noreferrer noopener">{props.t("Click here for more information")} <img src={more} alt="more"/></a>}
			</div>
		:null
	}
    {
    	props.doWarning ?
		    <div className="checklist-item">
			    <h5>{props.t("Don't")}</h5>
			    <p>{props.doWarning}</p>
				{props.doWarningLink != null && props.doWarningLink != "" &&   <a href={props.doWarningLink} target="_blank" rel="nofollow noreferrer noopener">{props.t("Click here for more information")} <img src={more} alt="more"/></a>}
			</div>
		:null
	}
  </div>
);

EventCheckList.propTypes = {};

EventCheckList.defaultProps = {};


export default translate("translations")(EventCheckList);