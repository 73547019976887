import * as acTypes from '../actions';

const initialState = {
    lang: 0,
    token: null,
    city: 0,
    firstOpenning:false,
    basketId: null,
    order: [],
    otherPlans: [],
    fullName: "",
    email: "",
    startDate: null,
    endDate: null,
    firstName: null,
    surName: null,
    privacy:false,
    terms:false,
    couponCode: ""
};

const reducer = (state = initialState, action) => {
    const newState = Object.assign({}, state);
    console.log(state);
    switch (action.type) {
        case acTypes.ADD_PLAN:
            return {
                ...state,
                order: state.order.concat(action.plan)
            }
        case acTypes.REMOVE_PLAN:
            return {
                ...state,
                order: state.order.filter(plan => plan.id !== action.plan.id)
            }
        case acTypes.SET_LANG:
            newState.lang = action.lang
            return {
                ...state,
                lang: newState
            }
        case acTypes.SET_TOKEN:
            newState.token = action.token
            return newState
        case acTypes.SET_BASKET_ID:
            newState.basketId = action.basketId
            return newState
        case acTypes.SET_COUPON_CODE:
            newState.couponCode = action.couponCode
            return newState
        case acTypes.SET_EMAIL:
            newState.email = action.email
            return newState
        case acTypes.SET_FULLNAME:
            newState.fullName = action.fullName
            return newState
        case acTypes.SET_NAME_SURNAME:
            newState.firstName = action.nameObj.firstName;
            newState.surName = action.nameObj.surName;
            return newState
        case acTypes.SET_DATES:
            newState.startDate = action.datesObj.startDate;
            newState.endDate = action.datesObj.endDate;
            return newState
        case acTypes.SET_CITY:
            newState.city = action.city;
            return newState
        case acTypes.SET_TERMS:
            newState.terms = action.terms;
            return newState
        case acTypes.SET_PRIVACY:
            newState.privacy = action.privacy;
            return newState
        case acTypes.SET_CITY:
            newState.city = action.city;
            return newState
        case acTypes.SET_OPENNING:
            newState.firstOpenning = true;
            return newState
    }
    return state;
};

export default reducer;