import React from 'react';
import PropTypes from 'prop-types';
import { Player, BigPlayButton } from 'video-react';
import Slider from "react-slick";
import { translate } from 'react-i18next';
import ReactPlayer from 'react-player'
import './PlanPreview.scss';
import PlanRating from './../PlanRating/PlanRating'
import PlanProfile from './../PlanProfile/PlanProfile'
import PlanTags from './../PlanTags/PlanTags'
import moment from 'moment';

const sliderSettings = {
	dots: false,
	arrows: true,
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1
};




const PlanPreview = (props) => {

	const [moreHistory, setMoreHistory] = React.useState(false);
	const [moreBeforeStarting, setMoreBeforeStarting] = React.useState(false);
	const [player, setPlayer] = React.useState(null);

	const playVideo = () => {
		if (player) {
			player.play();
		}
	};
	const rawMarkup =(data) => {
		var rawMarkup = data;
		return { __html: rawMarkup };
	  }

	return (

		<div className="PlanPreview">
			<PlanProfile {...props.profile} eventCityId={props.plan.eventCity} cities={props.plan.tridDesignerCities} />

			{props.plan.themeVideo ?
				<div className="video-container">
					<ReactPlayer url={props.plan.themeVideo} ref={playerObj => { setPlayer(playerObj); }} width='100%' />
					{/* <Player src={props.plan.themeVideo} ref={playerObj => { setPlayer(playerObj); }}>
						<BigPlayButton position="center" />
					</Player> */}
				</div>
				: null
			}
			{ !props.plan.themeVideo && props.plan.themeImages.length > 0 ?
				<div className="plan-images">
					<Slider {...sliderSettings}>

						{
							props.plan.themeImages.map((image, index) => {
								return <div key={"ei-" + index} ><div className="plan-image" style={{ backgroundImage: "url(" + image + ")" }} alt={props.plan.themeOfTheDay}></div></div>
							})}

					</Slider>
				</div>
				: null
			}
			{
				props.t("VisibleAreaPlanNameAndDescription") !== "VisibleAreaPlanNameAndDescription" && props.t("VisibleAreaPlanNameAndDescription") === "True" &&
				<>
				<p className="plan-name-txt" >{props.t("PlanNameTitle")}</p>
				<h1>{props.title}</h1>
				<p style={{ marginBottom: "15px" }}>{props.desc}</p>
				</>
			}
			
			{props.t("VisibleAreaRate") !== "VisibleAreaRate" && props.t("VisibleAreaRate") === "True" &&
				props.profile && props.profile.tdRatingCount > 0 ? <PlanRating rating={props.profile.tdRating} name={props.profile.fullName} /> : null}
			{props.t("VisibleAreaPlanIncludes") !== "VisibleAreaPlanIncludes" && props.t("VisibleAreaPlanIncludes") === "True" &&
			<div className="plan-tags-wrap">
				<p className="tags-title">{props.t("This Plan Includes")}</p>
				{props.plan ? <PlanTags {...props.plan} /> : null}
			</div>
			}
			{props.plan ?
				<>
					<div className="full-access-data">
						{props.isFullAccess && <>
							<div className="d-flex tags-hor flex-wrap">
								<div className="plan-tags-wrap">
									<p className="tags-title">{props.t("Min Max People")}</p>
									<div className="PlanTags"><div class="tag"> {props.t("PEOPLE",{min: props.plan.minPerson, max: props.plan.maxPerson})}</div></div>
								</div>
								{props.plan && props.plan.availableStartDate && props.plan.availableEndDate ?
									<div className="plan-tags-wrap">
										<p className="tags-title">{props.t("Start / End")}</p>
										<div className="PlanTags"><div class="tag"> {moment(props.plan.availableStartDate).format('DD-MM-YYYY')} / {moment(props.plan.availableEndDate).format('DD-MM-YYYY')} </div></div>
									</div>
									: null}
								<div className="plan-tags-wrap">
									<p className="tags-title">{props.t("Available Days")}</p>
									<div className="PlanTags">
										{props.plan.availableDays.monday ? <div class="tag"> {props.t("monday")} </div> : null}
										{props.plan.availableDays.tuesday ? <div class="tag"> {props.t("tuesday")} </div> : null}
										{props.plan.availableDays.wednesday ? <div class="tag"> {props.t("wednesday")} </div> : null}
										{props.plan.availableDays.thursday ? <div class="tag"> {props.t("thursday")} </div> : null}
										{props.plan.availableDays.friday ? <div class="tag"> {props.t("friday")} </div> : null}
										{props.plan.availableDays.saturday ? <div class="tag"> {props.t("saturday")} </div> : null}
										{props.plan.availableDays.sunday ? <div class="tag"> {props.t("sunday")} </div> : null}
									</div>
								</div>

							</div>
							{props.plan.dresscode ?
								<div className="plan-tags-wrap">
									<p className="tags-title ">{props.t("Dress code")}</p>
									<div className="PlanTags"><div class="tag" style={{ display: "inline-block" }} > {props.plan.dresscode} </div></div>
								</div>
								: null}
							{props.plan.beforeStartings && props.plan.beforeStartings.length > 0 ?
								<div className="plan-tags-wrap">
									<p className="tags-title ">{props.t("Before Starting")}</p>
									<div className="PlanTags">
										{
											props.plan.beforeStartings.length > 3 && !moreBeforeStarting ? 
											props.plan.beforeStartings.map((tag, i) => i < 3 ? <div key={"t" + i} class="tag" style={{ display: "inline-block" }} >{props.t(tag)}</div>:""):
											props.plan.beforeStartings.map((tag, i) => <div key={"t" + i} class="tag" style={{ display: "inline-block" }} >{props.t(tag)}</div>)
										}
										<br/>
										{props.plan.beforeStartings.length > 3  ?
											<span onClick={() => setMoreBeforeStarting(!moreBeforeStarting)} className="more">{moreBeforeStarting ? props.t('-Less') : props.t('+More')}</span>
										: null}
										</div>
								</div>
								: null}
						</>}
						{props.t("VisibleAreaPlanTags") !== "VisibleAreaPlanTags" && props.t("VisibleAreaPlanTags") === "True" && props.plan.hashTags ?
							<div className="plan-tags-wrap">
								<p className="tags-title plan-hashtags-title">{props.t("Hashtags suitable to the theme")}</p>
								<div className="PlanTags"><div className="plan-hashtags">{props.plan.hashTags.split(";").map((tag, i) => <span key={"t" + i}>{" " + tag}</span>)}</div></div>
							</div>
							: null}
						{props.isFullAccess && <>
							{props.plan.historyOfRoute ?
								<div className="plan-historyOfRoute">
									<h4> {props.t("Rotanin tarihi ve turistik açidan önemi")}</h4>
									<p>
										{props.plan.historyOfRoute.length > 220 && !moreHistory ? <span dangerouslySetInnerHTML={rawMarkup(props.plan.historyOfRoute.substring(0, 220)  + "...")} /> : <span dangerouslySetInnerHTML={rawMarkup(props.plan.historyOfRoute)} />}
										{props.plan.historyOfRoute.length > 220 ?
											<span onClick={() => setMoreHistory(!moreHistory)} className="more">{moreHistory ? props.t('-Less') : props.t('+More')}</span>
											: null}
									</p>
								</div>
								: null}
						</>}
					</div>
				</>
				: null}




		</div>
	)
};

PlanPreview.propTypes = {};

PlanPreview.defaultProps = {

};

export default translate("translations", { withRef: true })(PlanPreview);
