let en = {
    translations: {
        "City_0": "İstanbul",
        "City_1": "Nevşehir (Kapadokya)",
        "City_2": "Denizli (Pamukkale)",
        "City_3": "Antalya",
        "City_4": "Çanakkale",
        "City_5": "İzmir",
        "City_6": "Muğla",
        "City_7": "Paris",
        "City_8": "Nice",
        "City_9": "Marsilya",
        "City_10": "Roma",
        "City_11": "Floransa",
        "City_12": "Venedik",
        "City_13": "Milano",
        "City_14": "Napoli",
        "City_15": "Amsterdam",
        "City_16": "Rotterdam",
        "City_17": "Berlin",
        "City_18": "Münih",
        "City_19": "Frankfurt",
        "City_20": "Hamburg",
        "City_21": "Barselona",
        "City_22": "Madrid",
        "City_23": "Sevilla",
        "City_24": "Granada",
        "City_25": "Londra",
        "City_26": "Manchester",
        "tr" : "0",
"en" : "1",
"ru" : "2",
"TRY" : "TL",
"TL" : "₺",
"Euro" : "€",
"USD" : "$",
"Dolar" : "$",
"years" : "yıldır",
"Living" : "yaşıyor",
"livetext" : "{{inhabitCount}} {{inhabitCircleText}} {{cityText}} şehrinde {{inhabitText}}",
"Müze" : "Müze",
"Tarih" : "Tarih",
"Gezi" : "Gezi",
"Alışveriş" : "Alışveriş",
"Spor ve Etkinlik" : "Spor ve Etkinlik",
"Eğlence" : "Eğlence",
"Etkinlik" : "Etkinlik",
"Yiyecek ve içecek" : "Yiyecek ve içecek",
"Açık Alan" : "Açık Alan",
"Açık Arttırma" : "Açık Arttırma",
"Ada" : "Ada",
"Bar" : "Bar",
"Bilet Gerekli" : "Bilet Gerekli",
"Bisiklet" : "Bisiklet",
"Cafe" : "Cafe",
"Cam Sanatı" : "Cam Sanatı",
"Cami" : "Cami",
"Deniz Kenarı" : "Deniz Kenarı",
"Deniz Ürünleri" : "Deniz Ürünleri",
"Doğa" : "Doğa",
"Döner" : "Döner",
"Esnaf Lokantası" : "Esnaf Lokantası",
"Ev" : "Ev",
"Fuar / Expo" : "Fuar / Expo",
"Galeri" : "Galeri",
"Gece" : "Gece",
"Gece Hayatı" : "Gece Hayatı",
"Gizli Nokta" : "Gizli Nokta",
"Gurme" : "Gurme",
"Havuz" : "Havuz",
"Hint Mutfağı" : "Hint Mutfağı",
"İltalyan Mutfağı" : "İltalyan Mutfağı",
"Kahvaltı" : "Kahvaltı",
"Kapalı Alan" : "Kapalı Alan",
"Karaoke" : "Karaoke",
"Kebap" : "Kebap",
"Kiralık Araba" : "Kiralık Araba",
"Kokoreç" : "Kokoreç",
"Koşu" : "Koşu",
"Köfte" : "Köfte",
"Köprü" : "Köprü",
"Kütüphane" : "Kütüphane",
"Liman / Marina" : "Liman / Marina",
"Lounge" : "Lounge",
"Manevi / Dini" : "Manevi / Dini",
"Mangal" : "Mangal",
"Manzara" : "Manzara",
"Meksika Mutfağı" : "Meksika Mutfağı",
"Meydan" : "Meydan",
"Müzik" : "Müzik",
"Pahalı" : "Pahalı",
"Park" : "Park",
"Pasaj" : "Pasaj",
"Pide" : "Pide",
"Piknik" : "Piknik",
"Pizza" : "Pizza",
"Plaj" : "Plaj",
"Rakı-Balık" : "Rakı-Balık",
"Restoran" : "Restoran",
"Rus Mutfağı" : "Rus Mutfağı",
"Sağlık & Spa" : "Sağlık & Spa",
"Sahaf" : "Sahaf",
"Sergi" : "Sergi",
"Sokak Yemekleri" : "Sokak Yemekleri",
"Spor" : "Spor",
"Tekne Turu" : "Tekne Turu",
"Türk Mutfağı" : "Türk Mutfağı",
"Ucuz" : "Ucuz",
"Vegan" : "Vegan",
"Workshop" : "Workshop",
"Yerel Tatlar" : "Yerel Tatlar",
"Engelliler Topluluğu" : "Engelliler Topluluğu",
"Vejetaryen" : "Vejetaryen",
"İtalyan Mutfağı" : "İtalyan Mutfağı",
"Hamam" : "Hamam",
"Müzayede" : "Müzayede",
"Kilise" : "Kilise",
"Sinagog" : "Sinagog",
"Sightseeing" : "Gezi",
"Shopping" : "Alışveriş",
"SportAndActivity" : "Spor ve Aktivite",
"Entertainment" : "Eğlence",
"Events" : "Etkinlikler",
"FoodAndDrink" : "Yeme & İçme",
"VisitArtOfGallery" : "Ziyaret - Sanat Galerisi / Sergi",
"VisitMuseum" : "Ziyaret - Müze",
"VisitPlaceOfWorship" : "Ziyaret - İbadethane",
"FoodAndDrinkStreetFlavor" : "Yeme & İçme - Sokak Yemekleri",
"FoodAndDrinkRestourant" : "Yeme & İçme - Restoran",
"FoodAndDrinkBreakFast" : "Yeme & İçme - Kahvaltı",
"FoodAndDrinkCafe" : "Yeme & İçme - Kafe",
"FoodAndDrinkFineDining" : "Yeme & İçme - Fine Dining",
"SportAndActivityYogaAngPlates" : "Spor & Sağlık & Aktivite - Yoga & Pilates",
"SportAndActivitySpa" : "Spor & Sağlık & Aktivite - Spa",
"SportAndActivityRunning" : "Spor & Sağlık & Aktivite - Koşu",
"SportAndActivityFitness" : "Spor & Sağlık & Aktivite - Fitness",
"SportAndActivityTreeking" : "Spor & Sağlık & Aktivite - Doğa yürüyüşleri",
"SportAndActivitySycling" : "Spor & Sağlık & Aktivite - Bisiklet sürüşü",
"SportAndActivityTurkishBath" : "Spor & Sağlık & Aktivite - Hamam",
"TravelBoatTour" : "Gezi - Tekne turu / Boğaz turu",
"TravelWalking" : "Gezi - Şehir içinde yürüyüş",
"TravelParks" : "Gezi - Parklar & Bahçeler",
"TravelSquares" : "Gezi - Meydanlar",
"TravelStreets" : "Gezi - Caddeler ve Sokaklar",
"EventTournament" : "Etkinlik - Turnuva & Müsabaka: Seyirci / Katılımcı",
"EventConcert" : "Etkinlik - Konser",
"EventFair" : "Etkinlik - Fuar (Ticaret, Kitap, Gıda, Teknoloji Fuarları)",
"EventFestival" : "Etkinlik - Festival (Film, Müzik, Kahve, Kukla, Tiyatro vb. festivalleri)",
"EventBiennial" : "Etkinlik - Bienal",
"EntertainmentMeyhane" : "Eğlence - Meyhane",
"EntertainmentNightClub" : "Eğlence - Gece Kulüpleri",
"EntertainmentCasino" : "Eğlence - Gazino",
"EntertainmentBar" : "Eğlence - Bar",
"Live Support" : "Canlı Destek",
"Trip Designer" : "Seyahat Tasarımcısı",
"trip design" : "{{count}} seyahat tasarımı",
"Fully access to this plan only for" : "Bu plana tamamen erişin, yalnızca",
"Get Full Access" : "Satın Al",
"Fully access to this plan for (one time).  %100 satisfaction guaranteed!" : "Bu planı {{discountedPrice}}{{priceCurrency}} karşılığında satın alın.  100% memnuniyet garantili!",
"tripDesigner-join-event" : "Bu planın tasarımcısı {{firstname}}, siz planı uygularken size <span>katılmayacaktır.</span>",
"Fully access to this plan for (one time)" : "Planın tamamına, yalnızca {{price}}{{currency}} ödeyerek sahip olabilirsiniz. (Tek seferlik ödeme)",
"%100 satisfaction guaranteed." : "100% memnuniyet garantili.",
"Browse other plans matching my trip dates" : "Seyahat tarihlerinizle eşleşen diğer planları görün",
"Browse Plans" : "Planları Görüntüle",
"or" : "veya",
"This is a paid content. To view please click on “Get Full Access” button." : "Bu ücretli bir içerik. Görüntülemek için lütfen 'Satın Al' butonuna tıklayın.",
"Following event is brought to you by HeyTripster for demo purposes." : "Aşağıdaki etkinlik size HeyTripster tarafından önizleme amacıyla sunulmuştur.<br/> Tam günlük plana erişmek için 'Satın Al' butonuna tıklayın ve {{city}} şehrini {{designerName}} gibi deneyimleyin.",
"Play Trailer" : "Fragmanı Oynat",
"Show me 1 Sample Event" : "Bana Örnek Bir Etkinlik Göster",
"Plan Summary" : "Plan Özeti",
"View this event for free" : " Bu etkinliği ücretsiz görüntüle",
"Travellers give an average rating" : "Gezginler seyahat tasarımcılarımızdan {{name}} için ortalama olarak 5 yıldız üzerinden {{rating}} verdi.",
"This Plan Includes" : "Bu Planın İçerdikleri",
"Total places" : "Toplam {{total}} yer",
" Main Events" : "{{main}} Ana Etkinlik",
"walking" : "{{walking}} yürüme",
"Çiftler" : "Çiftler için harika",
" Alternative Events" : "{{total}} Alternatif Etkinlik",
" Bonus Events" : "{{total}} Yakın Nokta",
"Between - " : "{{startTime}}-{{endTime}} Arası",
"PlanAvarage" : "Ort. Harcama {{planAvaregePrice}}{{planCurrency}}",
"PlanFree" : "Ek Harcamasız Plan",
"300+ different plans, 100+ trip designers at HeyTripster." : "HeyTripster'da 117+ plan ve 67+ seyahat tasarımcısı var.",
"Arrival Date" : "Geliş Tarihi",
"Return Date" : "Dönüş Tarihi",
"Browse All Plans" : "Tüm Planları Görüntüle",
"Languages" : "Diller",
"LanguagesArabic" : "Arapça",
"LanguagesBengali" : "Bengalce",
"LanguagesChinese" : "Çince",
"LanguagesDutch" : "Felemenkçe",
"LanguagesEnglish" : "İngilizce",
"LanguagesFrench" : "Fransızca",
"LanguagesGerman" : "Almanca",
"LanguagesGreek" : "Yunanca",
"LanguagesHebrew" : "İbranica",
"LanguagesHindi" : "Hintçe",
"LanguagesIndonesian" : "Endonezyaca",
"LanguagesJavanese" : "Cava Dili",
"LanguagesJapanese" : "Japonca",
"LanguagesItalian" : "İtalyanca",
"LanguagesKorean" : "Korece",
"LanguagesPersian" : "Farsça",
"LanguagesPolish" : "Lehçe",
"LanguagesPortuguese" : "Portekizce",
"LanguagesRussian" : "Rusça",
"LanguagesSpanish" : "İspanyolca",
"LanguagesTurkish" : "Türkçe",
"LanguagesUrdu" : "Urduca",
"LanguagesVietnamese" : "Vietnamca",
"Planted Trees" : "Dikilen Ağaç",
"Number of Plans Sold" : "Satılan Plan",
"Themes of ’s trip plans" : "{{fullName}} bu temalarda plan tasarlıyor",
"Travel" : "Gezi",
"Nature" : "Doğa",
"Art" : "Sanat",
"Shopping" : "Alışveriş",
"Sports & Activity" : "Spor & Aktivite",
"Entertainment" : "Eğlence",
"Event" : "Etkinlik",
"Eating & Drinking" : "Yeme & İçme",
"Culture & History" : "Kültür & Tarih",
"Favorite Song" : "Favori Şarkı",
"What’s in every HeyTripster plan?" : "Her HeyTripster planı bunları içerir",
"Get Started" : "Hemen Başla",
"Information to prevent you from roaming aimlessly." : "Amaçsızca dolaşmanızı engelleyecek bilgiler.",
"What to eat, buy, take pictures & more." : "Ne yemeli, ne almalı, ne görülmeli ve daha fazlası.",
"Relevant and reliable information." : "Alakalı ve güvenilir bilgiler.",
"Insider tips like transportation, dress code & more." : "Ulaşım bilgisi, kıyafet kuralları ve daha fazlasını içeren ipuçları.",
"A tree planted on your behalf." : "Adınıza dikilmiş bir ağaç.",
"Things to watch out for like common scams." : "Bilinen dolandırıcılıklar gibi dikkat edilmesi gereken şeyler.",
"Min. 5 main events with hidden spots & alternatives." : "Gizli noktalar ve farklı alternatifler içeren en az 5 ana etkinlik.",
"Themes from candlelit dinner to adrenaline-filled activities" : "Mum ışığında akşam yemeğinden adrenalin dolu aktivitelere kadar temalar.",
"PDF export or interactive app (iOS & Android)" : "PDF olarak veya etkileşimli uygulamada görüntüleme. (iOS ve Android)",
"TOTAL PLACES" : "TOPLAM {{total}} YER",
" MAIN EVENTS" : "{{main}} ANA ETKİNLİK",
"WALKING" : "{{walking}} YÜRÜME",
"GREAT FOR COUPLES" : "ÇİFTLER İÇİN HARİKA",
" ALTERNATIVE EVENTS" : "{{total}} ALTERNATİF ETKİNLİK",
" BONUS EVENTS" : "{{total}} BONUS ETKİNLİK",
"BETWEEN - " : "{{startTime}}-{{endTime}} ARASI",
"PLANAVARAGE" : "{{planAvaregePrice}}{{planCurrency}} Ort. HARCAMA",
"FREE PLAN" : "Ek Harcamasız Plan",
"Speaks" : "Konuştuğu Diller:",
"name's Plan" : "Plan sahibi {{name}}  ",
"Browse other plans" : "Diğer planları görüntüle",
"You can choose a suitable one for yourself and see its details." : "Birine tıkla ve detaylarını gör!",
"LOCAL" : "LOKAL",
"TOURIST" : "GEZGİN",
"CONCIERGE" : "CONCIERGE",
"Browse all plans" : "Seni tanıdık, aşağıda isteklerinize en uygun seyahat planlarını sırasıyla listeledik.",
"When do you plan to arrive" : "{{city}} şehrine ne zaman gelmeyi planlıyorsunuz?",
"We’ll check ’s plan is suitable for your travel window or not." : "Bu planın seyahat tarihlerinizde kullanılabilir olup olmadığına bakacağız.",
"When is your trip" : "Seyahatiniz ne zaman?",
"Show me available trip plans" : "Mevcut seyahat planlarını göster",
"Can I use this plan" : "Bu planı kullanabilir miyim?",
"We will list the trip plans suitable for your dates." : "Seyahat tarihlerinize uygun planları listeleyeceğiz.",
"Which email address should we send your trip plan?" : "Seyahat planınızı hangi e-posta adresine gönderelim?",
"Which email address should we send your confirmation?" : "Onay bilgisini hangi email adresine gönderelim?",
"Show me available trip plans" : "Mevcut seyahat planlarını göster",
"Travellers Reviews" : "Gezginlerin Yorumları",
"Sorted by Relevancy" : "İlgi düzeyine göre",
"Explore other trip plans" : "Diğer seyahat planlarını keşfet",
"s Other Designs" : "{{designerName}} isimli seyahat tasarımcısının diğer planları",
"My Instagram" : "Instagram Hesabım",
"Browse related articles" : "İlgili Makaleleri görüntüleyin",
"Golden Checklist" : "Yapılacaklar Listesi",
"See" : "Görmeden Geçme",
"Taste" : "Tadına Bak",
"Buy" : "Alışveriş Yap",
"Photo-Point" : "Fotoğraf Çek",
"Do" : "Yap",
"Read" : "Oku",
"Listen" : "Dinle",
"Don't" : "Yapma",
"Watch a video related to this event" : "Bu etkinlikle alakalı bir video izleyin",
"My Cities" : "Şehirlerim",
"MyCitiesCity0" : "İstanbul",
"MyCitiesCity1" : "Nevşehir (Kapadokya)",
"MyCitiesCity2" : "Denizli (Pamukkale)",
"MyCitiesCity3" : "Antalya",
"MyCitiesCity4" : "Çanakkale",
"MyCitiesCity5" : "İzmir",
"MyCitiesCity6" : "Muğla",
"MyCitiesCity7" : "Paris",
"MyCitiesCity8" : "Nice",
"MyCitiesCity9" : "Marsilya",
"MyCitiesCity10" : "Roma",
"MyCitiesCity11" : "Floransa",
"MyCitiesCity12" : "Venedik",
"MyCitiesCity13" : "Milano",
"MyCitiesCity14" : "Napoli",
"MyCitiesCity15" : "Amsterdam",
"MyCitiesCity16" : "Rotterdam",
"MyCitiesCity17" : "Berlin",
"MyCitiesCity18" : "Münih",
"MyCitiesCity19" : "Frankfurt",
"MyCitiesCity20" : "Hamburg",
"MyCitiesCity21" : "Barselona",
"MyCitiesCity22" : "Madrid",
"MyCitiesCity23" : "Sevilla",
"MyCitiesCity24" : "Granada",
"MyCitiesCity25" : "Londra",
"MyCitiesCity26" : "Manchester",
"Visited Countries" : "Ziyaret Ettiğim Ülkeler",
"About Event" : "Etkinlik Hakkında",
"Event Type" : "Etkinlik Tipi",
"Suggested Duration" : "Önerilen başlangıç saati & süre",
"Reservation" : "Rezervasyon",
"Not Required" : "Gerekli değil",
"Required" : "Gerekli",
"Avg. Price" : "Ort. harcama",
"Suggested Start Time" : "Önerilen başlangıç saati & süre",
"Location" : "Konum",
"Key Information About this Event" : "Bu etkinlikle alakalı önemli bilgiler",
"The importance of the event in terms of tourism" : "Etkinliğin turistik açıdan önemi",
"What is the story / history of the event?" : "Etkinliğin hikayesi / tarihi nedir?",
"Things to know" : "Bilinmesi gerekenler",
"Alternative Event & Bonus Events" : "Alternatif Etkinlikler & Bonus Etkinlikler",
"When is your trip?" : "Seyahatiniz ne zaman?",
"We will list the trip plans suitable for your dates." : "Tarihlerinize uygun seyahat planlarını listeleyeceğiz.",
"January" : "Ocak",
"February" : "Şubat",
"March" : "Mart",
"April" : "Nisan",
"May" : "Mayıs",
"June" : "Haziran",
"July" : "Temmuz",
"August" : "Ağustos",
"September" : "Eylül",
"October" : "Ekim",
"November" : "Kasım",
"December" : "Aralık",
"Yes, you can use this plan" : "Evet, bu planı kullanabilirsin",
"Your trip designer suggests this plan for the following dates." : "Seyahat tasarımcınız bu planı aşağıdaki günler için tavsiye ediyor.",
"What is your name?" : "Adınız nedir?",
"Name" : "Ad",
"Surname" : "Soyad",
"To Get Full Access" : "Planı almak için",
"Continue to checkout" : "Ödeme adımına ilerle",
"Show me available trip plans" : "Bana mevcut planları göster",
"Can I use this plan" : "Bu planı kullanabilir miyim?",
"We will list the trip plans suitable for your dates." : "Tarihlerinize uygun seyahat planlarını listeleyeceğiz.",
"Which email address should we send your trip plan?" : "Seyahat planınızı hangi e-posta adresine gönderelim?",
"Which email address should we send your confirmation?" : "Onay e-postanızı hangi adrese gönderelim?",
"We’ll check ’s plan is suitable for your travel window or not." : "Bu planın seyahat tarihlerinizde kullanılabilir olup olmadığına bakacağız.",
"Main Event" : "Ana Etkinlik",
"Highlights" : "Öne Çıkan Etkinlik",
"This is a paid content." : "Bu ücretli bir içeriktir.",
"To view please complete payment." : "Görüntülemek için satın alın.",
"Show me available trip plans" : "Mevcut seyahat planlarını göster",
"Show plan on a map" : "Planı haritada görüntüle",
"Live Support" : "Canlı Destek",
"Travellers Reviews" : "Gezgin Yorumları",
"Sorted by Relevancy" : "Alaka Düzeyine Göre Sıralı",
"-Less" : "-Daha az",
"+More" : " + Daha fazla",
"Explore other trip plans" : "Diğer gezi planlarını keşfedin",
"Don't" : "Yapma",
"Listen" : "Dinle",
"Read" : "Oku",
"Do" : "Yap",
"Photo Point" : "Fotoğraf çek",
"Buy" : "Alışveriş yap",
"Taste" : "Tadına Bak",
"See" : "Görmeden Geçme",
"Golden CheckList" : "Yapılacaklar Listesi",
"Click here for more information" : "Daha fazla bilgi için tıklayın",
"Your trip designer is" : "Seyahat tasarımcınız <span className='truncate'>{{designerName}}</span>",
"SAMPLE EVENT" : "ÖNE ÇIKAN <br/> ETKİNLİK",
"About Event" : "Etkinlik Hakkında",
"Location" : "Lokasyon",
"Event Type" : "Etkinlik Tipi",
"Suggested Duration" : "Önerilen başlangıç saati & süre",
" min" : " dk.",
"Reservation" : "Rezervasyon",
"Avg. Price" : "Ort. harcama",
"Suggested Start Time" : "Önerilen başlangıç saati & süre",
"Free Event" : "Ücretsiz Etkinlik",
"Watch a video related to this event." : "Eventle ilgili video linki",
"Open Hours" : "Açık Saatler",
"Key Information About this Event" : "Bu Etkinlik Hakkında Önemli Bilgiler",
"The importance of the event in terms of tourism" : "Etkinliğin turizm açısından önemi",
"What is the story / history of the event?" : "Etkinliğin hikayesi / tarihi nedir?",
"Things to know" : "Bilinecek şeyler",
"Alternative Event & Bonus Events" : "Alternatif Etkinlik ve Bonus Etkinlikler",
"Bonus Event Icon" : "Bonus Etkinlik",
"About your trip designer" : "Seyahat tasarımcınız hakkında",
"Bio" : "Hakkında",
"Visited Countries" : "Ziyaret Edilen Ülkeler",
"Show More" : "Daha Fazla Göster",
"My Cities" : "Şehirlerim",
"PlanTreeInfoText" : "HeyTripster olarak, her seyahat planı satın aldığınızda bir ağaç dikiyoruz. Seyahatten kaynaklanan karbon ayak izinizi azaltmanıza yardımcı olma yolumuzdur. 2022'ye kadar 100.000 ağaç dikmeyi umuyoruz.",
"Frequently asked questions" : "Sıkça Sorulan Sorular",
"What is your name?" : "Adınız nedir?",
"Loading..." : "Yükleniyor...",
"Yes, you can use this plan" : "Evet, bu planı kullanabilirsiniz",
"Please pick one." : "Lütfen birini seçin.",
"Your trip designer suggested the best possible dates for this plan." : "Seyahat tasarımcısı bu plan için mümkün olan en iyi tarihleri önerdi.",
"To Get Full Access" : "Planı Almak İçin",
"Continue to checkout" : "Ödeme işlemine ilerle",
"Yes, take me to available plans." : "Evet, bana mevcut planları göster.",
"PlanNotApplicableText" : "{{designerName}} isimli seyahat tasarımcısının planı, <b>{{startDate}} - {{endDate}}</b> tarihleri arasında kullanmak için <b>uygun değil</b>. Ancak, kullanabileceğiniz <span>{{otherPlansLength}} tane harika plan bulduk.</span>",
"Would you like to review these plans?" : "Bu planları incelemek <br/>ister misiniz?",
"Pay with" : "Ödeme yap",
"Name on Card" : "Kart üzerindeki isim",
"Card Number" : "Kart numarası",
"TermsText" : "Bu ödemeyi tamamlayarak, <a href='https://heytripster.com/mesafeli-satis-sozlesmesi/?utm={{name}}&email={{email}}' target='_blank'>Mesafeli Satış Sözleşmesi</a>, <a href='https://heytripster.com/mesafeli-satis-sozlesmesi/?utm={{name}}&email={{email}}' target='_blank'>Ön Bilgilendirme Koşulları</a> ve <a href='https://heytripster.com/mesafeli-satis-sozlesmesi/?utm={{name}}&email={{email}}' target='_blank'>İptal ve Para İadesi Sözleşmesi'ni</a> kabul etmiş olursunuz.",
"Review and Pay" : "İncele ve ödemeyi tamamla",
"After payment, you can leave the e-mail addresses of the people you want your trip plan to be shared with, or you can share it later if you wish." : "Ödeme yapıldıktan sonra seyahat planınızın paylaşılmasını istediğiniz kişilerin e-posta adreslerini bırakabilir veya dilerseniz daha sonra paylaşabilirsiniz.",
"Designed by" : "Seyahat tasarımcısı {{tridDesigner}}",
"See More" : "Daha fazla gör",
"By purchasing single" : "Bu planı satın alarak, {{city}} şehrine {{count}} fidan dikilmesini sağlıyorsunuz.",
"By purchasing multiple" : "Bu planları satın alarak, {{city}} şehrine {{count}} fidan dikilmesini sağlıyorsunuz.",
"Enter a coupon code" : "Kupon kodu girin",
"Get discount!" : "{{discount}} indirim alın!",
"Apply" : "Uygula",
"Discount applied" : "İndirim uygulandı",
"discount applied" : "indirim uygulandı",
"PlanPickedForDaySingle" : "{{planDays}} gün için {{basketSource}} plan seçildi.",
"PlanPickedForDayMultiple" : "{{planDays}} gün için {{basketSource}} plan seçildi.",
"x trip plan" : "{{price}} {{currency}} x {{count}} seyahat planı",
"CurencyPrice" : "{{price}} {{currency}}",
"Service fee" : "Hizmet ücreti",
"TotalCurency" : "Toplam ({{currency}})",
"Confirm and Pay" : "Onayla ve öde",
"100% satisfaction guaranteed." : "100% Memnnuniyet Garantisi.",
"If you're not satisfied, get a full refund." : "Eğer memnun değilseniz, tam iade alın.",
"More Details" : "Daha fazla detay",
"Total" : "Toplam",
"Payment Success" : "Ödeme Başarılı",
"You just unlocked your trip plan!" : "Ödemeniz için teşekkürler! Satın aldığınız seyahat planlarını size özel bir bağlantı ile email adresinize ileteceğiz. Bu işlem genellikle 2 saat içerisinde tamamlanır.",
"You will receive your trip plan in the next 24 hours." : "Elimizde olmayan nedenlerden dolayı bu süre uzar ise endişelenmeyin, en fazla 24 saat içerisinde seyahat planlarınıza erişebileceksiniz.",
"Did you know we plant 1 tree for every plan you get!" : "Aldığınız her plan için 1 ağaç diktiğimizi biliyor muydunuz?",
"10 Million trees on the way!" : "10 milyon ağaç yolda!",
"Who else is travelling with you?" : "Sizinle başka kim seyahat ediyor?",
"Share your plans with them!" : "Planlarınızı onlarla paylaşın!",
"Thank You!" : "Teşekkürler!",
"We look forward to seeing you and your friends." : "Sizi ve arkadaşlarınızı görmek için sabırsızlanıyoruz.",
"+ Add" : "+ Ekle",
"Share my Plans" : "Seyahat planımı paylaş",
"Andorra" : "Andorra",
"United Arab Emirates" : "Birleşik Arap Emirlikleri",
"Afghanistan" : "Afganistan",
"Antigua and Barbuda" : "Antigua ve Barbuda",
"Anguilla" : "Anguilla",
"Albania" : "Arnavutluk",
"Armenia" : "Ermenistan",
"Netherlands Antilles" : "Hollanda Antilleri",
"Angola" : "Angola",
"Antarctica" : "Antarktika",
"Argentina" : "Arjantin",
"American Samoa" : "Amerikan Samoası",
"Austria" : "Avusturya",
"Australia" : "Avustralya",
"Aruba" : "Aruba",
"Aland Islands" : "Aland adaları",
"Azerbaijan" : "Azerbaycan",
"Bosnia and Herzegovina" : "Bosna Hersek",
"Barbados" : "Barbados",
"Bangladesh" : "Bangladeş",
"Belgium" : "Belçika",
"Burkina Faso" : "Burkina Faso",
"Bulgaria" : "Bulgaristan",
"Bahrain" : "Bahreyn",
"Burundi" : "Burundi",
"Benin" : "Benin",
"Saint Barthélemy" : "Saint Barthélemy",
"Bermuda" : "Bermuda",
"Brunei Darussalam" : "Brunei darüssalam",
"Bolivia Plurinational State of" : "Bolivya'nın plurinational durumu",
"Caribbean Netherlands" : "Karayipler Hollanda",
"Brazil" : "Brezilya",
"Bahamas" : "Bahamalar",
"Bhutan" : "Butan",
"Bouvet Island" : "Bouvet adası",
"Botswana" : "Botsvana",
"Belarus" : "Belarus",
"Belize" : "Belize",
"Canada" : "Kanada",
"Cocos (Keeling) Islands" : "Cocos (Keeling) Adaları",
"Congo the Democratic Republic of the" : "Kongo Demokratik Cumhuriyeti",
"Central African Republic" : "Orta Afrika Cumhuriyeti",
"Congo" : "Kongo",
"Switzerland" : "İsviçre",
"Cote d'Ivoire" : "Cote d'ivoire",
"Cook Islands" : "Cook Adaları",
"Chile" : "Şili",
"Cameroon" : "Kamerun",
"China" : "Çin",
"Colombia" : "Kolombiya",
"Costa Rica" : "Kosta Rika",
"Cuba" : "Küba",
"Cape Verde" : "Cape verde",
"Curacao" : "Curacao",
"Christmas Island" : "Noel Adası",
"Cyprus" : "Kıbrıs",
"Czech Republic" : "Çek Cumhuriyeti",
"Germany" : "Almanya",
"Djibouti" : "Djibuti",
"Denmark" : "Danimarka",
"Dominica" : "Dominika",
"Dominican Republic" : "Dominik Cumhuriyeti",
"Algeria" : "Cezayir",
"Ecuador" : "Ekvador",
"Estonia" : "Estonya",
"Egypt" : "Mısır",
"Western Sahara" : "Batı Sahra",
"Eritrea" : "Eritre",
"Spain" : "ispanya",
"Ethiopia" : "Etiyopya",
"Europe" : "Avrupa",
"Finland" : "Finlandiya",
"Fiji" : "Fiji",
"Falkland Islands (Malvinas)" : "Falkland Adaları (Malvinas)",
"Micronesia Federated States of" : "Mikronezya Federe Devletleri",
"Faroe Islands" : "Faroe Adaları",
"France" : "Fransa",
"Gabon" : "Gabon",
"England" : "İngiltere",
"Northern Ireland" : "Kuzey Irlanda",
"Scotland" : "İskoçya",
"Wales" : "Galler",
"United Kingdom" : "Birleşik Krallık",
"Grenada" : "Grenada",
"Georgia" : "Gürcistan",
"French Guiana" : "Fransız Guyanası",
"Guernsey" : "Guernsey Adası",
"Ghana" : "Gana",
"Gibraltar" : "Cebelitarık",
"Greenland" : "Grönland",
"Gambia" : "Gambiya",
"Guinea" : "Gine",
"Guadeloupe" : "Guadeloupe",
"Equatorial Guinea" : "Ekvator Ginesi",
"Greece" : "Yunanistan",
"South Georgia and the South Sandwich Islands" : "Güney Georgia ve Güneyli Sandwich Adaları",
"Guatemala" : "Guatemala",
"Guam" : "Guam",
"Guinea-Bissau" : "Gine-Bissau",
"Guyana" : "Guyana",
"Hong Kong" : "Hong Kong",
"Heard Island and McDonald Islands" : "Heard Adası ve McDonald Adaları",
"Honduras" : "Honduras",
"Croatia" : "Hırvatistan",
"Haiti" : "Haiti",
"Hungary" : "Macaristan",
"Indonesia" : "Endonezya",
"Ireland" : "İrlanda",
"Israel" : "İsrail",
"Isle of Man" : "Erkek adam",
"India" : "Hindistan",
"British Indian Ocean Territory" : "İngiliz Hint Okyanusu Bölgesi",
"Iraq" : "Irak",
"Iran Islamic Republic of" : "İran İslam Cumhuriyeti",
"Iceland" : "İzlanda",
"Italy" : "İtalya",
"Jersey" : "Jersey",
"Jamaica" : "Jamaika",
"Jordan" : "Ürdün",
"Japan" : "Japonya",
"Kenya" : "Kenya",
"Kyrgyzstan" : "Kırgızistan",
"Cambodia" : "Kamboçya",
"Kiribati" : "Kiribati",
"Comoros" : "Komoros",
"Saint Kitts and Nevis" : "Saint Kitts ve Nevis",
"Korea Democratic People's Republic of" : "Kore Demokratik Halk Cumhuriyeti",
"Korea Republic of" : "Kore Cumhuriyeti",
"Kuwait" : "Kuveyt",
"Cayman Islands" : "Cayman Adaları",
"Kazakhstan" : "Kazakistan",
"Lao People's Democratic Republic" : "Lao Halk Demokratik Cumhuriyeti",
"Lebanon" : "Lübnan",
"Saint Lucia" : "Saint Lucia",
"Liechtenstein" : "Liechtenstein",
"Sri Lanka" : "Sri Lanka",
"Liberia" : "Liberya",
"Lesotho" : "Lesoto",
"Lithuania" : "Litvanya",
"Luxembourg" : "Lüksemburg",
"Latvia" : "Letonya",
"Libya" : "Libya",
"Morocco" : "Fas",
"Monaco" : "Monako",
"Moldova Republic of" : "Moldova Cumhuriyeti",
"Montenegro" : "Karadağ",
"Saint Martin" : "Aziz Martin",
"Madagascar" : "Madagaskar",
"Marshall Islands" : "Marşal Adaları",
"Macedonia the former Yugoslav Republic of" : "Kuzey Makedonya",
"Mali" : "Mali",
"Myanmar" : "Myanmar",
"Mongolia" : "Moğolistan",
"Macao" : "Macao",
"Northern Mariana Islands" : "Kuzey Mariana Adaları",
"Martinique" : "Martinique",
"Mauritania" : "Moritanya",
"Montserrat" : "Montserrat",
"Malta" : "Malta",
"Mauritius" : "Mauritius",
"Maldives" : "Maldivler",
"Malawi" : "Malawi",
"Mexico" : "Meksika",
"Malaysia" : "Malezya",
"Mozambique" : "Mozambik",
"Namibia" : "Namibya",
"New Caledonia" : "Yeni Kaledonya",
"Niger" : "Nijer",
"Norfolk Island" : "Norfolk Adası",
"Nigeria" : "Nijerya",
"Nicaragua" : "Nikaragua",
"Netherlands" : "Hollanda",
"Norway" : "Norveç",
"Nepal" : "Nepal",
"Nauru" : "Nauru",
"Niue" : "Niue",
"New Zealand" : "Yeni Zelanda",
"Oman" : "Umman",
"Panama" : "Panama",
"Peru" : "Peru",
"French Polynesia" : "Fransız Polinezyası",
"Papua New Guinea" : "Papua Yeni Gine",
"Philippines" : "Filipinler",
"Pakistan" : "Pakistan",
"Poland" : "Polonya",
"Saint Pierre and Miquelon" : "Saint Pierre ve Miquelon",
"Pitcairn" : "Pitcairn",
"Puerto Rico" : "Porto Riko",
"Palestine" : "Filistin",
"Portugal" : "Portekiz",
"Palau" : "Palau",
"Paraguay" : "Paraguay",
"Qatar" : "Qatar",
"Réunion" : "Réunion",
"Romania" : "Romanya",
"Serbia" : "Sırbistan",
"Russian Federation" : "Rusya Federasyonu",
"Rwanda" : "Ruanda",
"Saudi Arabia" : "Suudi Arabistan",
"Solomon Islands" : "Solomon Adaları",
"Seychelles" : "Seyşeller",
"Sudan" : "Sudan",
"Sweden" : "İsveç",
"Singapore" : "Singapur",
"Saint Helena Ascension and Tristan da Cunha" : "Saint Helena, Ascension ve Tristan da Cunha",
"Slovenia" : "Slovenya",
"Svalbard and Jan Mayen Islands" : "Svalbard ve Jan Mayen Adaları",
"Slovakia" : "Slovakya",
"Sierra Leone" : "Sierra Leone",
"San Marino" : "San Marino",
"Senegal" : "Senegal",
"Somalia" : "Somali",
"Suriname" : "Surin",
"South Sudan" : "Güney Sudan",
"Sao Tome and Principe" : "Sao Tome ve Principe",
"El Salvador" : "El salvador",
"Sint Maarten (Dutch part)" : "St Maarten (Dutch Part)",
"Syrian Arab Republic" : "Suriye Arap Cumhuriyeti",
"Swaziland" : "Svaziland",
"Turks and Caicos Islands" : "Turks ve Caicos Adaları",
"Chad" : "Chad",
"French Southern Territories" : "Fransız Güney Bölgesi",
"Togo" : "Gitmek",
"Thailand" : "Tayland",
"Tajikistan" : "Tacikistan",
"Tokelau" : "Tokelau",
"Timor-Leste" : "Timor-Leste",
"Turkmenistan" : "Türkmenistan",
"Tunisia" : "Tunus",
"Tonga" : "Tonga",
"Turkey" : "Türkiye",
"Trinidad and Tobago" : "Trinidad ve Tobago",
"Tuvalu" : "Tuval",
"Taiwan" : "Tayvan",
"Tanzania United Republic of" : "Tanzanya Birleşik Cumhuriyeti",
"Ukraine" : "Ukrayna",
"Uganda" : "Uganda",
"US Minor Outlying Islands" : "ABD küçük dışındaki adalar",
"United States" : "Amerika Birleşik Devletleri",
"Uruguay" : "Uruguay",
"Uzbekistan" : "Özbekistan",
"Holy See (Vatican City State)" : "Holy See (Vatikan Şehir Devleti)",
"Saint Vincent and the Grenadines" : "Saint Vincent ve Grenadinler",
"Venezuela Bolivarian Republic of" : "Venezuela Bolivarcı Cumhuriyeti",
"Virgin Islands British" : "Virgin Adaları İngiliz",
"Virgin Islands U.S." : "Virgin Adaları ABD",
"Viet Nam" : "Viet nam",
"Vanuatu" : "Vanuatu",
"Wallis and Futuna Islands" : "Wallis ve Futuna Adaları",
"Kosovo" : "Kosova",
"Samoa" : "Samoa",
"Yemen" : "Yemen",
"Mayotte" : "Mayo",
"South Africa" : "Güney Afrika",
"Zambia" : "Zambiya",
"Zimbabwe" : "Zimbabwe",
"DiscountPercent" : "%{{couponDiscountPercent}} indirim uygulandı",
"features headline" : "Her HeyTripster planı bunları içerir",
"features exclamationPoint" : "Farklı meslek gruplarından farklı perspektifte yerel güzergahlar",
"features placeholder" : "En az 5 farklı ana etkinlik, 1 alternatif etkinlik ve 5 bonus nokta",
"features appointment" : "En az 8 saat süren sabah planları ve en az 6 saat süren akşam planları",
"features picture" : "Farklı gezgin gruplarına göre hazırlanmış planlama",
"features tree" : "Farklı ilgilere hitap eden seçkin etkinlikler",
"features flags" : "Aylık düzenli güncellenen rota bilgisi",
"features pin" : "Seyahati kolaylaştıran kültürel ipuçları",
"features clock" : "Tüm güne yayılan detaylı yer ve zaman bilgisi",
"features homeMarker" : "Lokal yaşam, deneyim ve hafızaya dair anlatılar",
"features clipboard" : "Etkinliğe dair kültürel deneyim tavsiye listesi",
"features Button" : "Mevcut seyahat planlarını göster",
"data.themeOfTheDay" : "Günün teması burada görünür",
"data.shortDescription" : "Günün kısa açıklaması burada görünür.",
"data.difficulty" : "Örnek",
"data.effort" : "Örnek",
"data.walking" : "Örnek",
"data.planAvaregePrice" : "Örnek",
"data.planCurrency" : "₺",
"data.themeImage" : "https://api.heytripster.com/resources/images/122/d131f824-dbd3-4b8b-b76f-ec8949fc9e9d.jpg",
"data.themeImage" : "https://api.heytripster.com/resources/images/122/d131f824-dbd3-4b8b-b76f-ec8949fc9e9d.jpg",
"data.themeThumbnail" : "https://api.heytripster.com/resources/images/122/a7d9d47b-daaa-4679-a467-4c7107b30e8f.jpg",
"data.themeThumbnails" : "https://api.heytripster.com/resources/images/122/a7d9d47b-daaa-4679-a467-4c7107b30e8f.jpg",
"q.nameOfTheEvent" : "Etkinliğin adı burada görünür",
"q.reason" : "Rotanın tarihi ve turistik açıdan önemi burada görünür.",
"q.importance" : "Etkinliğin turistik açıdan önemi burada görünür.",
"q.historyOfTheEvent" : "Etkinliğin hikayesi / tarihi burada görünür.",
"q.availability" : "Uygun günler burada görünür.",
"q.shortDescription" : "Etkinliğin kısa açıklaması burada görünür.",
"q.images" : "https://api.heytripster.com/resources/images/122/d131f824-dbd3-4b8b-b76f-ec8949fc9e9d.jpg",
"q.locationName" : "Lokasyon adı burada görünür.",
"q.openHours" : "Açık saatler burada görünür.",
"q.tipGuide" : "Bahşiş kültürü burada görünür.",
"q.webSite" : "Website burada görünür.",
"q.instagram" : "Instagram adresi burada görünür.",
"q.hygieneRules" : "Hijyen kuralları burada görünür.",
"q.doSee" : "Yapılması gerekenler listesindeki 'Görmeden geçme' karşısına yazdığınız metin burada görünür.",
"q.doTaste" : "Yapılması gerekenler listesindeki 'Tat' karşısına yazdığınız metin burada görünür.",
"q.doBuy" : "Yapılması gerekenler listesindeki 'Alışveriş yap' karşısına yazdığınız metin burada görünür.",
"q.doPhotographing" : "Yapılması gerekenler listesindeki 'Fotoğraf çek' karşısına yazdığınız metin burada görünür.",
"q.doDo" : "Yapılması gerekenler listesindeki 'Yap' karşısına yazdığınız metin burada görünür.",
"q.doRead" : "Yapılması gerekenler listesindeki 'Oku' karşısına yazdığınız metin burada görünür.",
"q.tags" : "Etiketler burada görünür.",
"q.doListen" : "Yapılması gerekenler listesindeki 'Dinle' karşısına yazdığınız metin burada görünür.",
"q.doWarning" : "Yapılması gerekenler listesindeki 'Yapma' karşısına yazdığınız metin burada görünür.",
"q.doSeeLink" : "Yapılması gerekenler listesindeki 'Görmeden geçme' için vereceğiniz bağlantı burada olacak.",
"q.doTasteLink" : "Yapılması gerekenler listesindeki 'Tat' için vereceğiniz bağlantı burada olacak.",
"q.doBuyLink" : "Yapılması gerekenler listesindeki 'Alışveriş yap' için vereceğiniz bağlantı burada olacak.",
"q.doPhotographingLink" : "Yapılması gerekenler listesindeki 'Fotoğraf çek' için vereceğiniz bağlantı burada olacak.",
"q.doDoLink" : "Yapılması gerekenler listesindeki 'Yap' için vereceğiniz bağlantı burada olacak.",
"q.doReadLink" : "Yapılması gerekenler listesindeki 'Oku' için vereceğiniz bağlantı burada olacak.",
"q.doListenLink" : "Yapılması gerekenler listesindeki 'Dinle' için vereceğiniz bağlantı burada olacak.",
"q.doWarningLink" : "Yapılması gerekenler listesindeki 'Yapma' için vereceğiniz bağlantı burada olacak.",
"q.media" : "https://api.heytripster.com/resources/images/122/d131f824-dbd3-4b8b-b76f-ec8949fc9e9d.jpg",
"q.media" : "https://api.heytripster.com/resources/images/122/d131f824-dbd3-4b8b-b76f-ec8949fc9e9d.jpg",
"q.thingsToKnow" : " 'Bilinmesi gerekenler' için yazacaklarınız burada görünür.",
"s.nameOfTheEvent" : "Örnek bonus etkinlik başlığı",
"s.shortDescription" : "Örnek bonus etkinlik açıklaması.",
"s.locationName" : "Örnek bonus etkinlik lokasyon adı",
"s.map" : "Örnek bonus etkinlik harita linki",
"s.tags" : "Örnek bonus etkinlik etiketler",
"s.webSite" : "Örnek bonus etkinlik website",
"This is a free sample. You’ll see for ALL events like this" : "Bu ücretsiz bir örnek etkinliktir. 'SATIN AL' butonunu kullanarak BÜTÜN etkinlikleri bu şekilde görebilirsiniz. Satın alınana kadar {{eventCount}} adet etkinlik kilitlidir.",
"Min Max People" : "En az / En fazla kişi sayısı",
"Start / End" : "Başlangıç / Bitiş",
"Available Days" : "Uygun günler",
"Dress code" : "Dress code",
"Before Starting" : "Başlamadan önce",
"Hashtags suitable to the theme" : "Tema için girilen hashtagler",
"Rotanın tarihi ve turistik açıdan önemi" : "Rotanın tarihi ve turistik açıdan önemi",
"View this event detail" : "Bu etkinliğin detaylarına bak",
"CopyRight Links" : "Görsel kaynakları",
"Image sources" : "Görsel kaynakları",
"PlanNameTitle" : "Plan İsmi",
"HygieneRulesTitle" : "Hijyen kuralları",
"TransportationTitle" : "Ulaşım",
"Istanbul" : "Istanbul",
"Nevsehir" : "Nevsehir",
"Denizli" : "Denizli",
"Antalya" : "Antalya",
"Canakkale" : "Canakkale",
"Izmir" : "Izmir",
"Mugla" : "Mugla",
"Paris" : "Paris",
"Nice" : "Nice",
"Marsilya" : "Marsilya",
"Roma" : "Roma",
"Floransa" : "Floransa",
"Venedik" : "Venedik",
"Milano" : "Milano",
"Napoli" : "Napoli",
"Amsterdam" : "Amsterdam",
"Rotterdam" : "Rotterdam",
"Berlin" : "Berlin",
"Munih" : "Munih",
"Frankfurt" : "Frankfurt",
"Hamburg" : "Hamburg",
"Barselona" : "Barselona",
"Madrid" : "Madrid",
"Sevilla" : "Sevilla",
"Granada" : "Granada",
"Londra" : "Londra",
"Manchester" : "Manchester",
"Bursa" : "Bursa",
"monday" : "Pazartesi",
"tuesday" : "Salı",
"thursday" : "Perşembe",
"saturday" : "Cumartesi",
"Tip Guide" : "Bahşiş Kültürü",
"Lütfen yeniden 'Preview' butonuna basın" : "Lütfen yeniden 'Preview' butonuna basın",
"bonus_event_default_image" : "https://api.heytripster.com/resources/images/376/0576dadc-6315-4f91-bbac-0808ceaceab2.jpg",
"visitedtext" : "{{cityText}} şehrini {{inhabitCount}} {{inhabitCircleText}} {{inhabitText}}.",
"Another" : "Bir başka",
"Friend" : "Arkadaş",
"Get Full Access_10" : "Satın Al",
"Get Full Access_1" : "Bu plan güncel değil",
"Get Full Access_3" : "Bu plan henüz satın alınamıyor",
"Get Full Access_4" : "Bu plan henüz satın alınamıyor",
"Get Full Access_5" : "Bu plan henüz satın alınamıyor",
"Price" : "Fiyat",
"Go to this event" : "Bu etkinliğe git",
"Bonus Location" : "Yakın Nokta - #{{count}}",
"Show Details" : "Detayları Göster",
"Alternative Event" : "Alternatif Etkinlik",
"PEOPLE" : "{{min}} - {{max}} Kişi",
"A day you will walk is awaiting you. You'd better wear sneakers." : "Çok yürüyeceğin bir gün seni bekliyor spor ayakkabını giysen iyi olur.",
"The day will be long. If you take a snack with you?" : "Gün uzun olacak yanına atıştırmalık bir şeyler almak isteyebilirsin.",
"Would you take a shawl with you for a visit to the mosque? (For women)" : "Cami ziyareti için yanına bir şal almalısın. (Kadınlar için)",
"Don't forget to make a reservation in advance for the restaurant you plan to visit." : "Seçenekler arasından gitmeyi planladığın restoran için önceden rezervasyon yaptırmayı unutma.",
"Don't forget to take coins with you." : "Yanına bozuk para almayı unutma.",
"In some places on the travel plan credit card does not pass." : "Seyahat planındaki bazı yerlerde kredi kartı geçmiyor.",
"Since there are museums on the route it will be more economical for you to buy tickets for a pass or multiple pass." : "Rotanda  müzeler olduğu için müzekart ya da çoklu geçiş için bilet alman senin için daha ekonomik olacaktır.",
"* Come together with local producers and learn about the products from firsthand." : "Yerel üreticilerle bir araya gelin ve ürünleri hakkında bilgi edinin",
"* Your friends back at home will envy your photographs taken in great points." : "Arkadaşlarınız çektiğiniz fotoğraflara imrenerek bakacaklar.",
"* Explore the rural area and get lost in mother nature" : "Kırsal kesimi keşfedin ve doğada eşsiz güzelliğinde kaybolun.",
"* See various art pieces closely" : "Birbirinden farklı sanat eserlerini yakından görün.",
"* A great plan for couples live unforgettable moments together" : "Çiftler için harika bir plan birlikte unutulmaz bir gün geçirin",
"* Once in a lifetime experience - you should definitely tell your friends." : "Hayatta bir kez yaşanabilecek bir deneyim bunu kesinlikle arkadaşlarınla paylaşmalısın.",
"* A great day for children they will love every minute of it" : "Çocuklar için harika bir gün her dakikasından keyif alacaklar.",
"* Take a stroll through the streets and learn better of the city" : "Şehrin caddelerinde dolaşın ve şehri daha yakından tanıyın.",
"* This plan is suitable for every individual in your family spread the love!" : "Ailenin her bireyi için uygun bir plan. Ne kadar kalabalık o kadar keyifli!",
"* While riding your bicycle in the area experience the atmosphere." : "Bisikletinle geçtiğin yolların atmosferini deneyimle.",
"* A venue recommendation for a tasty dinner at the end" : "Günün sonunda lezzetli bir akşam yemeği yiyebileceğin harika bir mekan önerisi",
"* Escape the noise and traffic of the city for a day" : "Şehrin gürültüsünden ve trafiğinden bir günlük kaçış.",
"* Includes a rich breakfast place to motivate you for the day" : "Seni güne motive edecek zengin bir kahvaltı menüsü önerisi içeriyor",
"* A restaurant with a wide dinner menu to keep you well-fed and happy" : "Tıka basa doyacağın geniş menülü bir restoran önerisiyle",
"* Lunch menu to boost your energy" : "Enerji yükseltecek bir öğle yemeği menüsü",
"* Do not forget to bring your towel and swimsuit. You will enjoy the beach." : "Mayo ve havlunu yanına almayı unutma. Sahilin tadını çıkaracaksın.",
"* It will be hot! You will need your sunglasses and sunscreen." : "Sıcak bir gün olacak! Güneş gözlüğü ve güneş kremini yanına alsan iyi edersin.",
"* Not everyone is your friend! There might be some uncanny people you will encounter on your route." : "Yoluna çıkan herkese güvenme! Seyahatin sırasında kötü niyetli insanlarla karşılaşabilirsin.",
"* Make sure your phone's battery is full. It can be hard to find a place to help you." : "Telefonunun şarjının dolu olduğundan emin ol. Tekrar şarj edecek bir yer bulamayabilirsin.",
"* This plan is designed for driving route. Do not forget to rent one if you do not have your own." : "Bu plan arabayla seyahat için tasarlanmıştır. Eğer kendine ait bir aracın yoksa başlamadan önce kirala.",
"* This is a bicycle route. Do not forget to rent a bicycle if you do not have your own." : "Bu plan bir bisiklet rotası içeriyor. Eğer kendi bisikletin yanında değilse seyahate çıkmadan kiralamayı unutma.",
"* Fill your backpack with snacks and beverages. You will need lots of energy throughout the day." : "Çantanı yiyecek ve içeceklerle doldur. Gün boyu çok fazla enerji harcayacaksın.",
"Media Link related to the Event" : "Etkinlikle İlgili Medya Linki",
"Event name" : "Etkinlik adı",
"Hediyelik Eşya" : "Hediyelik Eşya",
"Yürüyüş" : "Yürüyüş",
"Şarap" : "Şarap",
"Tadım" : "Tadım",
"Çarşı / Pazar" : "Çarşı / Pazar",
"Tarihi Yapı" : "Tarihi Yapı",
"Saray" : "Saray",
"Vejetaryen" : "Vejetaryen",
"Kule" : "Kule",
"Antik Kent" : "Antik Kent",
"Kermes" : "Kermes",
"Yüzme" : "Yüzme",
"404 NotFound" : "Aradığınız plan bulunamadı",
"Plan NotFound" : "Aradığınız sayfa bulunamadı",
    }
};

export default en;