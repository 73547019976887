import React from 'react';
import PropTypes from 'prop-types';
import './Review.scss';
import { translate } from 'react-i18next';
import {firstName,lastName,lastNameFirstChar} from './../../../../assets/Helpers.js'

class Review extends React.Component{ 

state={
  showMore:false
}
constructor(props) {
  super(props);
}
    toggleMore = () => {
        this.setState({ showMore: !this.state.showMore});
    }

  render(){
    return(
    <div className="Review">
  	<div className="review-wrap">
	    <p className="quote">"{this.props.review.length > 120 && !this.state.showMore ? this.props.review.substring(0,120) + "..." : this.props.review}"
	    {this.props.review.length > 120 ? 
        
        <span onClick={()=>this.toggleMore()} className="more">{this.state.showMore ? this.props.t('-Less'): this.props.t('+More')}</span>


        : null}
	    </p>
	    <p className="author">{firstName(this.props.fullName)} {lastNameFirstChar(this.props.fullName)}</p>
    </div>
  </div>
)};
}
Review.propTypes = {};

Review.defaultProps = {};


export default translate("translations")(Review);