import React, { Component } from "react";
import './App.scss';
import { Provider } from "react-redux";
import {BrowserRouter} from 'react-router-dom';
import { translate, Trans } from "react-i18next";
import i18n from "./i18n";
import { Route, Link } from 'react-router-dom';
import { PersistGate } from "redux-persist/integration/react";
import PlanDetail from './components/PlanDetail/PlanDetail';
import PlanReviews from './components/PlanReviews/PlanReviews';
import PlanExplore from './components/PlanExplore/PlanExplore';
import EveryPlan from './components/EveryPlan/EveryPlan';
import EventDetail from './components/EventDetail/EventDetail';
import DesignerDetail from './components/DesignerDetail/DesignerDetail';
import DesignerImageFeed from './components/DesignerImageFeed/DesignerImageFeed';
import PlantTreeInfo from './components/PlantTreeInfo/PlantTreeInfo';
import Faq from './components/Faq/Faq';
import RelatedArticles from './components/RelatedArticles/RelatedArticles';
import Checkout from './components/Checkout/Checkout';
import BrowsePlans from './components/BrowsePlans/BrowsePlans';
import FooterLight from './components/FooterLight/FooterLight';
import FooterDark from './components/FooterDark/FooterDark';
import HeaderDark from './components/HeaderDark/HeaderDark';
import BreadCrumb from './components/BreadCrumb/BreadCrumb';
import DateSelectPopup from './components/DateSelectPopup/DateSelectPopup';
import {Routes} from './pages/Routes'


import Plan from './pages/Plan/Plan';
import Payment from './pages/Payment/Payment';
import BrowseOtherPlans from './pages/BrowseOtherPlans/BrowseOtherPlans';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import PaymentSuccess from './components/PaymentSuccess/PaymentSuccess';

class App extends Component {
  render() {
    const { t, i18n } = this.props;

    const changeLanguage = lng => {
      i18n.changeLanguage(lng);
    };

    return (
        <BrowserRouter >
          <Routes  />
        </BrowserRouter>
    );
  }
}

export default translate("translations")(App);
