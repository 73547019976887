import React from 'react';
import PropTypes from 'prop-types';
import './EventLocation.scss';
//import GoogleMapReact from 'google-map-react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { translate } from 'react-i18next';
import ReactMapGL, { Source, Layer,Marker } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import marker from './../../../../assets/icons/map-marker.png';

const mapOptions  = {
    disableDefaultUI: true,
    fullScreenControl: false,
    draggable:false
}

const EventLocation = (props) => {

const [expanded, setExpanded] = React.useState(false);

const handleChange = (panel) => (event, isExpanded) => {
  setExpanded(isExpanded ? panel : false);
};

return(

  <div className="EventLocation">
  {(props.adress && props.adress.split(",") > 2 ) || (props.lat && props.lat) ?
  <div>
            <h3 className="sub-title">{props.t("Location")}</h3>
            <div className="location-container" >
            <div className="location-info" onClick={props.mapOpen}>
            {props.lat && props.lng ?
            	<div className="map-wrap">
                    <div className="map-placement">

                        <ReactMapGL
                        width="100%"
                        height="100%"
                        mapStyle="mapbox://styles/mapbox/light-v10"
                        zoom={13}
                        latitude={parseFloat(props.lat)}
                        longitude={parseFloat(props.lng)}
                        mapboxApiAccessToken={'pk.eyJ1IjoiaGV5dHJpcHN0ZXIiLCJhIjoiY2toaTByNmowMTBsYjJ5bndidzA0ZnZiaCJ9.4FU_uRLuYB8ikupTjuatag'}
                      >


                        <Marker latitude={parseFloat(props.lat)} longitude={parseFloat(props.lng)} offsetLeft={-15} offsetTop={-30}>
                            <div className="marker-wrap">
                          {props.adress && props.adress.split(",").length > 2 ?  <div className="marker-txt">{props.adress.split(",")[2].split("/")[0].replace(/\d+/g,'').replace(' ','')}</div>:null}
                            <div><img src={marker} className="marker-icon"/></div>
                          </div>
                        </Marker>

                      </ReactMapGL> 

                        
                    </div>
                </div>
                :null}

            </div> 

          

{ props.openHours && props.openHours != "false" ?
                  <div className="openHours-cont">
                  <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                       <div className="hours">{props.t("Open Hours")}</div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>{
                      props.openHours.split(",").filter(x => x).map((hour, index) => {
                            return <div key={"h-"+index} className="hours-val" style={{"marginBottom":"8px"}} >{hour}</div>
                       })
                    }
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  </div>
                  :null}

                  </div>

            </div> :null}
  </div>
)};


EventLocation.defaultProps = {
    zoom: 13
};

export default translate("translations")(EventLocation);

