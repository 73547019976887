import React from 'react';
import PropTypes from 'prop-types';
import './EventKeyInfo.scss';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { translate } from 'react-i18next';

import bonusEvent from './../../../../assets/icons/bonus-event.svg';
import altEvent from './../../../../assets/icons/alt-event.svg';

const EventKeyInfo = (props) => {


const [expanded1, setExpanded1] = React.useState(true);
const [expanded2, setExpanded2] = React.useState(true);
const [expanded3, setExpanded3] = React.useState(true);
const [expanded5, setExpanded5] = React.useState(true);
const [expanded6, setExpanded6] = React.useState(true);
const [expanded7, setExpanded7] = React.useState(true);
const [expanded8, setExpanded8] = React.useState(true);
//const [expanded9, setExpanded9] = React.useState(true);


const handleChange1 = (panel) => (event, isExpanded) => {
  setExpanded1(!expanded1);
};

const handleChange2 = (panel) => (event, isExpanded) => {
  setExpanded2(!expanded2);
};

const handleChange3 = (panel) => (event, isExpanded) => {
  setExpanded3(!expanded3);
};

const handleChange5 = (panel) => (event, isExpanded) => {
  setExpanded5(!expanded5);
};

const handleChange6 = (panel) => (event, isExpanded) => {
  setExpanded6(!expanded6);
};
const handleChange7 = (panel) => (event, isExpanded) => {
  setExpanded7(!expanded7);
};
const handleChange8 = (panel) => (event, isExpanded) => {
  setExpanded8(!expanded8);
};
/*const handleChange9= (panel) => (event, isExpanded) => {
  setExpanded5(!expanded9);
};*/

return(
  <div className="EventKeyInfo">

{	props.importance || props.historyOfTheEvent || props.thingsToKnow  || props.tipGuide || props.hygieneRules || props.transportation ?
    	<h3 className="sub-title">{props.t("Key Information About this Event")}</h3>
    	 :null
	        }
    	  {	props.t("VisibleAreaImportance") !== "VisibleAreaImportance" && props.t("VisibleAreaImportance") === "True" && props.importance ?
    	<Accordion expanded={expanded1} onChange={handleChange1('panel1')}>
	        <AccordionSummary
	          expandIcon={<ExpandMoreIcon />}
	          aria-controls="panel1a-content"
	          id="panel1a-header"
	        >
	          {props.t("The importance of the event in terms of tourism")}
	        </AccordionSummary>
	        <AccordionDetails>
	        	<div  dangerouslySetInnerHTML={{ __html: props.importance }} />
	        </AccordionDetails>
      	</Accordion>
      	 :null
	        }
      	  {		props.t("VisibleAreaHistoryOfTheEvent") !== "VisibleAreaHistoryOfTheEvent" && props.t("VisibleAreaHistoryOfTheEvent") === "True" && props.historyOfTheEvent ?
      	<Accordion expanded={expanded2} onChange={handleChange2('panel2')}>
	        <AccordionSummary
	          expandIcon={<ExpandMoreIcon />}
	          aria-controls="panel2a-content"
	          id="panel2a-header"
	        >
	          {props.t("What is the story / history of the event?")}
	        </AccordionSummary>
	        <AccordionDetails>
	        <div  dangerouslySetInnerHTML={{ __html: props.historyOfTheEvent }} />
	        </AccordionDetails>
	        
      	</Accordion>
      	 :null
	        }
     {		props.t("VisibleAreaThingsToKnow") !== "VisibleAreaThingsToKnow" && props.t("VisibleAreaThingsToKnow") === "True" && props.thingsToKnow ?
      	<Accordion expanded={expanded3} onChange={handleChange3('panel3')}>
	        <AccordionSummary
	          expandIcon={<ExpandMoreIcon />}
	          aria-controls="panel3a-content"
	          id="panel3a-header"
	        >
	          {props.t("Things to know")}
	        </AccordionSummary>
	        <AccordionDetails>
	        {	props.thingsToKnow ?
	        	<div  dangerouslySetInnerHTML={{ __html: props.thingsToKnow }} />
	        	:null
	        }
	        </AccordionDetails>
      	</Accordion>
 :null
	        }

      	{	props.t("VisibleAreaTipGuide") !== "VisibleAreaTipGuide" && props.t("VisibleAreaTipGuide") === "True" && props.tipGuide ? 
      	 <Accordion expanded={expanded6} onChange={handleChange6('panel6')}>
	        <AccordionSummary
	          expandIcon={<ExpandMoreIcon />}
	          aria-controls="panel6a-content"
	          id="panel6a-header"
	        >
	          {props.t("Tip Guide")}
	        </AccordionSummary>
	        <AccordionDetails>
	        {	props.tipGuide ?
	        	<div  dangerouslySetInnerHTML={{ __html: props.tipGuide }} />
	        	:null
	        }
	        </AccordionDetails>
      	</Accordion>
      	:null}


      	{	props.t("VisibleAreaHygieneRules") !== "VisibleAreaHygieneRules" && props.t("VisibleAreaHygieneRules") === "True" && props.hygieneRules ? 
      	 <Accordion expanded={expanded7} onChange={handleChange7('panel7')}>
	        <AccordionSummary
	          expandIcon={<ExpandMoreIcon />}
	          aria-controls="panel7a-content"
	          id="panel7a-header"
	        >
	          {props.t("HygieneRulesTitle")}
	        </AccordionSummary>
	        <AccordionDetails>
	        {	props.hygieneRules ?
	        	<div  dangerouslySetInnerHTML={{ __html: props.hygieneRules }} />
	        	:null
	        }
	        </AccordionDetails>
      	</Accordion>
      	:null}

      	{props.t("VisibleAreaTransportation") !== "VisibleAreaTransportation" && props.t("VisibleAreaTransportation") === "True" && props.transportation ? 
      	 <Accordion expanded={expanded8} onChange={handleChange8('panel8')}>
	        <AccordionSummary
	          expandIcon={<ExpandMoreIcon />}
	          aria-controls="panel8a-content"
	          id="panel8a-header"
	        >
	          {props.t("TransportationTitle")}
	        </AccordionSummary>
	        <AccordionDetails>
	        {	props.transportation ?
	        	<div  dangerouslySetInnerHTML={{ __html: props.transportation }} />
	        	:null
	        }
	        </AccordionDetails>
      	</Accordion>
      	:null}



      	{/*<Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
	        <AccordionSummary
	          expandIcon={<ExpandMoreIcon />}
	          aria-controls="panel4a-content"
	          id="panel4a-header"
	        >
	          Things to know before arrival
	        </AccordionSummary>
	        <AccordionDetails>
	        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
	            sit amet blandit leo lobortis eget.
	        </AccordionDetails>
      	</Accordion>*/}
      	
      	{ /*(props.spots && props.spots.length > 0) || props.events.filter(item => item.cardId == props.id).length >0 ?
	      	<Accordion  expanded={expanded5} onChange={handleChange5('panel5')}>
		        <AccordionSummary
		          expandIcon={<ExpandMoreIcon />}
		          aria-controls="panel5a-content"
		          id="panel5a-header"
		        >
		       {props.t("Alternative Event & Bonus Events")}
		        </AccordionSummary>
		        <AccordionDetails>
		        <div className="bonus-events-container">
		        	{	
		        		props.events?
		        		props.events.filter(item => item.cardId == props.id).map((eventData, i) => 
			        		<div className="bonus-event-wrap" key={i}>
					            <div className="d-flex bonus-event">
					            	<div className="ev-img"><img src={altEvent}/></div>
					            	<div className="ev-cnt">
					            		<h5 className="bonus-title">{eventData.nameOfTheEvent}</h5>
					            		
					            		{ eventData.openHours && eventData.openHours != "false" ? <p className="open-hours">{props.t("Open Hours")}:<br/><br/></p> : null}
						                {    
						                   eventData.openHours && eventData.openHours != "false" ?
						                       eventData.openHours.split(",").filter(x => x).map((hour, index) => {
						                            return <p key={index} className="open-hours">{hour}</p>
						                       })
						                       :null
						                }
					            	</div>
					            </div>
				            </div>
			            )
			            :null
		        	}

			        {	props.spots?
			        	props.spots.map((spot, i) =>
			        	<div className="bonus-event-wrap" key={i}>
				            <div className="d-flex bonus-event">
				            	<div className="ev-img"><img src={bonusEvent}/></div>
				            	<div className="ev-cnt">
				            		<h5 className="bonus-title">{spot.locationName} - {spot.nameOfTheEvent}</h5>
				            		<p className="bonus-txt">{spot.shortDescription}</p>
				            	</div>
				            </div>
			            </div>
			        ):null}
		        </div>
		        </AccordionDetails>
	      	</Accordion>
      	:null*/}


  </div>
)};


EventKeyInfo.defaultProps = {};

export default translate("translations")(EventKeyInfo);
