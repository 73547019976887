import React, { Component } from 'react';
import axios from 'axios';
import './RelatedArticles.scss';

export default class RelatedArticles extends Component{ 

	state = {
	  articles:[],
	  showMoreMobile:false
	};

	componentDidMount () {
	    axios.get( 'Order/getTdPosts/3/1/'+this.props.designerId )
	         .then( response => {
	             this.setState({articles: response.data});
	         });
	}

	render(){

	return(
	  <div className="RelatedArticles">
	  { this.state.articles.length > 0 ?
	    <div className="container">
	    <h3 className="sub-title">Browse related articles</h3>
	    	<div className="articles-container">
		    	<div className={this.state.showMoreMobile ? "articles d-flex show-more-mobile-articles":"articles d-flex"}>
		    		{ this.state.articles.map((article, i) =>
					    <div className="article" key={i}>
					    	<a href={article.link} target="_blank" >
							    <div className="pic bgImg" style={{ backgroundImage:  "url(" + article.image + ")" }} ></div>
							    <div className="title">{article.title}</div>
							    <div className="content"><p>{article.description}</p></div>
							    <div className="author d-flex align-items-center">
									<div className="avatar" style={{ flexShrink:0,backgroundImage:  "url(" + article.userImage + ")" }}></div>
							    	<div className="name truncate">{article.userName}</div>
							    </div>
						    </a>
					    </div>
				    ) }
	    		</div>
		    </div>
	    </div>:null}
	    { this.state.articles.length > 1 && !this.state.showMoreMobile ? <div className="mobile-more container"> <button className="btn-o-w" onClick={()=>this.setState({showMoreMobile:true})}>Show More</button> </div>:null}
	  </div>
	)};

}


