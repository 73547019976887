import React from 'react';
import PropTypes from 'prop-types';
import './PlanStoryFeature.scss';
import { translate } from 'react-i18next';

const PlanStoryFeature = (props) => (
  <div className="PlanStoryFeature d-flex flex-wrap align-items-center">
   		<div className="tag">{props.t("Total places", {total: props.cards.reduce((total,item) => total + item.spots.length,props.cards.length)})}</div>
    	<div className="tag">{props.t(" Main Events", {main: props.cards.filter(item => !item.cardId).length})}</div>    
    	<div className="tag">{props.t("walking",{walking:props.walking })}</div> 
{ props.availabilities.reduce((coupleEv,item) =>  coupleEv ? coupleEv : item.includes("Çiftler") ,false)  ? <div className="tag">{props.t("GREAT FOR COUPLES")}</div>:null}   	
		{props.cards.reduce((total,item) => item.cardId ? total + 1 : total ,0) > 0 ? <div className="tag"> {props.t(" Alternative Events",{total: props.cards.reduce((total,item) => item.cardId ? total + 1 : total ,0)})}</div>  :null}
		{props.cards.reduce((total,item) => total + item.spots.length ,0) > 0 ? <div className="tag"> {props.t(" Bonus Events", { total : props.cards.reduce((total,item) => total + item.spots.length ,0)})}</div>  :null}
    	{props.cards.length > 1 ? <div className="tag">{props.t("Between - ",{startTime: props.cards[0].startTime, endTime: props.cards[props.cards.length - 1].endTime})}</div>:null}
        {     props.tridDesignerCities ?
                
                props.tridDesignerCities.filter(ct=> ct.city == props.eventCity).map((c,i)=> 
                <div className="tag" key={i}>{props.t("livetext",{"inhabitText":props.t(c.inhabitText),"cityText": props.t(c.cityText),"inhabitCount":props.t(c.inhabitCount),"inhabitCircleText":props.t(c.inhabitCircleText.toLowerCase())})}</div>)
              :null
        }
  </div>
);

PlanStoryFeature.propTypes = {};

PlanStoryFeature.defaultProps = {};

export default translate("translations")(PlanStoryFeature);
