let ru = {
    translations: {
      "City_0": "Istanbul",
      "City_1": "Nevsehir (Cappadocia)",
      "City_2": "Denizli (Pamukkale)",
      "City_3": "Antalya",
      "City_4": "Canakkale",
      "City_5": "Izmir",
      "City_6": "Mugla",
      "City_7": "Paris",
      "City_8": "Nice",
      "City_9": "Marseille",
      "City_10": "Rome",
      "City_11": "Florence",
      "City_12": "Venice",
      "City_13": "Milano",
      "City_14": "Napoli",
      "City_15": "Amsterdam",
      "City_16": "Rotterdam",
      "City_17": "Berlin",
      "City_18": "Munich",
      "City_19": "Frankfurt",
      "City_20": "Hamburg",
      "City_21": "Barcelona",
      "City_22": "Madrid",
      "City_23": "Sevilla",
      "City_24": "Granada",
      "City_25": "London",
      "City_26": "Manchester",
      "tr" : "0",
"en" : "1",
"ru" : "2",
"TRY" : "TL",
"TL" : "₺",
"Euro" : "€",
"USD" : "$",
"Dolar" : "$",
"years" : "год(а) /лет",
"Living" : "живет",
"livetext" : "{{inhabitText}} в {{cityText}} {{inhabitCount}} {{inhabitCircleText}}",
"Müze" : "Музей",
"Tarih" : "История",
"Gezi" : "Поездка",
"Alışveriş" : "Шоппинг",
"Spor ve Etkinlik" : "Спорт и активный отдых",
"Eğlence" : "Развлечение",
"Etkinlik" : "Мероприятие",
"Yiyecek ve içecek" : "Еда и напитки",
"Açık Alan" : "Открытое пространство",
"Açık Arttırma" : "Аукцион",
"Ada" : "Остров",
"Bar" : "Бар",
"Bilet Gerekli" : "Нужен билет",
"Bisiklet" : "Велосипед",
"Cafe" : "Кафе",
"Cam Sanatı" : "Стеклянное искусство",
"Cami" : "Мечеть",
"Deniz Kenarı" : "Побережье",
"Deniz Ürünleri" : "Морепродукты",
"Doğa" : "Природа",
"Döner" : "Дёнер-кебаб (шаурма)",
"Esnaf Lokantası" : "Закусочная",
"Ev" : "Дом",
"Fuar / Expo" : "Ярмарка / Экспо",
"Galeri" : "Галерея",
"Gece" : "Ночь",
"Gece Hayatı" : "Ночная жизнь",
"Gizli Nokta" : "Секретное место",
"Gurme" : "Гурмэ",
"Havuz" : "Бассейн",
"Hint Mutfağı" : "Индийская кухня",
"İltalyan Mutfağı" : "Итальянская кухня",
"Kahvaltı" : "Завтрак",
"Kapalı Alan" : "Закрытое пространство",
"Karaoke" : "Караоке",
"Kebap" : "Кебаб",
"Kiralık Araba" : "Машина в аренду",
"Kokoreç" : "Кокореч (обжаренные бараньи потроха)",
"Koşu" : "Пробежка",
"Köfte" : "Котлеты",
"Köprü" : "Мост",
"Kütüphane" : "Библиотека",
"Liman / Marina" : "Порт / Пристань",
"Lounge" : "Лаунж-зона",
"Manevi / Dini" : "Духовный / Религиозный",
"Mangal" : "Мангал",
"Manzara" : "Вид",
"Meksika Mutfağı" : "Мексиканская кухня",
"Meydan" : "Площадь",
"Müzik" : "Музыка",
"Pahalı" : "Дорого",
"Park" : "Парк",
"Pasaj" : "Пассаж",
"Pide" : "Пиде (открытая лепешка с начинкой)",
"Piknik" : "Пикник",
"Pizza" : "Пицца",
"Plaj" : "Пляж",
"Rakı-Balık" : "Ракы и Рыба",
"Restoran" : "Ресторан",
"Rus Mutfağı" : "Русская кухня",
"Sağlık & Spa" : "Здоровье и Спа",
"Sahaf" : "Букинистический магазин",
"Sergi" : "Выставка",
"Sokak Yemekleri" : "Уличная Еда",
"Spor" : "Спорт",
"Tekne Turu" : "Прогулка на яхте",
"Türk Mutfağı" : "Турецкая кухня",
"Ucuz" : "Дёшево",
"Vegan" : "Веганский",
"Workshop" : "Мастерская",
"Yerel Tatlar" : "Местные блюда",
"Engelliler Topluluğu" : "Сообщество инвалидов",
"Vejetaryen" : "Вегетарианский",
"İtalyan Mutfağı" : "Итальянская кухня",
"Hamam" : "Хамам",
"Müzayede" : "Аукцион",
"Kilise" : "Церковь",
"Sinagog" : "Синагога",
"Sightseeing" : "Поездка",
"Shopping" : "Шоппинг",
"SportAndActivity" : "Спорт и активный отдых",
"Entertainment" : "Развлечение",
"Events" : "События",
"FoodAndDrink" : "Еда и напитки",
"VisitArtOfGallery" : "Посещение - Галерея Искусств / Выставка",
"VisitMuseum" : "Посещение - Музей",
"VisitPlaceOfWorship" : "Посещение - Культовое Сооружение",
"FoodAndDrinkStreetFlavor" : "Еда и Напитки - Уличная Еда",
"FoodAndDrinkRestourant" : "Еда и Напитки - Ресторан",
"FoodAndDrinkBreakFast" : "Еда и Напитки - Завтрак",
"FoodAndDrinkCafe" : "Еда и Напитки - Кафе",
"FoodAndDrinkFineDining" : "Еда и Напитки - Fine Dining",
"SportAndActivityYogaAngPlates" : "Спорт, здоровье и активный отдых - Йога и пилатес",
"SportAndActivitySpa" : "Спорт, здоровье и активный отдых - Спа",
"SportAndActivityRunning" : "Спорт, здоровье и активный отдых - Пробежка",
"SportAndActivityFitness" : "Спорт, здоровье и активный отдых - Фитнес",
"SportAndActivityTreeking" : "Спорт, здоровье и активный отдых -Треккинг",
"SportAndActivitySycling" : "Спорт, здоровье и активный отдых - Прогулка на велосипедах",
"SportAndActivityTurkishBath" : "Спорт, здоровье и активный отдых - Хамам",
"TravelBoatTour" : "Прогулка - На яхте / по Босфору",
"TravelWalking" : "Прогулка - По городу",
"TravelParks" : "Прогулка - Парки и Сады",
"TravelSquares" : "Прогулка - Площадь",
"TravelStreets" : "Прогулка - Улицы и Улочки",
"EventTournament" : "Событие - Турнир & Соревнование: Зритель / Участник",
"EventConcert" : "Событие - Концерт",
"EventFair" : "Событие - Ярмарка (Торговля, Книги, Еда, Технологии)",
"EventFestival" : "Событие - Фестиваль (Кино, Музыка, Кофе, Куклы, Театр и др.)",
"EventBiennial" : "Событие - Биеннале",
"EntertainmentMeyhane" : "Развлечение - Мейхане",
"EntertainmentNightClub" : "Развлечение - Ночные клубы",
"EntertainmentCasino" : "Развлечение - Казино",
"EntertainmentBar" : "Развлечение - Бар",
"Live Support" : "Онлайн-поддержка (English)",
"Trip Designer" : "Дизайнер тура",
"trip design" : "{{count}} план(-а,-ов)",
"Fully access to this plan only for" : "Получите полный доступ к этому плане всего лишь за",
"Get Full Access" : "Приобрести полный доступ",
"Fully access to this plan for (one time).  %100 satisfaction guaranteed!" : "Купите этот план за {{discountedPrice}} {{priceCurrency}}. 100% гарантия качества!",
"tripDesigner-join-event" : "Дизайнер этого плана - {{firstname}} <span> не </span> присоедится к Вам во время Вашего путешествия",
"Fully access to this plan for (one time)" : "Вы можете получить полный доступ ко всему плану всего за {{price}} {{currency}}. (Одноразовый платеж)",
"%100 satisfaction guaranteed." : "100% гарантия качества.",
"Browse other plans matching my trip dates" : "Посмотрите другие планы, соответствующие вашим датам путешествия",
"Browse Plans" : "Посмотреть планы",
"or" : "или",
"This is a paid content. To view please click on “Get Full Access” button." : "Это платный контент. Что посмотреть, пожалуйста, нажмите 'Приобрести полный доступ'",
"Following event is brought to you by HeyTripster for demo purposes." : "Следующее мероприятие предоставлено вам HeyTripster для предварительного просмотра. <br/> Нажмите кнопку «Приобрести полный доступ», чтобы получить доступ к плану на целый день и познакомиться с городом {{city}}, как предлагает {{designerName}}.",
"Play Trailer" : "Посмотреть трейлер",
"Show me 1 Sample Event" : "Показать образец мероприятия",
"Plan Summary" : "Краткий обзор плана",
"View this event for free" : "Посмотреть это мероприятние бесплатно",
"Travellers give an average rating" : "Путешественники в среднем поставили {{rating}} из 5 звезд нашему дизайнеру путешествий {{name}}.",
"This Plan Includes" : "Этот план включает",
"Total places" : "Итого {{total}} мест(-о,-а)",
" Main Events" : "{{main}} Главное(-ых) мероприятие(-я,-й)",
"walking" : "{{walking}} прогулки пешком",
"Çiftler" : "Отлично подходит для пар",
" Alternative Events" : "{{total}} Альтернативное(-ых) мероприятие(-я,-й)",
" Bonus Events" : "{{total}} Достопримечатльность(-и,-ей), расположенная(-ые,ых) рядом",
"Between - " : "с {{startTime}} - по {{endTime}}",
"PlanAvarage" : "Сред. расходы {{planAvaregePrice}} {{planCurrency}}",
"PlanFree" : "План без дополнительных расходов",
"300+ different plans, 100+ trip designers at HeyTripster." : "117+ планов и 67+ дизайнеров путешествий на HeyTripster",
"Arrival Date" : "Дата прибытия",
"Return Date" : "Дата отъезда",
"Browse All Plans" : "Посмотреть все планы",
"Languages" : "Языки",
"LanguagesArabic" : "Арабский",
"LanguagesBengali" : "Бенгальский",
"LanguagesChinese" : "Китайский",
"LanguagesDutch" : "Нидерландский",
"LanguagesEnglish" : "Английский",
"LanguagesFrench" : "Французский",
"LanguagesGerman" : "Немецкий",
"LanguagesGreek" : "Греческий",
"LanguagesHebrew" : "Иврит",
"LanguagesHindi" : "Хинди",
"LanguagesIndonesian" : "Индонезийский",
"LanguagesJavanese" : "Яванский",
"LanguagesJapanese" : "Японский",
"LanguagesItalian" : "Итальянский",
"LanguagesKorean" : "Корейский",
"LanguagesPersian" : "Персидский",
"LanguagesPolish" : "Польский",
"LanguagesPortuguese" : "Португальский",
"LanguagesRussian" : "Русский",
"LanguagesSpanish" : "Испанский",
"LanguagesTurkish" : "Турецкий",
"LanguagesUrdu" : "Урду",
"LanguagesVietnamese" : "Вьетнамский",
"Planted Trees" : "Посаженное(ых) дерево(-а,-ьев)",
"Number of Plans Sold" : "Количество проданных планов",
"Themes of ’s trip plans" : "{{fullName}} разрабатывает планы для следующих тем",
"Travel" : "Путешествие",
"Nature" : "Природа",
"Art" : "Искусство",
"Shopping" : "Шоппинг",
"Sports & Activity" : "Спорт и активный отдых",
"Entertainment" : "Развлечения",
"Event" : "Событие",
"Eating & Drinking" : "Еда и напитки",
"Culture & History" : "Культура и История",
"Favorite Song" : "Любимая песня",
"What’s in every HeyTripster plan?" : "В каждый план HeyTripster включено",
"Get Started" : "Начать сейчас",
"Information to prevent you from roaming aimlessly." : "Информация, которая не позволит Вам бесцельно бродить по городу.",
"What to eat, buy, take pictures & more." : "Что поесть, что купить, что посмотреть и многое другое.",
"Relevant and reliable information." : "Актуальная и достоверная информация.",
"Insider tips like transportation, dress code & more." : "Советы, включающие информацию о транспорте, дресс-коде и о многом другом ",
"A tree planted on your behalf." : "Дерево, посаженное от Вашего имени",
"Things to watch out for like common scams." : "Вещи, как, например мошенничество, которых стоит отсерегаться",
"Min. 5 main events with hidden spots & alternatives." : "Как минимум 5 основных мероприятий с секретными местами и различными альтернативами",
"Themes from candlelit dinner to adrenaline-filled activities" : "Разнообразные темы от ужинов при свечах до активного отдыха, полного адреналина.",
"PDF export or interactive app (iOS & Android)" : "Посмотреть в формате PDF или в интерактивном приложении (iOS и Android)",
"TOTAL PLACES" : "ИТОГО {{total}} мест(-о,-а)",
" MAIN EVENTS" : "{{main}} ГЛАВНОЕ(-ЫХ) МЕРОПРИЯТИЕ(-Я,-Й)",
"WALKING" : "{{walking}} ПРОГУЛКИ ПЕШКОМ",
"GREAT FOR COUPLES" : "ОТЛИЧНО ПОДХОДИТ ДЛЯ ПАР",
" ALTERNATIVE EVENTS" : "{{total}} АЛЬТЕРНАТИВНОЕ(-ЫХ) МЕРОПРИЯТИЕ(-Я,-Й)",
" BONUS EVENTS" : "{{total}} БОНУСНОЕ(-ЫХ) МЕРОПРИЯТИЕ(-Я,-Й)",
"BETWEEN - " : "С {{startTime}} -  ПО {{endTime}}",
"PLANAVARAGE" : "{{planAvaregePrice}} {{planCurrency}} Сред. РАСХОДЫ",
"FREE PLAN" : "План без дополнительных расходов",
"Speaks" : "Говорит на:",
"name's Plan" : "план дизайнера {{name}}  ",
"Browse other plans" : "Посмотреть другие планы",
"You can choose a suitable one for yourself and see its details." : "Выберите и изучите наиболее подходящий Вам план. За каждый выбранный Вами местный план, HeyTripster дарит дерево, которое посадят от Вашего имени",
"LOCAL" : "МЕСТНЫЙ",
"TOURIST" : "ПУТЕШЕСТВИННИК",
"CONCIERGE" : "КОНСЬЕРЖ",
"Browse all plans" : "Мы собрали планы, созданные местными жителями города для Вас",
"When do you plan to arrive" : "Когда Вы планируете приехать в город {{city}}?",
"We’ll check ’s plan is suitable for your travel window or not." : "Мы проверим, доступен ли этот план в даты Вашей поездки.",
"When is your trip" : "Даты Вашей поездки",
"Show me available trip plans" : "Показать доступные планы путешествий",
"Can I use this plan" : "Могу ли я воспользоваться этим планом?",
"We will list the trip plans suitable for your dates." : "Мы предложим планы, подходящие для даты Вашей поездки",
"Which email address should we send your trip plan?" : "На какой адрес электронной почты отправить Ваш план поездки?",
"Which email address should we send your confirmation?" : "На какой адрес электронной почты отправить подтверждение?",
"Show me available trip plans" : "Показать доступные планы путешествий",
"Travellers Reviews" : "Показать комментарии путешественников",
"Sorted by Relevancy" : "Перечислить по релевантности",
"Explore other trip plans" : "Изучите другие планы путешествий",
"s Other Designs" : "Другие планы от дизайнера путешествий {{designerName}}",
"My Instagram" : "Мой Instagram аккаунт",
"Browse related articles" : "Посмотреть статьи по теме",
"Golden Checklist" : "Золотой Чеклист",
"See" : "Стоит увидеть",
"Taste" : "Попробуй",
"Buy" : "Купи",
"Photo-Point" : "Сфотографируй",
"Do" : "Сделай",
"Read" : "Прочитай",
"Listen" : "Послушай",
"Don't" : "Не делай",
"Watch a video related to this event" : "Посмотреть видео об этом мероприятии",
"My Cities" : "Мои города",
"MyCitiesCity0" : "Стамбул",
"MyCitiesCity1" : "Невшехир (Каппадокия)",
"MyCitiesCity2" : "Денизли (Памуккале)",
"MyCitiesCity3" : "Анталья",
"MyCitiesCity4" : "Чанаккале",
"MyCitiesCity5" : "Измир",
"MyCitiesCity6" : "Мугла",
"MyCitiesCity7" : "Париж",
"MyCitiesCity8" : "Ницца",
"MyCitiesCity9" : "Марсель",
"MyCitiesCity10" : "Рим",
"MyCitiesCity11" : "Флоренция",
"MyCitiesCity12" : "Венеция",
"MyCitiesCity13" : "Милан",
"MyCitiesCity14" : "Неаполь",
"MyCitiesCity15" : "Амстердам",
"MyCitiesCity16" : "Роттердам",
"MyCitiesCity17" : "Берлин",
"MyCitiesCity18" : "Мюнхен",
"MyCitiesCity19" : "Франкфурт",
"MyCitiesCity20" : "Гамбург",
"MyCitiesCity21" : "Барселона",
"MyCitiesCity22" : "Мадрид",
"MyCitiesCity23" : "Севилья",
"MyCitiesCity24" : "Гранада",
"MyCitiesCity25" : "Лондон",
"MyCitiesCity26" : "Манчестер",
"Visited Countries" : "Посещенные страны",
"About Event" : "О мероприятии",
"Event Type" : "Тип мероприятия",
"Suggested Duration" : "Рекомендованное время начала и продолжительность",
"Reservation" : "Бронирование",
"Not Required" : "Не обязательно",
"Required" : "необходимые",
"Avg. Price" : "Сред. расходы",
"Suggested Start Time" : "Рекомендованное время начала и продолжительность",
"Location" : "Местоположение",
"Key Information About this Event" : "Важная информация об этом мероприятии",
"The importance of the event in terms of tourism" : "Туристическая значимость мероприятия",
"What is the story / history of the event?" : "История мероприятия",
"Things to know" : "Что нужно знать",
"Alternative Event & Bonus Events" : "Альтернативные мероприятия & Бонусные мероприятия",
"When is your trip?" : "Даты Вашей поездки",
"We will list the trip plans suitable for your dates." : "Мы предложим планы, подходящие для даты Вашей поездки",
"January" : "Январь",
"February" : "Февраль",
"March" : "Март",
"April" : "Апрель",
"May" : "Май",
"June" : "Июнь",
"July" : "Июль",
"August" : "Август",
"September" : "Сентябрь",
"October" : "Октябрь",
"November" : "Ноябрь",
"December" : "Декабрь",
"Yes, you can use this plan" : "Да, Вы можете использовать этот план",
"Your trip designer suggests this plan for the following dates." : "Ваш дизайнер путешествия рекомендует этот план на следующие дни.",
"What is your name?" : "Как Вас зовут?",
"Name" : "Имя",
"Surname" : "Фамилия",
"To Get Full Access" : "Чтобы получить план",
"Continue to checkout" : "Перейти к оплате",
"Show me available trip plans" : "Показать доступные планы",
"Can I use this plan" : "Могу ли я использовать этот план?",
"We will list the trip plans suitable for your dates." : "Мы предложим планы путешествий, подходящие для даты Вашей поездки.",
"Which email address should we send your trip plan?" : "На какой адрес электронной почты отправить Ваш план путешествия?",
"Which email address should we send your confirmation?" : "На какой адрес электронной почты отправить подтверждение?",
"We’ll check ’s plan is suitable for your travel window or not." : "Мы проверим, доступен ли этот план в даты Вашей поездки.",
"Main Event" : "Основное мероприятие",
"Highlights" : "Ключевое мероприятие",
"This is a paid content." : "Это платный контент.",
"To view please complete payment." : "Купить для просмотра.",
"Show me available trip plans" : "Показать доступные маршруты",
"Show plan on a map" : "Посмотреть план на карте",
"Live Support" : "Онлайн-поддержка (English)",
"Travellers Reviews" : "Отзывы путешественников",
"Sorted by Relevancy" : "Сортировано по релевантности",
"-Less" : "-Меньше",
"+More" : "+ Больше",
"Explore other trip plans" : "Изучите другие планы путешествий",
"Don't" : "Не делай",
"Listen" : "Послушай",
"Read" : "Прочитай",
"Do" : "Сделай",
"Photo Point" : "Сфотографируй",
"Buy" : "Купи",
"Taste" : "Попробуй",
"See" : "Стоит увидеть",
"Golden CheckList" : "Золотой Чеклист",
"Click here for more information" : "Щелкните для получения дополнительной информации",
"Your trip designer is" : "Ваш дизайнер путешествий - <span className = 'truncate'> {{designerName}} </span>",
"SAMPLE EVENT" : "КЛЮЧЕВОЕ <br/> МЕРОПРИЯТИЕ",
"About Event" : "О мероприятии",
"Location" : "Геолокация",
"Event Type" : "Тип мероприятия",
"Suggested Duration" : "Рекомендуемое время начала и продолжительность ",
" min" : "мин.",
"Reservation" : "Бронирование",
"Avg. Price" : "Сред. расходы",
"Suggested Start Time" : "Рекомендуемое время начала и продолжительность ",
"Free Event" : "Бесплатное мероприятие",
"Watch a video related to this event." : "Видео ссылка о мероприятии",
"Open Hours" : "Часы работы",
"Key Information About this Event" : "Важная информация об этом мероприятии",
"The importance of the event in terms of tourism" : "Важность мероприятия для туризма",
"What is the story / history of the event?" : "История мероприятия",
"Things to know" : "Что нужно знать",
"Alternative Event & Bonus Events" : "Альтернативные мероприятия и бонусные мероприятия",
"Bonus Event Icon" : "Бонусное мероприятие",
"About your trip designer" : "О Вашем дизайнере путешествия",
"Bio" : "О(б)",
"Visited Countries" : "Посещенные страны",
"Show More" : "Показать больше",
"My Cities" : "Мои города",
"PlanTreeInfoText" : "В HeyTripster мы сажаем дерево каждый раз, когда Вы покупаете план путешествия. Это наш способ помочь сократить выбросы парниковых газов в атмосферу во время Ваших путешествий. Мы надеемся посадить 100.000 деревьев к 2022 году.",
"Frequently asked questions" : "Часто задаваемые вопросы",
"What is your name?" : "Как Вас зовут?",
"Loading..." : "Загружается ...",
"Yes, you can use this plan" : "Да, Вы можете использовать этот план",
"Please pick one." : "Пожалуйста, выберите одно из",
"Your trip designer suggested the best possible dates for this plan." : "Дизайнер путешествия предложил оптимальные даты для этого плана.",
"To Get Full Access" : "Чтобы получить полный доступ к плану",
"Continue to checkout" : "Перейти к оплате",
"Yes, take me to available plans." : "Да, покажите мне доступные планы.",
"PlanNotApplicableText" : "План дизайнера путешествий {{designerName}} <b> не подходит </b> для использования в период с <b> {{startDate}} по {{endDate}} </b>. Однако мы нашли <span> {{otherPlansLength}} отличный(-ых) план(а,-ов), которым(-и) вы можете воспользоваться в выбранные даты. </span>",
"Would you like to review these plans?" : "Хотите просмотреть эти планы <br/>?",
"Pay with" : "Оплатить",
"Name on Card" : "Имя на карточке",
"Card Number" : "Номер карты",
"TermsText" : "Выполнив этот платеж, Вы соглашаетесь с условиями <a href='https://heytripster.com/mesafeli-satis-sozlesmesi/?utm=diniz=uthent allowed==email=====offemail dup=' target='_blank'> Освобождения от права требования и отказа от претензий </ a>, <a href='https://heytripster.com/mesafeli-satis-sozlesmesi/?utm=diniz=uthenticated==email= alert='_blank'> Политики отмены </ a > и <a href='https://heytripster.com/mesafeli-satis-sozlesmesi/?utm=izmetİHname= Çevir &email= Powder='_blank'> Политики Возврата </a>.",
"Review and Pay" : "Проверьте данные и завершите оплату",
"After payment, you can leave the e-mail addresses of the people you want your trip plan to be shared with, or you can share it later if you wish." : "После оплаты Вы можете поделиться своим планом путешествия с друзьями, указав их адрес электронной почты, или Вы можете поделиться им позже.",
"Designed by" : "Дизайнер путешествий {{tridDesigner}}",
"See More" : "Узнать больше",
"By purchasing single" : "Приобретая этот план, Вы предоставляете возможность посадить {{count}} саженцев в городе {{city}}.",
"By purchasing multiple" : "Приобретая эти планы, вы предоставляете возможность посадить {{count}} саженцев в городе {{city}}.",
"Enter a coupon code" : "Введите код купона",
"Get discount!" : "Получите {{Discount}} скидку!",
"Apply" : "Применить",
"Discount applied" : "Скидка применена",
"discount applied" : "применена скидка",
"PlanPickedForDaySingle" : "План {{basketSource}} выбран на даты {{planDays}}.",
"PlanPickedForDayMultiple" : "План {{basketSource}} выбран на даты {{planDays}}.",
"x trip plan" : "{{price}} {{currency}} x {{count}} план путешествия",
"CurencyPrice" : "{{price}} {{currency}}",
"Service fee" : "Стоимость услуги",
"TotalCurency" : "Итого ({{currency}})",
"Confirm and Pay" : "Подтвердить и оплатить",
"100% satisfaction guaranteed." : "100% гарантия качества.",
"If you're not satisfied, get a full refund." : "Если вы останетесь недовольны, то получите возврат средств в полном объёме.",
"More Details" : "Подробнее",
"Total" : "Всего",
"Payment Success" : "Платёж успешно выполнен",
"You just unlocked your trip plan!" : "Вы приобрели план путешествия!",
"You will receive your trip plan in the next 24 hours." : "Ваш план будет отправлен Вам в течение 24 часов.",
"Did you know we plant 1 tree for every plan you get!" : "Знаете ли Вы, что мы сажаем по одному дереву за каждый купленный Вами план?",
"10 Million trees on the way!" : "10 миллионов деревьев уже в пути!",
"Who else is travelling with you?" : "Кто еще путешествует с Вами?",
"Share your plans with them!" : "Поделись с ними своими планами!",
"Thank You!" : "Спасибо!",
"We look forward to seeing you and your friends." : "Мы с нетерпением ждем встречи с Вами и Вашими друзьями.",
"+ Add" : "+ Добавить {{other}},",
"Share my Plans" : "Поделиться моим планом путешествия",
"Andorra" : "Андорра",
"United Arab Emirates" : "Объединенные Арабские Эмираты",
"Afghanistan" : "Афганистан",
"Antigua and Barbuda" : "Антигуа и Барбуда",
"Anguilla" : "Ангилья",
"Albania" : "Албания",
"Armenia" : "Армения",
"Netherlands Antilles" : "Нидерландские Антильские острова",
"Angola" : "Ангора",
"Antarctica" : "Антарктида",
"Argentina" : "Аргентина",
"American Samoa" : "Американское Самоа",
"Austria" : "Австрия",
"Australia" : "Австралия",
"Aruba" : "Аруба",
"Aland Islands" : "Аландские острова",
"Azerbaijan" : "Азербайджан",
"Bosnia and Herzegovina" : "Босния и Герцеговина",
"Barbados" : "Барбадос",
"Bangladesh" : "Бангладеш",
"Belgium" : "Бельгия",
"Burkina Faso" : "Буркина-Фасо",
"Bulgaria" : "Болгария",
"Bahrain" : "Бахрейн",
"Burundi" : "Бурунди",
"Benin" : "Бенин",
"Saint Barthélemy" : "Сен-Бартелеми",
"Bermuda" : "Бермуды",
"Brunei Darussalam" : "Бруней",
"Bolivia Plurinational State of" : "Многонациональное Государство Боливия",
"Caribbean Netherlands" : "Карибские Нидерланды",
"Brazil" : "Бразилия",
"Bahamas" : "Багамы",
"Bhutan" : "Бутан",
"Bouvet Island" : "Остров Буве",
"Botswana" : "Ботсвана",
"Belarus" : "Беларусь",
"Belize" : "Белиз",
"Canada" : "Канада",
"Cocos (Keeling) Islands" : "Кокосовые острова (Килинг)",
"Congo the Democratic Republic of the" : "Демократическая Республика Конго",
"Central African Republic" : "Центрально-Африканская Республика",
"Congo" : "Конго",
"Switzerland" : "Швейцария",
"Cote d'Ivoire" : "Кот-д'Ивуар",
"Cook Islands" : "Острова Кука",
"Chile" : "Чили",
"Cameroon" : "Камерун",
"China" : "Китай",
"Colombia" : "Колумбия",
"Costa Rica" : "Коста-Рика",
"Cuba" : "Куба",
"Cape Verde" : "Кабо-Верде",
"Curacao" : "Кюрасао",
"Christmas Island" : "Остров Рождества",
"Cyprus" : "Кипр",
"Czech Republic" : "Республика Чехия",
"Germany" : "Германия",
"Djibouti" : "Джибути",
"Denmark" : "Дания",
"Dominica" : "Доминика",
"Dominican Republic" : "Доминиканская Республика",
"Algeria" : "Алжир",
"Ecuador" : "Эквадор",
"Estonia" : "Эстония",
"Egypt" : "Египет",
"Western Sahara" : "Западная Сахара",
"Eritrea" : "Эритрея",
"Spain" : "Испания",
"Ethiopia" : "Эфиопия",
"Europe" : "Европа",
"Finland" : "Финляндия",
"Fiji" : "Фиджи",
"Falkland Islands (Malvinas)" : "Фолклендские (Мальвинские) острова",
"Micronesia Federated States of" : "Федеративные Штаты Микронезии",
"Faroe Islands" : "Фарерские острова",
"France" : "Франция",
"Gabon" : "Габон",
"England" : "Англия",
"Northern Ireland" : "Северная Ирландия",
"Scotland" : "Шотландия",
"Wales" : "Уэльс",
"United Kingdom" : "Великобритания",
"Grenada" : "Гренада",
"Georgia" : "Грузия",
"French Guiana" : "Французская Гвиана",
"Guernsey" : "Гернси",
"Ghana" : "Гана",
"Gibraltar" : "Гибралтар",
"Greenland" : "Гренландия",
"Gambia" : "Гамбия",
"Guinea" : "Гвинея",
"Guadeloupe" : "Гваделупа",
"Equatorial Guinea" : "Экваториальная Гвинея",
"Greece" : "Греция",
"South Georgia and the South Sandwich Islands" : "Южная Георгия и Южные Сандвичевы острова",
"Guatemala" : "Гватемала",
"Guam" : "Гуам",
"Guinea-Bissau" : "Гвинея-Бисау",
"Guyana" : "Гайана",
"Hong Kong" : "Гонконг",
"Heard Island and McDonald Islands" : "Остров Херд и острова Макдональд",
"Honduras" : "Гондурас",
"Croatia" : "Хорватия",
"Haiti" : "Гаити",
"Hungary" : "Венгрия",
"Indonesia" : "Индонезия",
"Ireland" : "Ирландия",
"Israel" : "Израиль",
"Isle of Man" : "Остров Мэн",
"India" : "Индия",
"British Indian Ocean Territory" : "Британская территория Индийского океана",
"Iraq" : "Ирак",
"Iran Islamic Republic of" : "Исламская Республика Иран",
"Iceland" : "Исландия",
"Italy" : "Италия",
"Jersey" : "Джерси",
"Jamaica" : "Ямайка",
"Jordan" : "Иордания",
"Japan" : "Япония",
"Kenya" : "Кения",
"Kyrgyzstan" : "Кыргызстан",
"Cambodia" : "Камбоджа",
"Kiribati" : "Кирибати",
"Comoros" : "Коморские острова",
"Saint Kitts and Nevis" : "Сент-Китс и Невис",
"Korea Democratic People's Republic of" : "Корейская Народно-Демократическая Республика",
"Korea Republic of" : "Республика Корея",
"Kuwait" : "Кувейт",
"Cayman Islands" : "Каймановы острова",
"Kazakhstan" : "Казахстан",
"Lao People's Democratic Republic" : "Лаосская Народно-Демократическая Республика",
"Lebanon" : "Ливан",
"Saint Lucia" : "Люсия",
"Liechtenstein" : "Лихтенштейн",
"Sri Lanka" : "Шри-Ланка",
"Liberia" : "Либерия",
"Lesotho" : "Лесото",
"Lithuania" : "Литва",
"Luxembourg" : "Люксембург",
"Latvia" : "Латвия",
"Libya" : "Ливия",
"Morocco" : "Марокко",
"Monaco" : "Монако",
"Moldova Republic of" : "Молдова",
"Montenegro" : "Черногория",
"Saint Martin" : "Сен-Мартен",
"Madagascar" : "Мадагаскар",
"Marshall Islands" : "Маршалловы острова",
"Macedonia the former Yugoslav Republic of" : "Республика Северная Македония",
"Mali" : "Мали",
"Myanmar" : "Мьянма",
"Mongolia" : "Монголия",
"Macao" : "Макао",
"Northern Mariana Islands" : "Северные Марианские острова",
"Martinique" : "Мартиника",
"Mauritania" : "Мавритания",
"Montserrat" : "Монтсеррат",
"Malta" : "Мальта",
"Mauritius" : "Маврикий",
"Maldives" : "Мальдивы",
"Malawi" : "Малави",
"Mexico" : "Мексика",
"Malaysia" : "Малайзия",
"Mozambique" : "Мозамбик",
"Namibia" : "Намибия",
"New Caledonia" : "Новая Каледония",
"Niger" : "Нигер",
"Norfolk Island" : "Остров Норфолк",
"Nigeria" : "Нигерия",
"Nicaragua" : "Никарагуа",
"Netherlands" : "Нидерланды",
"Norway" : "Норвегия",
"Nepal" : "Непал",
"Nauru" : "Науру",
"Niue" : "Ниуэ",
"New Zealand" : "Новая Зеландия",
"Oman" : "Оман",
"Panama" : "Панама",
"Peru" : "Перу",
"French Polynesia" : "Французская Полинезия",
"Papua New Guinea" : "Папуа - Новая Гвинея",
"Philippines" : "Филиппины",
"Pakistan" : "Пакистан",
"Poland" : "Польша",
"Saint Pierre and Miquelon" : "Святые Пьер и Микелон",
"Pitcairn" : "Питкэрн",
"Puerto Rico" : "Порто Рико",
"Palestine" : "Палестина",
"Portugal" : "Португалия",
"Palau" : "Палау",
"Paraguay" : "Парагвай",
"Qatar" : "Катар",
"Réunion" : "Реюньон",
"Romania" : "Румыния",
"Serbia" : "Сербия",
"Russian Federation" : "Российская Федерация",
"Rwanda" : "Руанда",
"Saudi Arabia" : "Саудовская Аравия",
"Solomon Islands" : "Соломоновы острова",
"Seychelles" : "Сейшельские острова",
"Sudan" : "Судан",
"Sweden" : "Швеция",
"Singapore" : "Сингапур",
"Saint Helena Ascension and Tristan da Cunha" : "Острова Святой Елены, Вознесения и Тристан-да-Кунья",
"Slovenia" : "Словения",
"Svalbard and Jan Mayen Islands" : "Острова Шпицберген и Ян-Майен",
"Slovakia" : "Словакия",
"Sierra Leone" : "Сьерра-Леоне",
"San Marino" : "Сан-Марино",
"Senegal" : "Сенегал",
"Somalia" : "Сомали",
"Suriname" : "Суринам",
"South Sudan" : "Южный Судан",
"Sao Tome and Principe" : "Сан-Томе и Принсипи",
"El Salvador" : "Эль Сальвадор",
"Sint Maarten (Dutch part)" : "Синт-Мартен (голландская часть)",
"Syrian Arab Republic" : "Сирийская Арабская Республика",
"Swaziland" : "Свазиленд",
"Turks and Caicos Islands" : "Острова Теркс и Кайкос",
"Chad" : "Чад",
"French Southern Territories" : "Южные Французские Территории",
"Togo" : "Того",
"Thailand" : "Таиланд",
"Tajikistan" : "Таджикистан",
"Tokelau" : "Токелау",
"Timor-Leste" : "Тимор-Лешти",
"Turkmenistan" : "Туркменистан",
"Tunisia" : "Тунис",
"Tonga" : "Тонга",
"Turkey" : "Турция",
"Trinidad and Tobago" : "Тринидад и Тобаго",
"Tuvalu" : "Тувалу",
"Taiwan" : "Тайвань",
"Tanzania United Republic of" : "Объединенная Республика Танзания",
"Ukraine" : "Украина",
"Uganda" : "Уганда",
"US Minor Outlying Islands" : "Внешние малые острова США",
"United States" : "Соединенные Штаты Америки",
"Uruguay" : "Уругвай",
"Uzbekistan" : "Узбекистан",
"Holy See (Vatican City State)" : "Ватикан (Государство-город Ватикан)",
"Saint Vincent and the Grenadines" : "Святой Винсент и Гренадины",
"Venezuela Bolivarian Republic of" : "Боливарская Республика Венесуэла",
"Virgin Islands British" : "Британские Виргинские острова",
"Virgin Islands U.S." : "Виргинские острова США",
"Viet Nam" : "Вьетнам",
"Vanuatu" : "Вануату",
"Wallis and Futuna Islands" : "Острова Уоллис и Футуна",
"Kosovo" : "Косово",
"Samoa" : "Самоа",
"Yemen" : "Йемен",
"Mayotte" : "Майотта",
"South Africa" : "Южная Африка",
"Zambia" : "Замбия",
"Zimbabwe" : "Зимбабве",
"DiscountPercent" : "Применена скидка {{couponDiscountPercent}} % ",
"features headline" : "В каждый план HeyTripster включено",
"features exclamationPoint" : "Маршруты по городу, состаленные профессионалами разных областей и с разных перспектив",
"features placeholder" : "Как минимум 5 различных основных занятий, 1 альтернативное занятие и 5 бонусных мест",
"features appointment" : "Утренние планы продолжительностью не менее 8 часов и вечерние планы продолжительностью не менее 6 часов",
"features picture" : "Планы, подготовленный для разных групп путешественников",
"features tree" : "Неповторимые занятия, отвечающие различным интересам",
"features flags" : "Информация о маршруте, обновляемая ежемесячно",
"features pin" : "Практические советы, облегчающие путешествие",
"features clock" : "Подробная информация о местоположении и времени для всего дня",
"features homeMarker" : "Рассказы о местной жизни, важных событиях и истории города",
"features clipboard" : "Список рекомендаций, включающий особенности культуры, по событию",
"features Button" : "Показать доступные планы путешествий",
"data.themeOfTheDay" : "Тема дня указана здесь",
"data.shortDescription" : "Здесь приводится краткое описание дня.",
"data.difficulty" : "Образец",
"data.effort" : "Образец",
"data.walking" : "Образец",
"data.planAvaregePrice" : "Образец",
"data.planCurrency" : "₺",
"data.themeImage" : "https://api.heytripster.com/resources/images/122/d131f824-dbd3-4b8b-b76f-ec8949fc9e9d.jpg",
"data.themeImage" : "https://api.heytripster.com/resources/images/122/d131f824-dbd3-4b8b-b76f-ec8949fc9e9d.jpg",
"data.themeThumbnail" : "https://api.heytripster.com/resources/images/122/a7d9d47b-daaa-4679-a467-4c7107b30e8f.jpg",
"data.themeThumbnails" : "https://api.heytripster.com/resources/images/122/a7d9d47b-daaa-4679-a467-4c7107b30e8f.jpg",
"q.nameOfTheEvent" : "Здесь отображается название мероприятия",
"q.reason" : "Здесь указано историческое и туристическое значение маршрута.",
"q.importance" : "Здесь указана туристическая значимость мероприятия.",
"q.historyOfTheEvent" : "Здесь отображается история места.",
"q.availability" : "Здесь указаны доступные дни.",
"q.shortDescription" : "Здесь приводится краткое описание мероприятия.",
"q.images" : "https://api.heytripster.com/resources/images/122/d131f824-dbd3-4b8b-b76f-ec8949fc9e9d.jpg",
"q.locationName" : "Здесь отображается название геолокации.",
"q.openHours" : "Часы работы указаны здесь.",
"q.tipGuide" : "Здесь отображается рекомендации о сумме чаевых.",
"q.webSite" : "Веб-сайт указан здесь.",
"q.instagram" : "Здесь отображается аккаунт Instagram.",
"q.hygieneRules" : "Здесь указаны правила гигиены.",
"q.doSee" : "Здесь будет отображаться текст, который Вы напишите в поле «Стоит увидеть» в золотом чек-листе.",
"q.doTaste" : "Здесь будет отображаться текст, который Вы напишите в поле «Попробуй» в золотом чек-листе.",
"q.doBuy" : "Здесь будет отображаться текст, который Вы напишите в поле 'Купи' в золотом чек-листе.",
"q.doPhotographing" : "Здесь будет отображаться текст, который Вы напигите в поле «Сфотографируй» в золотом чек-листе.",
"q.doDo" : "Здесь будет отображаться текст, который Вы напишите в поле «Сделай» в золотом чек-листе.",
"q.doRead" : "Здесь будет отображаться текст, который вы напишите в поле  'Прочитай' в золотом чек-листе.",
"q.tags" : "Здесь отображаются теги.",
"q.doListen" : "Здесь будет отображаться текст, который Вы напишите в поле «Слушай» в золотом чек-листе.",
"q.doWarning" : "Здесь будет отображаться текст, который Вы напишите в поле «Не делай» в золотом чек-листе.",
"q.doSeeLink" : "Ссылка, которую вы дадите для поля «Стоит увидеть» в  золотом чек-листе, будет здесь.",
"q.doTasteLink" : "Ссылка, которую вы дадите для поля «Попробуй» в золотом чек-листе, будет здесь.",
"q.doBuyLink" : "Ссылка, которую вы дадите для поля «Купи» в золотом чек-листе, будет здесь.",
"q.doPhotographingLink" : "Ссылка, которую вы дадите для поля «Сфотографируй» в золотом чек-листе, будет здесь.",
"q.doDoLink" : "Ссылка, которую вы дадите для поля «Сделай» в золотом чек-листе, будет здесь.",
"q.doReadLink" : "Ссылка, которую вы дадите для поля «Прочитай» в золотом чек-листе, будет здесь.",
"q.doListenLink" : "Ссылка, которую вы дадите для поля «Послушай» в золотом чек-листе, будет здесь.",
"q.doWarningLink" : "Ссылка, которую вы дадите для поля «Не делай» в золотом чек-листе, будет здесь.",
"q.media" : "https://api.heytripster.com/resources/images/122/d131f824-dbd3-4b8b-b76f-ec8949fc9e9d.jpg",
"q.media" : "https://api.heytripster.com/resources/images/122/d131f824-dbd3-4b8b-b76f-ec8949fc9e9d.jpg",
"q.thingsToKnow" : "Здесь отображается то, что Вы напишете для поля «Что нужно знать».",
"s.nameOfTheEvent" : "Пример названия бонусного мероприятия",
"s.shortDescription" : "Пример описания бонусного мероприятия.",
"s.locationName" : "Пример названия местоположения бонусного мероприятия",
"s.map" : "Пример ссылки на карту бонусного мероприятия",
"s.tags" : "Примеры тегов бонусных мероприятий",
"s.webSite" : "Пример сайта бонусного мероприятия",
"This is a free sample. You’ll see for ALL events like this" : "Это пример бесплатного мероприятия. Нажав кнопку «КУПИТЬ ПОЛНЫЙ ДОСТУП» Вы сможете увидеть ВСЕ занятия так же детально. До покупки {{EventCount}} занятий будут заблокированы.",
"Min Max People" : "Минимальное / максимальное количество человек",
"Start / End" : "Начало / Конец",
"Available Days" : "Доступные дни",
"Dress code" : "Дресс-код",
"Before Starting" : "Прежде, чем мы начнем",
"Hashtags suitable to the theme" : "Хештеги по теме",
"Rotanın tarihi ve turistik açıdan önemi" : "Историческое и туристическое значение маршрута",
"View this event detail" : "Смотрите подробности этого мероприятия",
"CopyRight Links" : "Источник изображения",
"Image sources" : "Источник изображения",
"PlanNameTitle" : "Название плана",
"HygieneRulesTitle" : "Правила гигиены",
"TransportationTitle" : "Транспорт",
"Istanbul" : "Стамбул",
"Nevsehir" : "Невшехир",
"Denizli" : "Денизли",
"Antalya" : "Анталия",
"Canakkale" : "Чанаккале",
"Izmir" : "Измир",
"Mugla" : "Мугла",
"Paris" : "Париж",
"Nice" : "Ницца",
"Marsilya" : "Марсель",
"Roma" : "Рим",
"Floransa" : "Флоренция",
"Venedik" : "Венеция",
"Milano" : "Милан",
"Napoli" : "Неаполь",
"Amsterdam" : "Амстердам",
"Rotterdam" : "Роттердам",
"Berlin" : "Берлин",
"Munih" : "Мюнхен",
"Frankfurt" : "Франкфурт",
"Hamburg" : "Гамбург",
"Barselona" : "Барселона",
"Madrid" : "Мадрид",
"Sevilla" : "Севилья",
"Granada" : "Гранада",
"Londra" : "Лондон",
"Manchester" : "Манчестер",
"Bursa" : "Бурса",
"monday" : "Понедельник",
"tuesday" : "Вторник",
"thursday" : "Четверг",
"saturday" : "Суббота",
"Tip Guide" : "Культура чаевых",
"Lütfen yeniden 'Preview' butonuna basın" : "Пожалуйста, нажмите кнопку 'Предварительный просмотр' еще раз.",
"bonus_event_default_image" : "https://api.heytripster.com/resources/images/376/0576dadc-6315-4f91-bbac-0808ceaceab2.jpg",
"visitedtext" : "{{inhabitText}} {{cityText}} {{inhabitCount}} {{inhabitCircleText}}.",
"Another" : "Другой",
"Friend" : "Друг",
"Get Full Access_10" : "Купить",
"Get Full Access_1" : "This trip plan is not up to date",
"Get Full Access_3" : "Not yet available for purchase",
"Get Full Access_4" : "Not yet available for purchase",
"Get Full Access_5" : "Not yet available for purchase",
"Price" : "Цена",
"Go to this event" : "Перейти к этому событию",
"Bonus Location" : "Достопримечатльность(-и,-ей), расположенная(-ые,ых) рядом- #{{count}}",
"Show Details" : "Показать детали",
"Alternative Event" : "Альтернативное мероприятие",
"PEOPLE" : "{{min}} - {{max}} человек",
"A day you will walk is awaiting you. You'd better wear sneakers." : "A day you will walk is awaiting you. You'd better wear sneakers.",
"The day will be long. If you take a snack with you?" : "The day will be long. You may want to take a snack with you.",
"Would you take a shawl with you for a visit to the mosque? (For women)" : "You should take a shawl with you for a visit to the mosque (For women)",
"Don't forget to make a reservation in advance for the restaurant you plan to visit." : "Don't forget to make a reservation in advance for the restaurant you plan to visit.",
"Don't forget to take coins with you." : "Don't forget to take coins with you.",
"In some places on the travel plan credit card does not pass." : "In some places on the travel plan, credit card does not pass.",
"Since there are museums on the route it will be more economical for you to buy tickets for a pass or multiple pass." : "Since there are museums on the route, it will be more economical for you to buy tickets for a pass or multiple pass.",
"* Come together with local producers and learn about the products from firsthand." : "Come together with local producers and learn about the products from firsthand.",
"* Your friends back at home will envy your photographs taken in great points." : "Your friends back at home will envy your photographs taken in great points.",
"* Explore the rural area and get lost in mother nature" : "Explore the rural area and get lost in mother nature",
"* See various art pieces closely" : "See various art pieces closely",
"* A great plan for couples live unforgettable moments together" : "A great plan for couples live unforgettable moments together",
"* Once in a lifetime experience - you should definitely tell your friends." : "Once in a lifetime experience - you should definitely tell your friends.",
"* A great day for children they will love every minute of it" : "A great day for children they will love every minute of it",
"* Take a stroll through the streets and learn better of the city" : "Take a stroll through the streets and learn better of the city",
"* This plan is suitable for every individual in your family spread the love!" : "This plan is suitable for every individual in your family spread the love!",
"* While riding your bicycle in the area experience the atmosphere." : "While riding your bicycle in the area experience the atmosphere.",
"* A venue recommendation for a tasty dinner at the end" : "A venue recommendation for a tasty dinner at the end",
"* Escape the noise and traffic of the city for a day" : "Escape the noise and traffic of the city for a day",
"* Includes a rich breakfast place to motivate you for the day" : "Includes a rich breakfast place to motivate you for the day",
"* A restaurant with a wide dinner menu to keep you well-fed and happy" : "A restaurant with a wide dinner menu to keep you well-fed and happy",
"* Lunch menu to boost your energy" : "Lunch menu to boost your energy",
"* Do not forget to bring your towel and swimsuit. You will enjoy the beach." : "Do not forget to bring your towel and swimsuit. You will enjoy the beach.",
"* It will be hot! You will need your sunglasses and sunscreen." : "It will be hot! You will need your sunglasses and sunscreen.",
"* Not everyone is your friend! There might be some uncanny people you will encounter on your route." : "Not everyone is your friend! There might be some uncanny people you will encounter on your route.",
"* Make sure your phone's battery is full. It can be hard to find a place to help you." : "Make sure your phone's battery is full. It can be hard to find a place to help you.",
"* This plan is designed for driving route. Do not forget to rent one if you do not have your own." : "This plan is designed for driving route. Do not forget to rent one if you do not have your own.",
"* This is a bicycle route. Do not forget to rent a bicycle if you do not have your own." : "This is a bicycle route. Do not forget to rent a bicycle if you do not have your own.",
"* Fill your backpack with snacks and beverages. You will need lots of energy throughout the day." : "Fill your backpack with snacks and beverages. You will need lots of energy throughout the day.",
"Media Link related to the Event" : "Медиа-ссылка, связанная с этим событием",
"Event name" : "Название мероприятия",
"Hediyelik Eşya" : "Souvenir",
"Yürüyüş" : "Walking",
"Şarap" : "Wine",
"Tadım" : "Tasting",
"Çarşı / Pazar" : "Bazaar",
"Tarihi Yapı" : "Historical Building",
"Saray" : "Palace",
"Vejetaryen" : "Vegetarian",
"Kule" : "Tower",
"Antik Kent" : "Ancient City",
"Kermes" : "Kermis",
"Yüzme" : "Swimming",
"404 NotFound" : "План, который вы ищете, не найден",
"Plan NotFound" : "Страница, которую вы ищете, не найден",
 }
  };
  
  export default ru;
  