import React from 'react';
import PropTypes from 'prop-types';
import './HeaderDark.scss';
import logo from './../../assets/images/logo@3x.png';
import comment from './../../assets/icons/comment-w.svg';
import { translate, Trans } from "react-i18next";

const HeaderDark = (props) => (
  <div className="HeaderDark">
       <div className="container d-flex justify-content-between align-items-center">
    	<img className="logo" src={props.t("MainLogo2")}/>
    	<div className="side">
        {
          props.t("VisibleAreaLiveSupport") !== "VisibleAreaLiveSupport" && props.t("VisibleAreaLiveSupport") === "True" &&
          <a href={props.t("MainLiveSupport")} target="_blank" rel="nofollow noreferrer noopener"><button className="btn-o-w"><img className="comment" src={comment}/> {props.t("Live Support")} </button></a>
        }
        
    	</div>
    </div>
  </div>
);

HeaderDark.propTypes = {};

HeaderDark.defaultProps = {};

export default translate("translations")(HeaderDark);
