import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './FaqItem.scss';


export default function  FaqItem(t, faq, id, expanded, setExpanded) {
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }


  return(

    	<Accordion key={id} expanded={expanded === `panel${id}`} onChange={handleChange(`panel${id}`)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel_${id}a-content`}
          id={`panel_${id}a-header`}
        >
          {t(`faqname${id}`)}
        </AccordionSummary> 
        <AccordionDetails>
          {t(`faqdetail${id}`)}
        </AccordionDetails>
      </Accordion>
)};
