import React, {Component} from 'react';
import './CheckoutForm.scss';
import { translate } from 'react-i18next';

import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';


const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        fontFamily: 'sans-serif',
        fontWeight:'300',
        color: '#1b2d41',
        '::placeholder': {
          color: '#b2b0b0',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};

class _CheckoutForm extends Component {
  state = {
    errorMessage: '',
    validations:{cardNumber:false,cardHolder:false,cardCvc:false,cardExpiry:false}
  };
  constructor(props) {
    super(props);
  }

  onChangeCardInfo(event) {

          var validations = {...this.state.validations};
          const errorMessage = event.error ?  event.error.message : "";
          
          if (event.error) {
            validations[event.elementType] = false;
          }else if(event.complete){
            validations[event.elementType] = true;
          }

          this.setState({errorMessage: errorMessage,validations: validations },() => this.props.handleChange(this.state.validations));

  }

  onChangeName(event) {
      const valid = event.target.value ? true:false;
      this.setState({errorMessage: valid ? "":"Please fill in name on card.",validations: {...this.state.validations, cardHolder:valid}},() => this.props.handleChange(this.state.validations));
  }



  handleSubmit = (evt) => {
    evt.preventDefault();
    this.setState({errorMessage:""});
    this.props.handleDisable();
    if (this.props.stripe) {
      this.props.stripe.createToken().then(this.props.handleResult);
    } else {
      this.props.handleDisable();
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    return (
      <div className="CheckoutForm">
      <form id='my-form' onSubmit={this.handleSubmit.bind(this)}  >

       <div className="form-item">
            <input
              name="name"
              type="text"
              placeholder={this.props.t("Name on Card")}
              className="StripeElement"
              required
              onChange={this.onChangeName.bind(this)}
            />
          </div>
          <div className="form-item">
            <CardNumberElement
                placeholder={this.props.t("Card Number")}
              {...createOptions()}
              onChange={this.onChangeCardInfo.bind(this)}
            />
          </div>
          
        <div className="form-inline d-flex justify-content-between">
          <div className="form-item inline-item">
            <CardExpiryElement
              {...createOptions()}
               onChange={this.onChangeCardInfo.bind(this)}
            />
          </div>
          <div className="form-item inline-item">
            <CardCVCElement {...createOptions()} onChange={this.onChangeCardInfo.bind(this)} />
          </div>
        </div>
        <div className="error" role="alert">
          {this.state.errorMessage}
        </div>
      </form>
      </div>
    );
  }
}

const CheckoutForm = injectStripe(_CheckoutForm);

export default translate("translations")(CheckoutForm);
