import React from 'react';
import PropTypes from 'prop-types';
import './EventSocial.scss';
import { translate } from 'react-i18next';


import spotify from './../../../../assets/icons/spotify.svg';
import youtube from './../../../../assets/icons/youtube.svg';

const EventSocial = (props) => (
  <div className="EventSocial d-flex align-items-center">
   		{props.medias.map((media, index) => 
		  	<div key={index}  className="youtube social-link d-flex align-items-center">
		  		<img src={youtube}/>
		  		<a href={media} target="_blank" rel="nofollow noreferrer noopener">{props.t("Watch a video related to this event.")}</a>
		  	</div>
	  	)}
  </div>
);

EventSocial.propTypes = {};

EventSocial.defaultProps = {
	medias:[]
};

export default translate("translations")(EventSocial);
