import React, { Component } from 'react';
import PropTypes from 'prop-types';
//import './../../components/EventDetail/EventDetail.scss';
import './AltEventDetail.scss';

import { translate } from 'react-i18next';

import EventPreview from './../../components/EventDetail/components/EventPreview/EventPreview';
import EventCheckList from './../../components/EventDetail/components/EventCheckList/EventCheckList';
import EventSocial from './../../components/EventDetail/components/EventSocial/EventSocial';
import EventAbout from './../../components/EventDetail/components/EventAbout/EventAbout';
import EventLocation from './../../components/EventDetail/components/EventLocation/EventLocation';
import EventKeyInfo from './../../components/EventDetail/components/EventKeyInfo/EventKeyInfo';

import EventDesigner from './../../components/EventDetail/components/EventDesigner/EventDesigner';
import EventActions from './../../components/EventDetail/components/EventActions/EventActions';
import EventPlan from './../../components/EventDetail/components/EventPlan/EventPlan';
import {firstName,calcPrice} from "./../../assets/Helpers.js";
import { Translation } from 'react-i18next';
import PlanMapBox from './../../components/PlanDetail/components/PlanMapBox/PlanMapBox'
import medialink from './../../assets/icons/media-link.svg';
import PlanActions from './../../components/PlanDetail/components/PlanActions/PlanActions'

class AltEventDetail extends Component {

    state = {
        selectedEventIndex: this.props.eventIndex,
        selectedEventData:this.props.event,
        events:this.props.events,
        image:null
    }

    eventSelectedHandler = (id,index) => {
        /*this.setState({selectedEventIndex: index});
        this.state.events.filter(item => !item.cardId).sort(((a, b) => a.startTime < b.startTime ? -1 : 1)).map((event, i) => {
          if(event.starEvent)
          {
            this.setState({selectedEventData: this.state.events.filter(item => item.starEvent == true)[0], selectedEventIndex: i});
          }
        }
      )*/
    }

    componentDidMount () {
         //this.eventSelectedHandler(this.state.events[0].id,0);
         var alt = {...this.props.event};



         var img = alt.images && alt.images.split(";").filter(x => x).length > 0 ? alt.images.split(";").filter(x => x)[0]:null;
        if(img == null)
        {
          img = "https://api.heytripster.com/resources/images/376/0576dadc-6315-4f91-bbac-0808ceaceab2.jpg";
        }

         console.log(alt.images.split(";").filter(x => x)[0]);
         if(img){
           this.setState({image: img});

         }
         alt.images="";
         this.setState({selectedEventIndex: 1,selectedEventData: alt});


    }

      componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
        console.log("componentWillReceiveProps",nextProps);

            var alt ={...nextProps.event} ;
         alt.images="";
         this.setState({selectedEventIndex: 1,selectedEventData: alt});

    }

     render () {
         const { t, plan } = this.props;
      return(
      <div className="AltEventDetail">
        <div className="container d-flex flex-wrap justify-content-between">
        {this.state.image ? <div className="event-main-image"  style={{ backgroundImage:  "url(" + this.state.image + ")" }}></div> : null}

        {
          this.state.selectedEventData ?
        	<div className="main">
        		<EventPreview {...this.state.selectedEventData} etype={this.props.etype}  solted={this.props.solted} eventIndex={this.state.selectedEventIndex} designerName={firstName(this.props.designer.fullName)} plan={this.props.plan}/>
        		<EventAbout {...this.state.selectedEventData} etype={this.props.etype} solted={this.props.solted}  />
              <p className="new-desc">{this.state.selectedEventData.shortDescription}</p>

        		{this.state.selectedEventData.lat && this.state.selectedEventData.lng ? <EventLocation {...this.state.selectedEventData}/> :null}
            <EventKeyInfo {...this.state.selectedEventData} events={this.props.events}/>
             {this.state.selectedEventData.media?
              <div className="media-link">
                <a href={this.state.selectedEventData.media} target="_blank" rel="nofollow noreferrer noopener">{this.props.t("Media Link related to the Event")} <img src={medialink}/></a>
              </div>
            :null
            }

            <EventCheckList {...this.state.selectedEventData} />
             

            {this.state.selectedEventData && this.state.selectedEventData.medias && this.state.selectedEventData.medias.length > 0 ? <EventSocial medias={this.state.selectedEventData.medias}/> :null}

        	</div>
          : null 
        }
        	{/*<div className="sidebar">
                <div className="c-wrap">
    	    	    <EventDesigner {...this.props.designer}/>

    	    	    <EventActions 
                    fullAccess={() =>  this.props.onFullAccess()} 
                    browse={() =>  this.props.onBrowse()}
                    currency={this.props.plan.priceCurrency}
                    price={this.props.plan.discountedPrice.toFixed(2)}
                    />
                </div>
                  <div className="event-plan-container">
                    <div className="event-plan-wrap" >
                        <h2 className="title">{t("name's Plan", {name : firstName(this.props.designer.fullName)})}</h2>
                        <div className="plan-items">

                        {this.state.events.filter(item => !item.cardId).sort(((a, b) => a.startTime < b.startTime ? -1 : 1)).map((evitem, index) => {
                                return <EventPlan key={"ei-"+index} indexs={index} event={evitem} 
                                selected={this.state.selectedEventData && this.state.selectedEventData.id == evitem.id ? true:false} 
                                clicked={() => this.eventSelectedHandler(evitem.id,index)} />
                              })}
                        </div>

                          { this.props.plan && this.props.plan.cards && this.props.plan.cards.filter(item=> item.lat && item.lng ).length > 0 ?  
                              <PlanMapBox mapOpen={() => this.props.onMapOpen()} locations={this.props.plan.cards.filter(item=> item.lat && item.lng ).reduce((locs, item) =>locs.concat([{lat:item.lat,lng:item.lng}]),[])} /> 
                              : null
                          }

                        <div className="full-access">
                        <p>{t("Fully access to this plan for (one time).  %100 satisfaction guaranteed!",{priceCurrency:t(this.props.plan.priceCurrency), discountedPrice: this.props.plan.discountedPrice.toFixed(2)})}</p>
                        <button className="btn btn-w" onClick={() => this.props.onFullAccess()} >{t("Get Full Access")}</button>
                        </div>
                     </div>
                  </div>
                <div className="join-event">
                <p dangerouslySetInnerHTML={{__html: t("tripDesigner-join-event", {firstname : firstName(this.props.designer.fullName)})}} />
                </div>
        	</div>*/}
        </div>
        {!this.props.solted &&
                        <PlanActions  
                        fullAccess={() => this.props.onFullAccess()}
                        currency={this.props.plan.priceCurrency}
			      	          status={this.props.plan.status}
                          price={this.props.plan.price,this.props.plan.price.toFixed(2)}
                          discountedPrice={this.props.plan.price,this.props.plan.discountedPrice.toFixed(2)} />
                         
                        }
         
         
      </div>
    );}
}

export default translate("translations")(AltEventDetail);
