import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import './PlanReviews.scss';
import Slider from "react-slick";
import Review from './components/Review/Review'
import { translate } from 'react-i18next';

import prevImg from './../../assets/icons/slider-left.svg';
import nextImg from './../../assets/icons/slider-right.svg';

const settings = {
  dots: false,
  arrows:false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  centerMode: true,
  centerPadding:"0px",
  responsive: [
          { breakpoint: 1170,settings: { slidesToShow: 2, slidesToScroll: 2, arrows: false, dots: true }},
          { breakpoint: 720,settings: { slidesToShow: 1, slidesToScroll: 1, arrows: false, dots: true }}
        ]
};

class PlanReviews extends Component{ 
  state = {
    reviews:[]
  };

   constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  componentDidMount () {
      axios.get( 'Users/getReviews/'+this.props.designerId )
          .then( response => {
              this.setState({reviews: response.data});
   
          } );
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render(){
    
    return(
      <div>
           {this.state.reviews.length >0 ? 

      <div className="PlanReviews">
            <div className="container">

       <div className="d-flex flex-wrap justify-content-between align-items-center revtitle">
       <h2 className="section-title">{this.props.t("Travellers Reviews")}</h2>

  
      <div className="d-flex flex-nowrap justify-content-start align-items-center">
         <p className="sorted">{this.props.t("Sorted by Relevancy")}</p>
         <button className="sliderBtn" onClick={this.previous}><img className="" src={prevImg}/></button>
         <button className="sliderBtn" onClick={this.next}><img className="" src={nextImg}/></button>
     </div>
     </div>
     

     	<div className="slider-container">
            <Slider ref={c => (this.slider = c)} {...settings}>
             { this.state.reviews.map((review, i) => <Review key={i} {...review} />) }
            </Slider>
        </div>

      </div>
     </div>
      :null}
      </div>
    )};
}

PlanReviews.propTypes = {};
PlanReviews.defaultProps = {};

export default translate("translations")(PlanReviews);