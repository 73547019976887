import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios';

export class Host extends Component {
    componentDidMount() {
        axios.get(`/getHost/${this.props.match.params.test}`).then(response => {
           console.log(response.data)
        });
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Host)
