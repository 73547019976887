import React from 'react';

class CityImages extends React.Component{ 

  state={
    imagesSize:8,
    isMobile:false

  }

  showAll(){
    this.setState({imagesSize:this.props.images.length});
  }

  componentDidMount() {
      window.addEventListener("resize", this.resize.bind(this));
      this.resize();
  }

  resize() {
    
      this.setState({isMobile: window.innerWidth <= 1170});
  }

  componentWillUnmount() {
      window.removeEventListener("resize", this.resize.bind(this));
  }

   render(){

return(
  <div className="CityImages">
              <div className="images-container">
              <div className="images-wrap d-flex justify-content-start flex-wrap">
                {this.props.images.slice(0, this.state.isMobile ? this.props.images.length:this.state.imagesSize).map((im, index) => <div key={"im"+index} className="bgImg grid-img" style={{ backgroundImage:  "url(/images/cities/" + im.split("/")[im.split("/").length-1].toLowerCase() + ")" }} ></div>)}
              </div>
            </div>
            { 
               this.props.images.length > this.state.imagesSize && !this.state.isMobile?
                <div className="show-more-wrap">
                  <button className="btn-o-d sm-btn" onClick={() => this.showAll()} >Show More</button>
                </div>
              :null}
  </div>

)};

}

CityImages.defaultProps = {
  images:[]
};

export default CityImages;