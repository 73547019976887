import React, { Component } from 'react';
import axios from 'axios';
import './Plan.scss';
//import Modal from "@material-ui/core/Modal";

import * as acTypes from '../../store/actions';
import { connect } from 'react-redux';
import compose from 'compose-function';
import PlanDetail from './../../components/PlanDetail/PlanDetail';
import PlanReviews from './../../components/PlanReviews/PlanReviews';
import PlanExplore from './../../components/PlanExplore/PlanExplore';
import EveryPlan from './../../components/EveryPlan/EveryPlan';
import EventDetail from './../../components/EventDetail/EventDetail';
import DesignerDetail from './../../components/DesignerDetail/DesignerDetail';
import DesignerImageFeed from './../../components/DesignerImageFeed/DesignerImageFeed';
import DesignerInstagram from './../../components/DesignerInstagram/DesignerInstagram';
import PlanHeatMap from './../../components/PlanHeatMap/PlanHeatMap';
import PlantTreeInfo from './../../components/PlantTreeInfo/PlantTreeInfo';
import Faq from './../../components/Faq/Faq';
import RelatedArticles from './../../components/RelatedArticles/RelatedArticles';
import FooterDark from './../../components/FooterDark/FooterDark';
import HeaderDark from './../../components/HeaderDark/HeaderDark';
import BreadCrumb from './../../components/BreadCrumb/BreadCrumb';
import { firstName, lastName } from './../../assets/Helpers.js'
import DateSelectPopup from './../../components/DateSelectPopup/DateSelectPopup';
import DateSelectBrowse from './../../components/DateSelectBrowse/DateSelectBrowse';
import Metas from './../../components/Metas/Metas';
import { translate, Trans } from "react-i18next";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Connector from 'activecampaign_v3';
import moment from 'moment'
import { Controller, Scene } from 'react-scrollmagic';
import ReactDOM from "react-dom";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import PlanLocations from './../../components/PlanLocations/PlanLocations';

const actId = "224635093";
const eventKey = "df158451e05eb31744a99d59251513283a91b9cc";
const apiKey = "";
const apiUrl = "";
const ActiveCamp = new Connector(actId, eventKey, apiKey, apiUrl);



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class Plan extends Component {
    state = {
        planData: null,
        selectedEvent: null,
        selectedEventIndex: null,
        datePopupOpen: false,
        mapOpen: false,
        showDesignerPopup: true,
        showFirst: false,
        isFullAccess: false,
        print:false,
        isAltEventShow: false,
        offsetTop: 0,
        isMobile: false,
        solted:false,
        amountDiscount:0,
        percentDiscount:0
    }

    targetElement = null;

    constructor(props) {
        super(props)
        this.eventDetailRef = React.createRef()
    }



    changeLanguage = (lng) => {
        this.props.i18n.changeLanguage(lng);
        if (lng != "en") {
            require(`moment/locale/${lng}`);
        }
    };

    resize() {

        this.setState({ isMobile: window.innerWidth <= 1024 });
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        const qs = new URLSearchParams(this.props.location.search);
        const couponCode = qs.get('couponCode');
        var planApi = `Order/getPlan/${this.props.match.params.id}/${this.props.match.params.lang === "tr" ? "0" :this.props.match.params.lang === "ru" ? "2":"1"}`

        if (couponCode) {
            this.props.onCouponCode(couponCode);
        }
        if(this.props.couponCode !== "")
        {
            axios.get(`Order/getCouponCode/${this.props.couponCode}`)
            .then(response => {
                this.setState(
                    {
                        amountDiscount : response.data.amount_off === null ? 0 : response.data.amount_off,
                        percentDiscount : response.data.percent_off === null ? 0 : response.data.percent_off
                    });
                
                console.log(response.data);
            });
            console.log("couponCode");
            console.log(this.props.couponCode);
        }

        var pageParams = this.props.match.url.split("/");
        if (pageParams.length >= 3) {
            if (pageParams[2] == "my-plan") {
                this.setState({ isFullAccess: true });
                planApi = `SourceTd/getPlan/${this.props.match.params.id}/${this.props.match.params.key}/${this.props.match.params.userId}`
            }
            else if (pageParams[2] == "soldplan") {
                this.setState({ isFullAccess: true, solted:true, print: pageParams[5] != undefined });
                planApi = `Order/getPlan/${this.props.match.params.id}/${this.props.match.params.lang === "tr" ? "0" :this.props.match.params.lang === "ru" ? "2":"1"}/${this.props.match.params.key}`
            }
        }

        var node = ReactDOM.findDOMNode(this);

        this.targetElement = node;

        axios.get(planApi)
            .then(response => {
                console.log("response.data");
                console.log(response.data);
                if(!this.state.solted && this.props.t("handleOpenDatePopupTimer") > 0 )
                {
                    setTimeout(() => { this.handleOpenDatePopup(true) }, this.props.t("handleOpenDatePopupTimer"));
                }
                
                
                if (response.data.id == 0) {
                    this.changeCity(response.data.eventCity);
                    this.redirecToPath(`/${this.props.match.params.lang}/browse`);
                }
                
                var data = response.data;
                if (pageParams.length >= 3) {
                    if (pageParams[2] == "my-plan") {
                        if (data.themeOfTheDay == null) data.themeOfTheDay = this.props.t("data.themeOfTheDay");
                        if (data.shortDescription == null) data.shortDescription = this.props.t("data.shortDescription");
                        if (data.difficulty == null) data.difficulty = this.props.t("data.difficulty");
                        if (data.effort == null) data.effort = this.props.t("data.effort");
                        if (data.walking == null) data.walking = this.props.t("data.walking");
                        if (data.planAvaregePrice == null) data.planAvaregePrice = this.props.t("data.planAvaregePrice");
                        if (data.planCurrency == null) data.planCurrency = this.props.t("data.planCurrency");
                        if (data.themeImage == null) data.themeImage = "https://api.heytripster.com/resources/images/122/b5ef289e-074a-4a42-a536-d20f97ee813e.jpg";
                        if (data.themeImages.length == 0) data.themeImages.push("https://api.heytripster.com/resources/images/122/b5ef289e-074a-4a42-a536-d20f97ee813e.jpg");
                        if (data.themeThumbnail == null) data.themeThumbnail = this.props.t("data.themeThumbnail");
                        if (data.themeThumbnails != null && data.themeThumbnails.length == 0) data.themeThumbnails.push("data.themeThumbnails");
                        data.cards = data.cards.map((q, i) => {
                            if (data.cards.filter(x => x.starEvent)[0] == undefined && i == 0) {
                                q.starEvent = true;
                            }
                            if (q.nameOfTheEvent == null) q.nameOfTheEvent = this.props.t("q.nameOfTheEvent");
                            if (q.reason == null) q.reason = this.props.t("q.reason");
                            if (q.importance == null) q.importance = this.props.t("q.importance");
                            if (q.historyOfTheEvent == null) q.historyOfTheEvent = this.props.t("q.historyOfTheEvent");
                            if (q.availability == null) q.availability = this.props.t("q.availability");
                            if (q.shortDescription == null) q.shortDescription = this.props.t("q.shortDescription");
                            if (q.images == null) q.images = this.props.t("q.images");
                            if (q.locationName == null) q.locationName = this.props.t("q.locationName");
                            if (q.openHours == null) q.openHours = this.props.t("q.openHours");
                            if (q.tipGuide == null) q.tipGuide = this.props.t("q.tipGuide");
                            if (q.webSite == null) q.webSite = this.props.t("q.webSite");
                            if (q.instagram == null) q.instagram = this.props.t("q.instagram");
                            if (q.hygieneRules == null) q.hygieneRules = this.props.t("q.hygieneRules");
                            if (q.doSee == null) q.doSee = this.props.t("q.doSee");
                            if (q.doTaste == null) q.doTaste = this.props.t("q.doTaste");
                            if (q.doBuy == null) q.doBuy = this.props.t("q.doBuy");
                            if (q.doPhotographing == null) q.doPhotographing = this.props.t("q.doPhotographing");
                            if (q.doDo == null) q.doDo = this.props.t("q.doDo");
                            if (q.doRead == null) q.doRead = this.props.t("q.doRead");
                            if (q.tags == null) q.tags = this.props.t("q.tags");
                            if (q.doListen == null) q.doListen = this.props.t("q.doListen");
                            if (q.doWarning == null) q.doWarning = this.props.t("q.doWarning");
                            if (q.doSeeLink == null) q.doSeeLink = this.props.t("q.doSeeLink");
                            if (q.doTasteLink == null) q.doTasteLink = this.props.t("q.doTasteLink");
                            if (q.doBuyLink == null) q.doBuyLink = this.props.t("q.doBuyLink");
                            if (q.doPhotographingLink == null) q.doPhotographingLink = this.props.t("q.doPhotographingLink");
                            if (q.doDoLink == null) q.doDoLink = this.props.t("q.doDoLink");
                            if (q.doReadLink == null) q.doReadLink = this.props.t("q.doReadLink");
                            if (q.doListenLink == null) q.doListenLink = this.props.t("q.doListenLink");
                            if (q.doWarningLink == null) q.doWarningLink = this.props.t("q.doWarningLink");
                            if (q.media == null) q.media = this.props.t("q.media");
                            if (q.medias.length == 0) q.medias.push("q.media");
                            if (q.thingsToKnow == null) q.thingsToKnow = this.props.t("q.thingsToKnow");
                            q.spots = q.spots.map(s => {
                                if (s.nameOfTheEvent == null) s.nameOfTheEvent = this.props.t("s.nameOfTheEvent");
                                if (s.shortDescription == null) s.shortDescription = this.props.t("s.shortDescription");
                                if (s.locationName == null) s.locationName = this.props.t("s.locationName");
                                if (s.map == null) s.map = this.props.t("s.map");
                                if (s.tags == null) s.tags = this.props.t("s.tags");
                                if (s.webSite == null) s.webSite = this.props.t("s.webSite");
                                return s;
                            });
                            return q;
                        });
                    }
                    else if(pageParams[2] == "soldplan")
                    {
                        
                    }
                }
                this.changeCity(response.data.eventCity);
                if (pageParams[2] == "my-plan") {
                    this.changeLanguage(pageParams[1]);
                }
                else if (pageParams[2] == "soldplan") {
                    this.changeLanguage(pageParams[1]);
                }
                else
                {
                    if(this.props.match.params.url != response.data.planUrl)
                    {
                        this.redirecToPath(`/${this.props.match.params.lang}/plan/${response.data.uniqId}/${response.data.planUrl}`);
                    }
                    this.changeLanguage(response.data.languageString);
                }
                this.setState({ planData: data });
                this.selectEvent(0);

            }).catch(error => {
                if (pageParams[2] == "my-plan") {
                    if (window.confirm(this.props.t("Lütfen yeniden 'Preview' butonuna basın"))) {
                        window.opener = null;
                        window.open('', '_self');
                        window.close();
                    }
                    else { }

                }
                else if (error.response != null && error.response.data === "UniqId is incorrect") {
                    this.props.history.push("/planNotFound");
                }
                else
                {
                    this.props.history.push("/NotFound");
                }
                //console.log(error.response.data);
            });

        // if(this.props.firstOpenning === false)
        // {
        //     this.setState({ showDesignerPopup: false, datePopupOpen: true, showFirst:true });
        //     this.props.onFirstOpen(true);
        // }


        setTimeout(() => {
            if (this.props.email != undefined && this.props.email != null && this.props.email != "") {
                new Connector(this.props.t("ActiveCamiaignActid"), this.props.t("ActiveCamiaignActKey"), apiKey, apiUrl).trackSite("", { url: encodeURIComponent(window.location.href), referrer: encodeURIComponent(window.location.href) }, this.props.email);
            }}, 5000);
        
    }

    changeCity = (city) => {
        console.log("city");
        console.log(parseInt(city, 10));
        this.props.onCity(parseInt(city, 10));
    }


    selectEvent = (ind) => {
        this.setState({ selectedEventIndex: ind });
    }

    handleOpenDatePopup = (showDesigner = true) => {
        console.log("handleOpenDatePopupCloser");
        console.log(this.props.t("handleOpenDatePopupCloser"));
        this.setState({ showDesignerPopup: showDesigner, datePopupOpen: true, showFirst: false });
    }

    handleCloseDatePopup = () => {
        this.setState({ datePopupOpen: false });
    };

    handleCloseDatePopup = () => {
        this.setState({ datePopupOpen: false });
    };

    handleClickOpen = () => {
        if(this.state.solted){
            this.setState({ mapOpen: true });
            // var link = "https://www.google.com/maps/dir";
            // this.state.planData.cards.sort(((a, b) => a.startTime < b.startTime ? -1 : 1)).filter(x => x.cardId == null).map((q, i) =>  {
            //     link = `${link}/${q.lat},${q.lng}`;
            // });
            // link = `${link}/am=t/data=!4m2!4m1!3e2`;
            // window.open(link, "_blank")
        }
        else{
            this.setState({ mapOpen: true });
        }
    };

    handleClickOpen2 = () => {
        if(this.state.solted){
            var link = "https://www.google.com/maps/dir";
            this.state.planData.cards.sort(((a, b) => a.startTime < b.startTime ? -1 : 1)).filter(x => x.cardId == null).map((q, i) =>  {
                link = `${link}/${q.lat},${q.lng}`;
            });
            window.open(link, "_blank")
        }
        else{
            this.setState({ mapOpen: true });
        }
    };

    handleCloseMap = () => {
        this.setState({ mapOpen: false });
    };

    handleAltEventToggle = (status) => {

        if (status == true) {
            disableBodyScroll(this.targetElement);
        } else {
            enableBodyScroll(this.targetElement);
        }

        this.setState({ isAltEventShow: status });
    };

    redirecToPath = (path) => {
        this.props.history.push(path);
    };

    scrolltoEventDetail = () => { console.log("scrolltoEventDetail", this.eventDetailRef); window.scrollTo({ top: this.eventDetailRef.current.offsetTop, behavior: 'smooth' }); };

    onSelectEventAndScroll = (id) => {
        console.log("onSelectEventAndScroll", id);
        this.setState({ selectedEventIndex: id });
        window.scrollTo({ top: this.eventDetailRef.current.offsetTop, behavior: 'smooth' });
    };

    componentWillUnmount() {
        clearAllBodyScrollLocks();
    }

    handleEventChange() {
        window.scrollTo({ top: this.eventDetailRef.current.offsetTop, behavior: 'smooth' });
    }

    render() {
        const { t } = this.props;
        return (
            <div className="Plan" >
                <Controller>
                    {!this.state.isFullAccess && this.state.planData &&
                        <Metas
                        title = {this.state.planData.planTitle}
                        url = {window.location.href}
                        description= {this.state.planData.planDescription}
                        image = {this.state.planData.themeImages[0]}
                        concierge = {this.state.planData.planType !== 0}
                        />
                    }
                    <div className="d-flex flex-column" style={{ minHeight: "100%" }}>
                        <div className="flex-shrink-0 flex-grow-0">
                            <HeaderDark />

                        </div>
                        <div className="flex-shrink-0 flex-grow-1">

                            {
                                this.state.planData ?
                                    <div>
                                        <BreadCrumb items={[t("Trip Designer"), firstName(this.state.planData.tridDesigner.fullName) + " " + lastName(this.state.planData.tridDesigner.fullName), this.state.planData.themeOfTheDay]} />
                                        <PlanDetail 
                                        solted={this.state.solted}
                                        amountDiscount={this.state.amountDiscount}
                                        percentDiscount={this.state.percentDiscount}
                                        isFullAccess={this.state.isFullAccess} plan={this.state.planData} onFullAccess={() => this.handleOpenDatePopup()} onScrollToEvent={() => this.scrolltoEventDetail()} onSelectEvent={(id) => this.onSelectEventAndScroll(id)} onMapOpen={() => this.handleClickOpen()} onMapOpen2={() => this.handleClickOpen2()} />
                                    </div>
                                    : null
                            }



                            {this.state.planData && this.state.planData.cards ?
                                <div ref={this.eventDetailRef}>
                                    <Scene duration={"100%"} pin="#sticky-sidebar" triggerElement="#sticky-trigger" triggerHook={0} indicators={false}>
                                        <EventDetail
                                            solted={this.state.solted}
                                            print={this.state.print}
                                            isFullAccess={this.state.isFullAccess}
                                            onMapOpen={() => this.handleClickOpen()}
                                            onMapOpen2={() => this.handleClickOpen2()}
                                            events={this.state.planData.cards}
                                            eventIndex={this.state.selectedEventIndex}
                                            designer={this.state.planData.tridDesigner}
                                            plan={this.state.planData}
                                            onSelectEventAndScroll={(id) => this.onSelectEventAndScroll(id)}
                                            onFullAccess={() => this.handleOpenDatePopup()}
                                            onBrowse={() => this.handleOpenDatePopup(false)}
                                            onAltEventToggle={(status) => this.handleAltEventToggle(status)}
                                        />
                                    </Scene>
                                </div>
                                : null}


                            {this.props.t("VisibleAreaIncludeWith") !== "VisibleAreaIncludeWith" && this.props.t("VisibleAreaIncludeWith") === "True" && this.state.planData ? <EveryPlan onFullAccess={() => this.handleOpenDatePopup(false)} /> : null}


                            {this.props.t("VisibleAreaPlanExplore") !== "VisibleAreaPlanExplore" && this.props.t("VisibleAreaPlanExplore") === "True" && this.state.planData && !this.state.solted ? <PlanExplore planId={this.state.planData.uniqId} isFullAccess={this.state.isFullAccess} /> : null}



                            {this.props.t("VisibleAreaPlantTreeInfo") !== "VisibleAreaPlantTreeInfo" && this.props.t("VisibleAreaPlantTreeInfo") === "True" && !this.state.print && this.state.planData ? <PlantTreeInfo /> : null}



                            {this.props.t("VisibleAreaDesignerDetail") !== "VisibleAreaDesignerDetail" && this.props.t("VisibleAreaDesignerDetail") === "True" && !this.state.print && this.state.planData ? <DesignerDetail eventCityId={this.state.planData.eventCity} planId={this.state.planData.uniqId} designer={this.state.planData.tridDesigner} cities={this.state.planData.tridDesignerCities} /> : null}


                            {this.props.t("VisibleAreaPlanReviews") !== "VisibleAreaPlanReviews" && this.props.t("VisibleAreaPlanReviews") === "True" && !this.state.print && 
                                this.state.planData ?
                                    <div>
                                        <PlanReviews designerId={this.state.planData.tridDesigner.id} />
                                    </div>
                                    : null
                            }

                            {/*this.state.planData ? <DesignerImageFeed /> :null*/}

                            {this.props.t("VisibleAreaDateSelectBrowse") !== "VisibleAreaDateSelectBrowse" && this.props.t("VisibleAreaDateSelectBrowse") === "True" && !this.state.print && this.state.planData ?
                                <DateSelectBrowse
                                    redirect={(to) => this.redirecToPath(to)}
                                    isFullAccess={this.state.isFullAccess}
                                    eventCity={
                                        this.state.planData.tridDesignerCities.filter(ct => ct.city == this.state.planData.eventCity).length > 0 ?
                                            this.state.planData.tridDesignerCities.filter(ct => ct.city == this.state.planData.eventCity)[0] : {}
                                    } /> : null
                            }

                            {this.props.t("VisibleAreaFaq") !== "VisibleAreaFaq" && this.props.t("VisibleAreaFaq") === "True" && !this.state.print && this.state.planData ? <Faq /> : null}
                            {!this.state.print && this.state.planData ? <RelatedArticles designerId={this.state.planData.tridDesigner.id} /> : null}

                        </div>
                        <div className="flex-shrink-0 flex-grow-0">
                            <FooterDark />
                        </div>
                        {this.state.planData ? <div className="mobile-actions-area"> </div> : null}

                    </div>


                </Controller>


                <Dialog fullScreen open={this.state.mapOpen} onClose={this.handleCloseMap} TransitionComponent={Transition}>

                    <IconButton edge="start" color="inherit" onClick={this.handleCloseMap} className="mpCLose">
                        <CloseIcon />
                    </IconButton>

                    {!this.state.solted && this.state.planData && this.state.planData.cards && this.state.planData.cards.filter(item => item.lat && item.lng).length > 0 ?
                        <PlanHeatMap solted={this.state.solted} locations={this.state.planData.cards.filter(item => item.lat && item.lng).reduce((locs, item) => locs.concat([{ lat: item.lat, lng: item.lng }]), [])} />
                        : null
                    }

                    {this.state.solted && this.state.planData && this.state.planData.cards && this.state.planData.cards.filter(item => item.lat && item.lng).length > 0 ?
                        <PlanLocations solted={this.state.solted} locations={this.state.planData.cards.filter(item => item.lat && item.lng).reduce((locs, item) => locs.concat([{ lat: item.lat, lng: item.lng, show:false, id:item.id, name:item.nameOfTheEvent, location:item.locationName, map:item.mapsLink }]), [])} />
                        : null
                    }

                </Dialog>

                {  /*<Modal open={this.state.datePopupOpen} onClose={this.handleCloseDatePopup} className="modal-center" >
                        <div className="datepopup-wrap"><DateSelectPopup redirect={(to) => this.redirecToPath(to)} /></div>
                    </Modal>*/}

                {this.state.datePopupOpen && this.state.planData ? <DateSelectPopup
                    onClose={() => this.handleCloseDatePopup()}
                    redirect={(to) => this.redirecToPath(to)}
                    designerName={firstName(this.state.planData.tridDesigner.fullName)}
                    designerImage={this.state.planData.tridDesigner.image}
                    showDesigner={this.state.showDesignerPopup}
                    showFirst={this.state.showFirst}
                    planId={this.state.planData.uniqId}
                    eventCity={
                        this.state.planData.tridDesignerCities.filter(ct => ct.city == this.state.planData.eventCity).length > 0 ?
                            this.state.planData.tridDesignerCities.filter(ct => ct.city == this.state.planData.eventCity)[0] : {}
                    }
                /> : null}


            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        city: state.usr.city,
        email: state.usr.email,
        firstOpenning: state.usr.firstOpenning,
        couponCode: state.usr.couponCode
    }
};


const mapDispatchToProps = dispatch => {
    return {
        onCity: (city) => dispatch({ type: acTypes.SET_CITY, city: city }),
        onEmail: (email) => dispatch({ type: acTypes.SET_EMAIL, email: email }),
        onCouponCode: (couponCode) => dispatch({ type: acTypes.SET_COUPON_CODE, couponCode: couponCode }),
        onFirstOpen: (firstOpenning) => dispatch({ type: acTypes.SET_OPENNING, firstOpenning: firstOpenning })
    }
};

export default compose(translate("translations"), connect(mapStateToProps, mapDispatchToProps))(Plan);