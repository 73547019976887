import React from 'react';
import PropTypes from 'prop-types';
import './DesignerImageFeed.scss';

const DesignerImageFeed = () => (
  <div className="DesignerImageFeed">
    <div className="container">
    <h3 className="sub-title">My Instagram</h3>
        <div className="images-container">
             <div className="images-wrap d-flex justify-content-start flex-wrap">
            	<div className="grid-img bgImg" style={{ backgroundImage:  "url(" + "https://placeimg.com/400/400/any?nocache=" +Math.random() + ")" }}></div>
            	<div className="grid-img bgImg" style={{ backgroundImage:  "url(" + "https://placeimg.com/400/400/any?nocache=" +Math.random() + ")" }}></div>
            	<div className="grid-img bgImg" style={{ backgroundImage:  "url(" + "https://placeimg.com/400/400/any?nocache=" +Math.random() + ")" }}></div>
            	<div className="grid-img bgImg" style={{ backgroundImage:  "url(" + "https://placeimg.com/400/400/any?nocache=" +Math.random() + ")" }}></div>
            	<div className="grid-img bgImg" style={{ backgroundImage:  "url(" + "https://placeimg.com/400/400/any?nocache=" +Math.random() + ")" }}></div>
            	<div className="grid-img bgImg" style={{ backgroundImage:  "url(" + "https://placeimg.com/400/400/any?nocache=" +Math.random() + ")" }}></div>
            	<div className="grid-img bgImg" style={{ backgroundImage:  "url(" + "https://placeimg.com/400/400/any?nocache=" +Math.random() + ")" }}></div>
            	<div className="grid-img bgImg" style={{ backgroundImage:  "url(" + "https://placeimg.com/400/400/any?nocache=" +Math.random() + ")" }}></div>
            	<div className="grid-img bgImg" style={{ backgroundImage:  "url(" + "https://placeimg.com/400/400/any?nocache=" +Math.random() + ")" }}></div>
            	<div className="grid-img bgImg" style={{ backgroundImage:  "url(" + "https://placeimg.com/400/400/any?nocache=" +Math.random() + ")" }}></div>
            	<div className="grid-img bgImg" style={{ backgroundImage:  "url(" + "https://placeimg.com/400/400/any?nocache=" +Math.random() + ")" }}></div>
            	<div className="grid-img bgImg" style={{ backgroundImage:  "url(" + "https://placeimg.com/400/400/any?nocache=" +Math.random() + ")" }}></div>
            </div>
        </div>
    </div>
  </div>
);

DesignerImageFeed.propTypes = {};

DesignerImageFeed.defaultProps = {};

export default DesignerImageFeed;
