import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './PlanExplore.scss';
import Slider from "react-slick";
import axios from 'axios';
import PlanStory from './../PlanStory/PlanStory'
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import compose from 'compose-function';

import prevImg from './../../assets/icons/slider-left.svg';
import nextImg from './../../assets/icons/slider-right.svg';

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  draggable: false,
  centerMode: false,
  responsive: [
    { breakpoint: 1170, settings: { slidesToShow: 3, slidesToScroll: 3, arrows: false, draggable: true, centerMode: false, } },
    { breakpoint: 960, settings: { slidesToShow: 3, slidesToScroll: 3, arrows: false, draggable: true, centerMode: false, } },
    { breakpoint: 720, settings: { slidesToShow: 3, slidesToScroll: 1, arrows: false, draggable: true, centerMode: false, } },
    { breakpoint: 540, settings: { slidesToShow: 2, slidesToScroll: 1, arrows: false, draggable: true, centerMode: false, } }
  ]
};

const settingsMobile = {
  dots: false,
  arrows: false,
  infinite: false,
  slidesToShow: 2.3,
  slidesToScroll: 1,
  draggable: true,
  centerMode: false
};

class PlanExplore extends Component {

  state = {
    plans: []
  }

  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }


  componentDidMount() {
    axios.get(`Order/getRandomPlans/6/${this.props.i18n.language === "tr" ? "0" : this.props.i18n.language === "ru" ? "2":"1"}/${this.props.city}/0`)
      .then(response => {
        this.setState({ plans: response.data });
        console.log(this.state.plans);
      });
  }

  render() {
    return (
      <>
        {this.state.plans.length > 3 &&
          <div className="PlanExplore container">

            <div className="d-flex flex-wrap justify-content-between align-items-center ">
              <h2 className="section-title">{this.props.t("Explore other trip plans")}</h2>


              <div className="d-flex flex-nowrap justify-content-start align-items-center explore-header">
                {/* <p className="show-all-btn">Show All Plans (15)</p>*/}
                <button className="sliderBtn" onClick={this.previous}><img className="" src={prevImg} /></button>
                <button className="sliderBtn" onClick={this.next}><img className="" src={nextImg} /></button>
              </div>
            </div>

            {/*<p className="description">In this section, you can find other trip plans from all trip designers.</p>*/}
            <div className="explore-slider-wrap">
              <div className="explore-slider-container slider-container">
                <div className="desktop-sliderx">
                  <Slider ref={c => (this.slider = c)} {...settings}>
                    {this.state.plans.filter(pl => pl.id != this.props.planId).map((plan, i) => <a key={i} href={this.props.isFullAccess ? `#` : `/${plan.languageString}/plan/${plan.uniqId}/${plan.planUrl}`}><PlanStory plan={plan} /></a>)}
                  </Slider>
                </div>
                <div className="mobile-sliderx">
                  <Slider ref={c => (this.sliderMobile = c)} {...settingsMobile}>
                    {this.state.plans.filter(pl => pl.id != this.props.planId).map((plan, i) => <a key={i} href={this.props.isFullAccess ? `#` : `/${plan.languageString}/plan/${plan.uniqId}/${plan.planUrl}`}><PlanStory plan={plan} /></a>)}
                  </Slider>
                </div>

              </div>
            </div>
          </div>
        }
      </>
    );
  }

}



const mapStateToProps = state => {
  return {
    lang: state.usr.lang,
    city: state.usr.city
  }
};


const mapDispatchToProps = dispatch => {
  return {


  }
};


export default compose(translate("translations"), connect(mapStateToProps, mapDispatchToProps))(PlanExplore);