import React, { Component } from 'react';
import axios from 'axios';
import './DesignerOther.scss';
import Slider from "react-slick";

import PlanStory from './../../../PlanStory/PlanStory'
import { BrowserRouter as Router, Link } from 'react-router-dom';
import {firstName} from './../../../../assets/Helpers.js'
import {withRouter} from 'react-router';
import compose from 'compose-function';
import { translate } from 'react-i18next';


import prevImg from './../../../../assets/icons/slider-left.svg';
import nextImg from './../../../../assets/icons/slider-right.svg';

const settings = {
  dots: false,
  arrows:false,
  infinite: false,
  slidesToShow: 2.5,
  slidesToScroll: 1,
  draggable:false,
  responsive: [
        { breakpoint: 1170,settings: { slidesToShow: 2.5, slidesToScroll: 1 }},
        { breakpoint: 1096,settings: { slidesToShow: 2, slidesToScroll: 1}},
        { breakpoint: 960,settings: { slidesToShow: 3, slidesToScroll: 1}},
        { breakpoint: 820,settings: { slidesToShow: 2.5, slidesToScroll: 1}},
        { breakpoint: 700,settings: { slidesToShow: 2, slidesToScroll: 1}},
        { breakpoint: 570,settings: { slidesToShow: 1.5, slidesToScroll: 1}},
        { breakpoint: 440,settings: { slidesToShow: 2, slidesToScroll: 1}}
        ]
};

class DesignerOther extends Component{ 

  state = {
    plans:[]
  };

       constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }

    next() {
      this.slider.slickNext();
    }
    previous() {
      this.slider.slickPrev();
    }


  componentDidMount () {
      axios.get( 'Order/getRandomPlans/6/1/0/'+this.props.designerId )
           .then( response => {
               this.setState({plans: response.data});
           });
  }

  render(){
  return(
    <div className="DesignerOther">
    {this.state.plans.filter(pl => pl.uniqId != this.props.planId).length > 0 ?
      <div>

         <div className="d-flex flex-wrap justify-content-between align-items-center ">
        <h4 className="section-title">{this.props.t("s Other Designs", {designerName:firstName(this.props.designerName)})}</h4>
        <div className="d-flex flex-nowrap justify-content-start align-items-center explore-header">
          {/* <p className="show-all-btn">Show All Plans (15)</p>*/}

          {this.state.plans.filter(pl => pl.uniqId != this.props.planId).length > 1 ?
           <div>
             <button className="sliderBtn" onClick={this.previous}><img className="" src={prevImg}/></button>
             <button className="sliderBtn" onClick={this.next}><img className="" src={nextImg}/></button>
           </div>
           :null}
       </div>
       </div>

        <div className="slider-container">
            <Slider ref={c => (this.slider = c)} {...settings}>
               { this.state.plans.filter(pl => pl.uniqId != this.props.planId).map((plan, i) => <a key={i}  href={`/${plan.languageString}/plan/${plan.uniqId}/${plan.planUrl}`} ><PlanStory plan={plan} /></a>) }
            </Slider>
        </div>
      </div>
    :null}
    </div>
  )};
}


DesignerOther.defaultProps = {};
export default  translate("translations")(DesignerOther);
