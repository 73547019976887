import React, { Component } from 'react';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import './DateSelectBrowse.scss';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import logo from './../../assets/images/logo@3x.png';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as acTypes from '../../store/actions';
import { connect } from 'react-redux';
import moment from 'moment'
import compose from 'compose-function';
import { translate } from 'react-i18next';

class DateSelectBrowse extends Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      email:'',
      focusedInput: null,
      isMobile:false
    };
  }

  render() {
    return (
    	<div className="DateSelectBrowse">
			    	<div className="content-wrap  container">
						<h4 className="many-text">{this.props.t("300+ different plans, 100+ trip designers at HeyTripster.")}</h4>
			    			<div className="main-content d-flex justify-content-between align-items-center">
				    			<div className="date-wrap fc-wrap wm">
				    				<div className="date-picker-wrap">
								        <DateRangePicker
								          startDatePlaceholderText={this.props.t("Arrival Date")}
  										  endDatePlaceholderText={this.props.t("Return Date")}
								          minimumNights={0}
								          hideKeyboardShortcutsPanel
								          readOnly
								          withFullScreenPortal = {this.state.isMobile}
								          orientation={this.state.isMobile ? "vertical" : "horizontal"}
								          startDateId="startDate"
										  endDateId="endDate"
								          startDate={this.state.startDate}
								          endDate={this.state.endDate}
								          onDatesChange={({ startDate, endDate }) => { this.setState({ startDate, endDate })}}
								          focusedInput={this.state.focusedInput}
								          onFocusChange={(focusedInput) => { this.setState({ focusedInput })}}
								        />
								    </div>
				    			</div>
							<button className="btn-w flex-grow-0" onClick={() => this.toBrowse()} >{this.props.t("Browse All Plans")}</button>
			    		</div>    		
			    </div>
    	</div>
    );
  }

  	componentDidMount() {
  		if(this.props.startDate && this.props.endDate){
  			  	this.setState({startDate:moment(this.props.startDate),endDate:moment(this.props.endDate)});
  		}
	    window.addEventListener("resize", this.resize.bind(this));
	    this.resize();
	}

	resize() {
		
	    this.setState({isMobile: window.innerWidth <= 760});
	}

	componentWillUnmount() {
	    window.removeEventListener("resize", this.resize.bind(this));
	}

	toBrowse(){
		this.props.onDates({startDate:this.state.startDate,endDate:this.state.endDate});
		if(this.state.startDate && this.state.endDate){
			console.log("toBrowse");
			var url = "/" + this.props.i18n.language + "/browse/"+this.state.startDate.format('YYYY-MM-DD')+"/"+this.state.endDate.format('YYYY-MM-DD');

			if(this.props.eventCity && this.props.eventCity.city != null )
				url = url+"/"+ this.props.eventCity.city;

			this.props.redirect(url);
		}
		
	}

}

const mapStateToProps = state => {
    return {
        startDate:state.usr.startDate,
		endDate:state.usr.endDate,
		
    }
};


const mapDispatchToProps = dispatch => {
    return {
        onDates: (datesObj) => dispatch({type: acTypes.SET_DATES , datesObj: datesObj}),
    }
};

export default compose(translate("translations"),connect(mapStateToProps, mapDispatchToProps))(DateSelectBrowse);

