import React from 'react';
import PropTypes from 'prop-types';
import './PlantTreeInfo.scss';
import { translate } from 'react-i18next';
import planting from './../../assets/icons/planting.svg';

const PlantTreeInfo = (props) => (
  <div className="PlantTreeInfo">
    <div className="container d-flex justify-content-center align-items-center tree-container">
    	<div className="img"><img src={planting}/></div>
    	<div className="txt">
    		<p>{props.t("PlanTreeInfoText")}</p>
    		{/*<button className="btn-d">Learn More</button>*/}
    	</div>

    </div>
  </div>
);

PlantTreeInfo.propTypes = {};

PlantTreeInfo.defaultProps = {};

export default translate("translations")(PlantTreeInfo);
