import React, { Component } from 'react';
import './PlanMapBox.scss';
import ReactMapGL, { Source, Layer } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { translate } from 'react-i18next';


class PlanMapBox extends Component {

  state = {

  };

  componentDidMount() {

  }

  render() {
    return (
      <>
      {this.props.solted ? 
      <div className="PlanMapBox"  >
        <div className="showmap-txtb">
        <p className="showmap-txt1 " onClick={this.props.mapOpen}>{this.props.t("Show locations")}</p>
        <p className="showmap-txt2 " onClick={this.props.mapOpen2}>{this.props.t("Show route")}</p>
        </div>
        <div className='map-wrap'>
          {this.props.locations.length > 0 ?
            <ReactMapGL
              width="100%"
              height="100%"
              mapStyle="mapbox://styles/mapbox/light-v10"
              zoom={13}
              latitude={parseFloat(this.props.locations[0].lat)}
              longitude={parseFloat(this.props.locations[0].lng)}
              mapboxApiAccessToken={'pk.eyJ1IjoiaGV5dHJpcHN0ZXIiLCJhIjoiY2toaTByNmowMTBsYjJ5bndidzA0ZnZiaCJ9.4FU_uRLuYB8ikupTjuatag'}
            >
            </ReactMapGL> : null}
        </div>
      </div>
      :
      <div className="PlanMapBox" onClick={this.props.mapOpen} >
        <p className="showmap-txtd"><p className="showmap-txt">{this.props.t("Show plan on a map")}</p></p>
        <div className='map-wrap'>
          {this.props.locations.length > 0 ?
            <ReactMapGL
              width="100%"
              height="100%"
              mapStyle="mapbox://styles/mapbox/light-v10"
              zoom={13}
              latitude={parseFloat(this.props.locations[0].lat)}
              longitude={parseFloat(this.props.locations[0].lng)}
              mapboxApiAccessToken={'pk.eyJ1IjoiaGV5dHJpcHN0ZXIiLCJhIjoiY2toaTByNmowMTBsYjJ5bndidzA0ZnZiaCJ9.4FU_uRLuYB8ikupTjuatag'}
            >
            </ReactMapGL> : null}
        </div>
      </div>
      }
      
      </>
    );
  }
}



export default translate("translations")(PlanMapBox);