import React from 'react';
import PropTypes from 'prop-types';
import './PlanProfile.scss';
import mapicon from './../../../../assets/icons/map-pin-outline.svg';
import { translate } from 'react-i18next';
import {firstName,lastName} from './../../../../assets/Helpers.js'


const PlanProfile = (props) => {
	const { t } = props;
  
  return(
    <>
    {
      props.t("VisibleAreaTripDesignerInformation") !== "VisibleAreaTripDesignerInformation" && props.t("VisibleAreaTripDesignerInformation") === "True" &&
      <div className="PlanProfile d-flex align-items-center">
  	<div className="avatar flex-shrink-0" style={{ backgroundImage:  "url(" + props.image + ")" }} ></div>
  	<div className="info">
  		<div className="name trunccate">{firstName(props.fullName)} {lastName(props.fullName)}</div>
  		<div className="occupation">{props.professions}</div>
  		<div className="trips">{t("trip design", {count:props.tdPlanCount})}</div>
             {
              props.cities.filter(ct=> ct.city == props.eventCityId).map((c,i)=> 
             <div className="local" key={i}><img className="liveicon" src={mapicon}/>{t("livetext",{"inhabitText":t(c.inhabitText),"cityText": t(c.cityText),"inhabitCount":t(c.inhabitCount),"inhabitCircleText":t(c.inhabitCircleText.toLowerCase())})}</div>)
            }
  	</div>
  </div>
    }
  </>
  )};

PlanProfile.propTypes = {};

PlanProfile.defaultProps = {
	"fullName":""
};

export default translate("translations")(PlanProfile);
