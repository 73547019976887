import React, { Component } from 'react';
import PropTypes from 'prop-types';

// examples:
import GoogleMap from './GoogleMap';


// InfoWindow component
const InfoWindow = (props) => {
  const { place } = props;
  const infoWindowStyle = {
    position: 'relative',
    width: 300,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 10,
    fontSize: 14,
    zIndex: 100,
  };

  return (
    <div style={infoWindowStyle}>
      <div style={{ fontSize: 16 }}>
        {place.name}<br/>
        <b>{place.location}</b><br/>
        <a href={place.map} target="_blank" rel="nofollow noreferrer noopener">Google maps </a>
      </div>
    </div>
  );
};

// Marker component
const Marker = ({ show, place }) => {
  const markerStyle = {
    borderRadius: "50% 50% 50% 0",
    width: "20px",
    height: "20px",
    transform: "rotate(-45deg)",
    border:  show ? '5px solid blue': '5px solid red',
    backgroundColor: show ? 'white' : 'white',
    cursor: 'pointer',
    zIndex: 10,
   
  };

  return (
    <>
      <div style={markerStyle} />
      {show && <InfoWindow place={place} />}
    </>
  );
};

class MarkerInfoWindow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      places: [],
    };
  }

  componentDidMount() {
    this.setState({ places: this.props.locations });
    console.log(this.props.locations);
  }

  // onChildClick callback can take two arguments: key and childProps
  onChildClickCallback = (key) => {
      
    console.log("key");
    console.log(key);
    var places = this.state.places.map(q => {
      if (q.id == key) {
          var up = { ...q, show: !q.show };
          return up;
      }
      else{
        var up = { ...q, show: false };
          return up;
      }
  });
    this.setState((state) => {
      return { places: places };
    });
  };

  render() {
    const { places } = this.state;

    return (
      <>
        
          <GoogleMap
            defaultZoom={14}
            defaultCenter={ [parseFloat(this.props.locations[0].lat),parseFloat(this.props.locations[0].lng) ]}
            
            bootstrapURLKeys={{key: "AIzaSyAHeBDsgVDMztc0ZybKZXXv-fDn77Xq6t0" }}
            onChildClick={this.onChildClickCallback}
          >
            {places.map((place) => (
              <Marker
                key={place.id}
                lat={place.lat}
                lng={place.lng}
                show={place.show}
                place={place}
              />
            ))}
          </GoogleMap>
      </>
    );
  }
}

InfoWindow.propTypes = {
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
    rating: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.string),
    price_level: PropTypes.number,
    opening_hours: PropTypes.shape({
      open_now: PropTypes.bool,
    }),
  }).isRequired,
};

Marker.propTypes = {
  show: PropTypes.bool.isRequired,
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
    rating: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.string),
    price_level: PropTypes.number,
    opening_hours: PropTypes.shape({
      open_now: PropTypes.bool,
    }),
  }).isRequired,
};

export default MarkerInfoWindow;