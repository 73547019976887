import React from 'react';
import './DesignerProfile.scss';
import spotify from './../../../../assets/icons/spotify.svg';
import { translate } from 'react-i18next';

function firstName(fullName) {
  const names = fullName.split(" ");
  names.pop();
  return names.join(" ");
}

class DesignerProfile extends React.Component{


  state={
  showMore:false
}

    toggleMore = () => {
        this.setState({ showMore: !this.state.showMore});
    }

render() {return(
  <div className="DesignerProfile">
    <div className="photo bgImg" style={{ backgroundImage:  "url(" + this.props.image + ")" }} ></div>
    <div className="bio">
    	{this.props.tripDesignerRewiev ? 

        
        <p>

        {this.props.tripDesignerRewiev.length > 154 && !this.state.showMore ? this.props.tripDesignerRewiev.substring(0,154) + "..." : this.props.tripDesignerRewiev}


      {this.props.tripDesignerRewiev.length > 154 ? 
        
        <span onClick={()=>this.toggleMore()} className="more">{this.state.showMore ? '-Less':'+More'}</span>


        : null}



        </p> 


        : null }
    </div>
    <div className="sep"></div>
      <h5>{this.props.t("Languages")}</h5>
    <div className="langs d-flex flex-wrap">
            {
                this.props.tdLanguages.map((lang, index) => {
                return <div key={"lng-"+index} className="tag">{this.props.t("Languages" + lang)}</div>
           })}
    </div>
	<div className="sep"></div>
	<div className="stats d-flex">
			<div className="stat">
          <p className="title">{this.props.t("Planted Trees")}</p>
				<p className="val">{this.props.tdTrees}</p>
			</div>
			<div className="stat">
				<p className="title">{this.props.t("Number of Plans Sold")}</p>
				<p className="val">{this.props.tdTrees}</p>
			</div>
	</div>
	<div className="sep"></div>
	<h5>{this.props.t("Themes of ’s trip plans",{fullName : firstName(this.props.fullName)})}</h5>
    <div className="themes d-flex flex-wrap">
    	
           {
                this.props.planThemes ? this.props.planThemes.split(";").filter(x => x).map((theme, index) => {
                return <div key={"th-"+index} className="tag">{this.props.t(theme)}</div>
           }):null}
        </div>
    <div className="sep"></div>
    {this.props.favoriteSong ? 
      <div>
    <h5>{this.props.t("Favorite Song")}</h5>
    <iframe src={ "https://open.spotify.com/embed/track/" + this.props.favoriteSong.split("/").pop()} width="300" height="80" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
</div>:null
}
  </div>
)};
}

DesignerProfile.defaultProps = {};

export default  translate("translations")(DesignerProfile)