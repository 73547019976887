import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './DesignerInstagram.scss';
import axios from 'axios';


export default class DesignerInstagram extends Component{

	state = {
        feedHtml:"",
        data:null
    }

	componentDidMount () {
	    axios.get( 'https://graph.facebook.com/v8.0/instagram_oembed?url=https://www.instagram.com/p/fA9uwTtkSN/&access_token=1267961486896720%7Cdbbf2a94f4405ceede2cff093a5a24af' )
	        .then( response => {
	            this.setState({feedHtml: response.data.html});
	            this.setState({data: response.data});
	        } );
	}

	render(){
		return(
		  <div className="DesignerInstagram d-flex justify-content-center">
		   {
		   	this.state.data ? 
		   	<div style={{"height":"900px"}}>
		    {/*<iframe src={"data:text/html;charset=utf-8,"+encodeURI(this.state.feedHtml)} style={{"width":"100%"}}/>*/}
		    <iframe src={"data:text/html;charset=utf-8,"+"<html><head></head><body>"+this.state.feedHtml+"</body></html>"} style={{"width":"800px","height":"100%"}}/>
		   {/* {this.state.data ? <iframe src={this.state.data.thumbnail_url}/>:null}*/}
		    
		    </div>

		    :null}
		  </div>
		)};

}

